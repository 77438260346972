.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2vh;
  border-top: 1px solid rgb(237, 235, 235);
  margin-bottom: 2vh;
}

.wrapper .user-profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* font-size: 13px; */
}

.wrapper .user-profile img {
  width: 30%;
  margin-right: 1vw;
}

.wrapper .user-profile > div h4 {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0.1vh;
  /* font-weight: 600; */
  /* border: 1px solid red; */
}

.wrapper .user-profile > div p {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbebe;
  /* font-weight: 600; */
  /* border: 1px solid red; */
}

.wrapper input[type="button"] {
  justify-self: end;
  width: max-content;
  height: 35px;
  border-radius: 4px;
  padding: 0 14px;
  background-color: #f1f2f7;
  color: inherit;
  font-weight: 100;
  box-shadow: none;
  /* padding: 1.5vh 1vw; */
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #575759;
  display: grid;
  align-items: center;
}

.wrapper .div-dropdown {
font-family: "Axiforma";
font-size: 12px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;

}

.wrapper .dropdown {
  /* width: 30%; */
  border: 1px solid #c6c8cc;
  border-radius: 5px;
  color: #c6c8cc;
  padding: 1vh 1vw;
  text-align: left;
  overflow: hidden;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

.wrapper .active {
  border: 1px solid green;
  text-align: center;
  color: green;
}

.wrapper .inactive {
  border: 1px solid red;
  text-align: center;
  color: red;
}

.wrapper .controls {
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 630px) {
  .wrapper {
    flex-direction: column;
  }
  .wrapper input[type="button"] {
    width: 100%;
    /* margin-top: 1vh; */
  }
  .wrapper .user-profile {
    margin-bottom: 1vh;
  }

  .wrapper .controls {
    width: 100%;
    flex-direction: column;
  }
  .wrapper .controls .dropdown {
    text-align: center;
    width: 100%;
    margin-bottom: 1vh;
  }
}
