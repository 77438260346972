.wrapper {
  height: 100vh;
}

.wrapper > header {
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3vh 4vw;
  border-bottom: 1px solid rgb(237, 235, 235);
}

.wrapper > header > h1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.wrapper > header input[type="button"] {
  border-radius: 5px;
  background-color: #5102ff;
  color: white;
  padding: 2vh 3vw;
  box-shadow: none;
}

.wrapper > section {
  width: 100%;
}

.wrapper .content {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content max-content;
  gap: 1rem;
  padding: 3rem;
  align-content: start;
}

.content_header{
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  align-content: start;
  margin-bottom: 1rem;
}

.content_header > h2{
  font-family: "Axiforma SemiBold";
  font-size: 34px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.content_header > button{
  cursor: pointer;
  border: none;
  outline: none;
  width: 142px;
  height: 38px;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  opacity: 0.10000000149011612px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: none;
  background-color: #191919;
  color: white;
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 4px;
  grid-template-columns: max-content max-content;
}

.content_header > button:disabled{
  background-color: #88898C;
  cursor: not-allowed;
}

.content_header > button > svg{
  width: 12px;
  stroke: #FFFFFF;
}

.content_header > .edited{
  background-color: #5100FF;
  color: #FFFFFF;
}

.content_header > .edited > svg{
  stroke: #FFFFFF;
}

.edit_password{
  cursor: pointer;
  border: none;
  outline: none;
  width: 142px;
  height: 38px;
  border-radius: 4px;
  opacity: 0.10000000149011612px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: none;
  background-color: #313233;
  color: #FFFFFF;
  align-self: end;
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 6px;
  grid-template-columns: max-content max-content;
}

.edit_password:disabled{
  background-color: #F0F2F7;
  color: #949699;
  cursor: not-allowed;
}

.edit_password > svg{
  width: 12px;
  fill: #FFFFFF;
}

.edit_password:disabled > svg{
  fill: #000000;
}

.section1 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
}

.form{
  width: 100%;
  display: grid;
  align-content: start;
}

.section1_header{
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.section1 > h2{
  font-family: 'Axiforma';
  font-size: 34px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;

}

.form_row{
  width: 100%;
  display: grid;
  grid-template-columns: calc(50% - 1rem) calc(50% - 1rem);
  gap: 2rem;
}

.form_group{
  display: grid;
  gap: 9px;
}

.form_group > label{
  font-family: 'Axiforma';
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.form_group  input{
  border: none;
  outline: none;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px ;
  font-family: 'Axiforma Light';
}

.form_group > textarea{
  resize: none;
  border: none;
  outline: none;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px ;
  font-family: 'Axiforma';
  background-color: white;

}

.form_group > .password{
  border-radius: 6px;
  border: 1px solid #F0F2F7;
}

.form_group  .edit_value{
  /* width: 210px; */
  /* height: 40px; */
  border-radius: 6px;
  border: 1px solid #6F7073;
}

.line{
  margin: 1rem 0 ;
  border-color: #F0F2F7;
  border-width: 0.1px;
}

.image_card{
  width: 167px;
  height: 167px;
  border-radius: 8px;
  border: 1px solid #F0F2F7;
  box-shadow: 0px 15px 35px 0px #00091A0A;
  display: grid;
  grid-template-rows: 1fr max-content;
  justify-items: center;
  align-items: center;
}

.image_card > img {
  width: 35px;
  height: 35px;
}

.dropdown{
  height: 30px;
  width: 75%;
  border: 1px solid #EBEDF2;
  border-radius: 6px;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  color: #19191A;
  position: relative;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-auto-flow: column;
  padding: 0 10px;
  background-color: #F8F7FA;
}

.dropdown > p{
  text-transform: capitalize;
  width: max-content;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdown > p{
  height: 18px;
}

.collapse_icon{
  width: 10px;
  stroke: #88898C;
  transform: rotateZ(-90deg);
}

.more_option_con{
  z-index: 2;
  position: absolute;
  top: 23px;
  right: 2px;
  border: 1px solid #F0F2F7;
  width: 100%;
  height: max-content;
  border-radius: 8px;
  background-color: white;
  padding: 10px;
  display: grid;
  align-items: center;
  gap: 5px;
}

.more_option_con > h5{
  cursor: pointer;
  
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: start;
  height: 100%;
  display: grid;
  align-items: center;
  color: #6F7073;
}