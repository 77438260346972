.attachment{
    background-color: #F0F2F7;
    color:#6F7073;
    width:144px;
    height: 103px;
    padding:5px 10px;
    font-size: 10px;
    border-radius: 5px;
    cursor:pointer;
    display: flex;
    border: 1px solid #C6C8CC;;
  }
  .attachment-left{
    gap:36px;
    display: flex;
    padding: 5px 5px;
    flex-direction: column;
    font-size: 12;
    font-family: "Axiforma SemiBold";
  
  }
  .shared-text{
    font-size: 7px;
  }
  
  .attachment-right {
    display: flex;
    height: 100px;
    width:95px;
    padding: 5px 5px;
    position: relative;
    right:0;
    flex-direction: column;
  }
  
  .attachment-right p {
    white-space: nowrap;
    overflow: hidden;   
    text-overflow: ellipsis; 
  }
  .download-text {
    font-size: 10px; 
    font-weight: 500;
    position: absolute; 
    bottom: 10px;
    font-family: "Axiforma SemiBold";
    color: #000000;
}
  .download-text:hover{
    text-decoration: underline;
  }
  .attachment-content{
    position: fixed;
    top:0;
    bottom: 0;
    right:0;
    left:0;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
    z-index:999;
    display: flex;
    padding: 5 10px;
    justify-content: center;
    align-items: center;
    /* position: relative; */
  }
  
  .top-icon-div{
    display:flex;
    position: absolute;
    padding: 30px 25px;
    top: 0;
    right:0;
    gap:20px;
    height: 25px;
    align-items: center;
    justify-content: end;
    padding-bottom: 5px;
  }
  .top-icon-div a{
    /* height:120px */
  }
  
  .download_icon{
    fill:#ffffff;
    height:30px;


  }
  .cancel_icon{
    fill:#ffffff;
    height:22px;
    width: 22px;
 
  }
  .pdf-container {
    width: 100%; /* Fills the container horizontally */
    height: 500px; /* Adjust height as needed */
    margin: auto; /* Centers the PDF viewer within the container */
    overflow: hidden; /* Hides potential overflow (optional) */
  }
  
  /* Optional styling for the Viewer element (adjust as desired) */
  Worker, Viewer {
    width: 100%;
    height: 100%;
  }
  
  /* .cancel_icon:hover{
    fill: #000000;
  }
  .download_icon:hover{
    fill: #000000;

  } */