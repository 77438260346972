.wrapper {
  position: relative;
  height: 100vh;
}

.wrapper > header {
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3vh 4vw;
  border-bottom: 1px solid rgb(237, 235, 235);
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
}

.wrapper > header input[type="button"] {
  border-radius: 5px;
  background-color: #5102ff;
  color: white;
  padding: 2vh 3vw;
}

.wrapper > section {
  width: 100%;
}

.wrapper .content {
  width: 100%;
  height: calc(100vh - 95px);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.wrapper .content .channels {
  width: 80%;
  margin-top: 5vh;
}

.channel_box {
  width: 100%;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgb(237, 235, 235);
  margin-bottom: 2rem;
  align-items: center;
}

.channel_box .channel {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.channel_box .channel > div h4 {
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
}

.channel_box .channel > div p {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  color: #6f7073;
  /* font-weight: 600; */
  /* border: 1px solid red; */
}

.channel_box input[type="button"] {
  /* width: 100%; */
  border-radius: 5px;
  background-color: #f1f2f7;
  color: inherit;
  font-weight: 100;
  box-shadow: none;
  padding: 1.5vh 1vw;
}

.channel_box .div-dropdown {
  font-size: 15px;
  text-align: start;
  font-weight: 500;
}

.channel_box .dropdown {
  width: 30%;
  border: 1px solid #c6c8cc;
  border-radius: 5px;
  color: #c6c8cc;
  padding: 1vh 1vw;
  text-align: left;
  overflow: hidden;
}

.channel_box .active {
  border: 1px solid green;
  text-align: center;
  color: green;
}

.channel_box .inactive {
  border: 1px solid red;
  text-align: center;
  color: red;
}

.channel_box .controls {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.controls > p {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #6f7073;
}

.controls > button {
  cursor: pointer;
  outline: none;
  width: max-content;
  padding: 4px 6px;
  border-radius: 4px;
  border: 0.7px solid #c6c8cc;
  background-color: #ffffff;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.controls > .connected {
  color: #ffffff;
  background-color: #19191a;
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
  align-items: center;
}

.check_icon {
  width: 9px;
  stroke: #ffffff;
}

.channel_icon_con {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #ffffff;
}

.channel_icon {
  width: 44px;
}

.channel_icon2 {
  width: 33px;
}

@media screen and (max-width: 630px) {
  .channel_box {
    flex-direction: column;
  }
  .channel_box input[type="button"] {
    width: 100%;
    /* margin-top: 1vh; */
  }
  .channel_box .channel {
    margin-bottom: 1vh;
  }

  .channel_box .controls {
    width: 100%;
    flex-direction: column;
  }
  .channel_box .controls .dropdown {
    text-align: center;
    width: 100%;
    margin-bottom: 1vh;
  }
}

.live_chat_sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 466px;
  height: 100%;
  /* box-shadow: 0px 20px 42px 0px #00091A1A; */
  border-left: 1px solid #ebedf2;
  background-color: white;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  overflow-y: scroll;
}

.live_chat_sidebar_header {
  height: 137px;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 1rem;
  align-items: center;
  align-content: end;
  border-bottom: 1px solid #f0f2f7;
  padding: 1rem 2rem;
}

.live_chat_sidebar_header_icon {
  width: 24px;
}

.live_chat_sidebar_header > div {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

.live_chat_sidebar_header > button {
  cursor: pointer;
  outline: none;
  width: max-content;
  padding: 4px 6px;
  border-radius: 4px;
  border: 0.7px solid #c6c8cc;
  background-color: #ffffff;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.how_to_button {
  cursor: pointer;
  outline: none;
  width: max-content;
  padding: 4px 6px;
  border-radius: 4px;
  border: 0.7px solid #c6c8cc;
  background-color: #ffffff;
  color: #19191a;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
  align-items: center;
}

.live_chat_sidebar_body {
  padding: 2rem;
  display: grid;
  gap: 2rem;
  /* overflow-y: scroll; */
  height: 100%;
  align-content: start;
}

.live_chat_sidebar_body111 > header {
  height: 71px;
  font-family: "Axiforma";
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  column-gap: 1vh;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid #f0f2f7;
  border-bottom: 1px solid #f0f2f7;
}

.live_chat_sidebar_section {
  display: grid;
  gap: 10px;
}

.live_chat_sidebar_section > header {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 10px;
  align-items: center;
}

.live_chat_sidebar_icon {
  width: 17px;
  fill: #313233;
}

.live_chat_sidebar_icon_stroke {
  width: 17px;
  stroke: #313233;
}

.live_chat_sidebar_section > header > p {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.live_chat_sidebar_section > div {
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: #f8f7fa;
  border: 1px solid #ebedf2;
  padding: 1rem;
  font-family: monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: scroll;
}

.live_chat_sidebar_section > div > section {
  cursor: pointer;
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.live_chat_sidebar_section > div > section > div {
  width: 78px;
  height: 28px;
  border-radius: 4px;
  border: 1.5px solid #ffffff;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.live_chat_sidebar_section > div > section > div > p {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.live_chat_sidebar_section > input {
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: #f8f7fa;
  border: 1px solid #ebedf2;
  padding: 1rem;
  font-family: monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: scroll;
}

.live_chat_sidebar_section > textarea {
  resize: none;
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: #f8f7fa;
  border: 1px solid #ebedf2;
  padding: 10px 1rem;
  font-family: monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: scroll;
}

.add_department_button {
  width: 120px;
  height: 40px;
  border-radius: 6px;
  background-color: #19191a;
  color: #ffffff;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  outline: none;
  display: grid;
  align-items: center;
  justify-items: center;
}

.copy_icon {
  stroke: #ffffff;
  width: 14px;
}

.live_chat_sidebar_section > div:hover section {
  display: grid;
  width: 100%;
  height: 100%;
  background-color: #5102ff;
}

.live_chat_sidebar_section > div::-webkit-scrollbar {
  width: 7px;
}

.live_chat_sidebar_section > div::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 20px;
}

.live_chat_sidebar_section1 > div {
  height: 128px;
}

.live_chat_sidebar_section2 > div {
  height: 74px;
}

.live_chat_sidebar_bottom {
  height: 137px;
  border-top: 1px solid #f0f2f7;
  padding: 1rem 0;
}

.live_chat_sidebar_bottom > div {
  width: 100%;
  height: 48px;
  border-radius: 6px;
  background-color: #f8f7fa;
  border: 1px solid #ebedf2;
  padding: 0 1rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
}

.live_chat_sidebar_bottom > div > h5 {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.live_chat_sidebar_bottom > div > div {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
}

.live_chat_sidebar_bottom > div > div > p {
  text-transform: uppercase;
  font-family: monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.color_box {
  width: 46px;
  height: 38px;
  box-shadow: 0px 0px 12px 0px #00091a1a;
}

.color_box {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background-color: transparent;
  width: 100px;
  height: 100px; */
  border: none;
  cursor: pointer;
}
.color_box::-webkit-color-swatch {
  border-radius: 4px;
  border: 4px solid #ffffff;
}
.color_box::-moz-color-swatch {
  border-radius: 4px;
  border: 4px solid #ffffff;
}

.button_escalation {
  width: 16px;
  fill: #575759;
}

.connected_departments_con > header {
  height: 71px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 10px;
  align-items: center;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: #f0f2f7;
  padding: 0 2rem;
}

.no_connected_department_con {
  display: grid;
  gap: 1.5rem;
  justify-content: center;
  justify-items: center;
  height: 160px;
  align-content: center;
}

.no_connected_department_icon {
  width: 77px;
  height: 77px;
  border-radius: 50%;
  background-color: #ffffff;
  display: grid;
  align-items: center;
  justify-items: center;
  box-shadow: 0px 20px 42px 0px #08001a1a;
}

.no_connected_department_icon > svg {
  width: 29px;
  fill: #5100ff;
}

.no_connected_department_con > p {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.connected_departments {
  /* height: 240px; */
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem;
  justify-content: start;
  align-content: start;
}

.connected_department {
  position: relative;
  width: max-content;
  height: 28px;
  padding: 6px 10px;
  border-radius: 4px;
  border: 0.7px;
  border: 0.7px solid #c6c8cc;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
  display: grid;
  align-items: center;
  justify-items: center;
}

.connected_department > div {
  position: absolute;
  cursor: pointer;
  top: -8px;
  right: -7px;
  display: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #5100ff;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  align-items: center;
  justify-items: center;
}

.connected_department:hover > div {
  display: grid;
}

.connected_department > div:hover {
  display: grid;
}

.department_con {
  width: 124px;
  height: 98px !important;
  border-radius: 8px;
  box-shadow: 0px 20px 42px 0px #00091a1a;
  background-color: white !important;
  display: grid;
  align-content: start;
  position: absolute !important;
  overflow-y: scroll;
  bottom: -102px;
  z-index: 4;
}

.department_con::-webkit-scrollbar {
  display: none;
}

.department_con > .department:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}

.department {
  cursor: pointer;
  padding: 7px 1rem;
}

.department > h4 {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.live_chat_sidebar_body_how {
  padding: 0;
  display: grid;
  /* gap: 2rem; */
  /* overflow-y: scroll; */
  height: 100%;
  align-content: start;
}

.live_chat_sidebar_section_how {
  position: relative;
  display: grid;
  gap: 10px;
  padding-bottom: 2rem;
  border-bottom: 1px solid #f0f2f7;
}

.live_chat_sidebar_section_how > header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content max-content 1fr;
  justify-content: start;
  gap: 10px;
  align-items: center;
  height: 50px;
  padding: 14px 0;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #f0f2f7;
  padding: 0 2rem;
}

.live_chat_sidebar_how_icon {
  width: 17px;
  fill: #313233;
}

.live_chat_sidebar_how_icon_stroke {
  width: 17px;
  stroke: #313233;
}

.live_chat_sidebar_section_how > header > p {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.how_process_con {
  display: grid;
  gap: 1rem;
  padding: 1rem 2rem;
}

.how_process {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 10px;
  align-items: center;
}

.how_process > div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #5100ff;
}

.how_process > p {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.how_process > p > span {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.code_div {
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: #f8f7fa;
  border: 1px solid #ebedf2;
  padding: 1rem;
  font-family: monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: scroll;
}

.icon_colapse {
  stroke: #292d32;
  width: 16px;
  justify-self: end;
}

.rotate_icon {
  transform: rotateZ(-90deg);
}

.delete_modal {
  width: 500px;
  padding: 2rem;
}

.delete_modal .modal-header .header {
  font-family: "Axiforma Bold";
  font-weight: 600;
  margin-bottom: 1vh;
  font-size: 20px;
}

.delete_modal .modal-header .email {
  color: #949699;
}

.delete_modal .modal-content {
  font-family: "Axiforma Medium";
  color: #949699;
  margin: 0vh 0 2vh 0;
  font-size: 14px;
}

.delete_modal .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.delete_modal .modal-controls input[type="button"] {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 0.8rem 1.5rem;
  font-weight: 100;
  box-shadow: none;
}

.delete_modal .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #f0f2f7;
  margin-right: 2vw;
}

.delete_modal .modal-controls input[type="button"]:first-of-type:hover {
  background-color: #e6e6e7;
}

.delete_modal .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #b22424;
}

.delete_modal .modal-controls input[type="button"]:last-of-type:hover {
  background-color: #9f1f1f;
}
