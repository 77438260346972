/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

#layout-grid-18 {
  grid-template-columns: 18rem 1fr;
}

#layout-grid-15 {
  grid-template-columns: 15rem 1fr;
}

#layout-grid-3 {
  grid-template-columns: 3rem 1fr;
}

#layout-grid-0 {
  grid-template-columns: 0rem 1fr;
}

#layout-grid-18 * #modalId{
  grid-template-columns: 18rem 1fr;
  grid-template-rows: unset;
}

#layout-grid-3 * #modalId{
  grid-template-columns: 3rem 1fr;
  grid-template-rows: unset;
}

#layout-grid-15 * #modalId{
  grid-template-columns: 15rem 1fr;
  grid-template-rows: unset;
}

/* #modalId{
  grid-template-columns: 18rem 1fr;
} */

svg{
  cursor: pointer;
}

.css-8oeadq-placeholder{
  
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #ADAFB2 !important;
}

.css-1dimb5e-singleValue{
  
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #19191A !important;
}

.Toastify__toast{
  border-radius: 8px !important;
  padding: 8px 15px !important;
}

.Toastify__toast-icon > svg{
  display: none !important;
}

.Toastify__progress-bar{
  opacity: 0 !important;
}

.Toastify__close-button{
  opacity: 1 !important;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #E9EBEF !important;
  display: grid;
  align-items: center;
  justify-items: center;
  margin-top: 5px;
}

.Toastify__close-button > svg{
  fill: #374151 !important;
  margin-left: 1px;
}

.primary{
  border-left: 4px solid #5100FF;
  color: #5100FF;
}

.secondary{
  border-left: 4px solid #FF00C7;
  color: #FF00C7;
}

@font-face {
  font-family: "Axiforma Light";
  src: local('Axiforma-Light'), url("./fonts/font/Axiforma-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Axiforma Medium";
  src: local('Axiforma-Medium'), url("./fonts/font/Axiforma-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Axiforma Bold";
  src: local('Axiforma-Bold'), url("./fonts/font/Axiforma-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Axiforma SemiBold";
  src: local('Axiforma-SemiBold'), url("./fonts/font/Axiforma-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Axiforma";
  src: local('Axiforma-Regular'), url("./fonts/font/Axiforma-Regular.ttf") format("truetype");
}

button{
  cursor: pointer;
}

input[type="button"]{
  cursor: pointer;
}

input[type="submit"]{
  cursor: pointer;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.react-datepicker{
  font-family: "Axiforma" !important;
}

.react-datepicker__input-container > input{
  font-family: "Axiforma" !important;
  padding: 3px 10px;
  border-radius: 4px;
  border: 1px solid #ADAFB2;
}