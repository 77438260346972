.wrapper {
  /* height: 100vh; */
  height: 100vh;
  display: grid;
  grid-template-columns: 250px 1fr;
  flex-direction: row;
  position: fixed;
  right: 0;
  width: calc(100vw - 295px);
}

.wrapper > div {
  display: grid;
  grid-template-rows: 95px 1fr;
}

.conversation-column {
  /* flex: 0.5; */
  flex: 1;
  border-right: 1px solid rgb(237, 237, 237);
}

.conversation-column > header {
  font-family: "Axiforma";
  /* height: 10vh; */
  font-size: 25px;
  font-weight: 600;
  /* padding: 2vw 0 0 0; */
  padding-left: 2rem;
  display: grid;
  align-items: center;
  border-bottom: 1px solid rgb(237, 237, 237);
  position: sticky;
  top: 0;
  background-color: white;
}

.search{
  height: 42px;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 10px;
  border: 1px solid #F0F2F7;
  border-radius: 8px;
  margin: 20px;
  margin-bottom: 10px;
  padding: 10px;
  align-items: center;
}

.search_icon{
  width: 15.31px;
}

.search > input{
  border: none;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

}

.conversation-column > section > .messages-top-controls {
  padding: 0 1.2rem;
  height: 40px;
  display: grid;
  gap: 1rem;
}

.conversation-body-column > header{
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  padding: 0 2rem;
  border-bottom: 1px solid rgb(237, 237, 237);
  position: sticky;
  top: 0;
  background-color: white;
}

.conversation-body-column_col {
  /* height: 10vh; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.messages{
  height: 75%;
  overflow-y: scroll;
  padding-bottom: 0rem;
}

.messages::-webkit-scrollbar {
  display: none;
}

.conversation-body-column_col > a > .chat {
  cursor: pointer;
  width: 87px;
  font-size: 13px;
  font-family: "Axiforma";
  font-weight: 600;
  padding: 1.2vh 1vw;
  border: 1px solid rgb(237, 237, 237);
  border-radius: 5px;
  text-align: center;
  background-color: #f7f8fc;
  color: rgb(165, 165, 165);
}

.conversation-body-column_col > a > .chat:hover {
  background-color: #575759;
  color: white;
}

.conversation-body-column_col > .allChats {
  background-color: inherit;
  color: inherit;
  font-weight: 600;
}

.conversation-body-column_col > a > .unread {
  background-color: #575759;
  color: white;
}

.conversation-body-column_col > .chat:not(:last-of-type) {
  margin-right: 1vh;
}

.conversation-column > section {
  width: 100%;
  height: calc(100vh - 95px);
  /* padding: 1vw; */
}

.conversation-column > section > .messages-top-controls > ul {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  list-style-type: none;
  align-items: center;
  justify-content: start;
  /* margin-bottom: 2vh; */
}

.conversation-column
  > section
  > .messages-top-controls
  > ul
  > li:not(:last-of-type) {
  margin-right: 1.8vw;
}

.conversation-column > section > .messages > ul {
  list-style-type: none;
}

.conversation-column > section > .messages > ul > li {
  cursor: pointer;
  width: 100%;
  height: 53px;
  display: grid;
  /* justify-content: space-between; */
  grid-template-columns: max-content 1fr max-content;
  gap: 1rem;
  justify-content: space-around;
  padding: 0 1.2rem;
  border-bottom: 1px solid rgb(237, 237, 237);
  align-items: center;
}

.conversation-column > section > .messages > ul > li > .checkIcons {
  display: flex;
  flex-direction: row;
}

.conversation-column
  > section
  > .messages
  > ul
  > li
  > .checkIcons
  > img:not(:last-of-type) {
  margin-right: 1vh;
}

.conversation-column > section > .messages > ul > li > .checkIcons p {
  font-weight: 600;
  margin-left: 1.5vw;
}

.conversation-body-column > section {
  height: calc(100vh - 95px);
  display: grid;
  grid-template-columns: 1fr;
}

.conversation-body-column > section .left {
  flex: 3;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(237, 237, 237);
}

.conversation-body-column > section .right {
  flex: 1;
  padding: 8px;
  display: grid;
}

.conversation-body-column > section .right {
  flex: 1;
  padding: 8px;
  height: calc(100vh - 95px);
}

.conversation-body-column > section .right > .right_row1 > .calendar {
  padding: 15px 9px 5px;
  border-bottom: 1px solid #f7f8fc;
  font-size: 10px;
  font-weight: 400;
}

.conversation-body-column > section .right > .right_row1 .others {
  padding: 15px 9px 5px;
  border-bottom: 1px solid #f7f8fc;
  font-size: 10px;
  font-weight: 400;
  font-size: 10px;
}

.conversation-body-column > section .right > .right_row1 .others > .others-child {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.conversation-body-column > section .right > .right_row1 .others > .others-child >p:last-child{
  text-align: right;
}

.conversation-body-column > section .left .conversation-message {
  height: calc(100vh - 205px);
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  border-bottom: 1px solid #f7f8fc;
  /* padding: 30px; */
  overflow: auto;
}

.conversation-body-column > section .left .conversation-message > ul {
  list-style-type: none;
}

.conversation-body-column > section .left .flex-end {
  align-items: flex-end;
  justify-content: flex-start;
}

.conversation-body-column > section .left .flex-center {
  align-items: center;
  justify-content: center;
}

.conversation-body-column > section .left .text-area {
  /* height: 20%; */
  /* background-color: #24b224; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.draft_message{
  cursor: pointer;
  position: relative;
  width: 90%;
  box-shadow: 0px 12px 30px 0px #9383B53D;
  border: 1px solid #5100FF;
  background-color: #F6F1FF;
  border-radius: 8px;
  padding: 1rem;
  margin: 0 auto;
}

.draft_message_header{
  width: 91px;
  height: 20px;
  border-radius: 600px;
  background-color: #783BFC;
  display: grid;
  align-items: center;
  justify-items: center;
  position: absolute;
  top: -10px;
  left: 9px;
}

.draft_message_header > h5{
  margin-top: 1px;
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
  text-align: center;
}

.draft_message_content{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #18004D;
}

.close_draft_message{
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 30px;
  background-color: #FFFFFF;
  display: grid;
  align-items: center;
  justify-items: center;
  position: absolute;
  top: -12px;
  right: -4px;
}

.close_draft_message_icon{
  width: 8px;
  height: 8px;
  fill: #5100FF;
}

.draft_icon{
  width: 15px;
  stroke: #5100FF;
  position: absolute;
  right: 6px;
  bottom: 8px;
}

.text-area-wrapper {
  width: 90%;
  display: flex;
  flex-direction: row;
  border: 2px solid #8d8d8f;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f8f7fa;
  padding: 1vh;
  position: relative;
}

.restructure{
  position: absolute;
  bottom: 0;
  right: 5px;
}

.refresh_icon{
  stroke: #101828;
  height: 18px;
}

.restructuring{
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes rotate {
  0%{
    transform: rotateZ(0deg);
  }
  100%{
    transform: rotateZ(360deg);
  }
}

.conversation-body-column
  > section
  .left
  .text-area
  > .text-area-wrapper
  > textarea {
  width: 100%;
  border: none;
  border-radius: 8px;
  /* padding: 1vw; */
  padding: 0 1vw 0 1vw;
  resize: none;
  min-height: 100px;
  background-color: #f8f7fa;
  max-height: 280px;
}

.text-area > .text-area-wrapper > textarea:focus {
  border: none !important;
  outline: none;
}

.text-area > .text-area-wrapper > textarea::placeholder {
  color: #c6c8cc;
  font-weight: 400;
  font-family: "Axiforma", sans-serif;
  font-style: italic;
}

.send-message {
  display: flex;
  align-items: flex-start;
  border: none;
  background-color: transparent;
  padding: 0.1vh 0 0 0;
}

.select-message {
  font-weight: 300;
  font-size: 12px;
  color: gray;
}

.text-align {
  text-align: center;
}

.chat-list {
  height: 100%;
  width: 100%;
  overflow-y: auto !important;
  padding: 30px;
}

.chat-list::-webkit-scrollbar {
  display: none;
}

.high-color {
  color: red;
}

.mid-color {
  color: #5100ff;
}

.low-color {
  color: #b29a24;
}

.chill-color {
  color: #24b224;
}

.icons {
  width: 16px;
}

.form_group2{
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
}

.mode_icon{
  width: 17px;
}

.auto_icon{
  fill: #00B2FF;
}

.supervised_icon{
  stroke: #5100FF;
}

.hybrid_icon{
  fill: #FF00C7;
}

.selected_icon{
  width: 10px;
  stroke: #313233;
}

.collapse_icon{
  width: 8px;
  stroke: #88898C;
  transform: rotateZ(-90deg);
}

.form_group2 > div{
  cursor: pointer;
  height: 36px;
  width: 165px;
  border-radius: 6px;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  color: #19191A;
  position: relative;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-auto-flow: column;
  grid-template-columns: max-content 1fr max-content;
  padding: 0 8px;
  gap: 8px;
  background-color: #F8F7FA;
}

.form_group2 > div > p{
  text-transform: capitalize;
  width: max-content;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.011em;
  text-align: left;
  color: #19191A;

}

.form_group2 > div > p{
  height: 18px;
}

.more_option_con{
  position: absolute;
  top: 42px;
  left: 0;
  border: 1px solid #F0F2F7;
  width: 166px;
  height: 115px;
  border-radius: 8px;
  background-color: white;
  display: grid;
  align-items: center;
  box-shadow: 0px 20px 42px 0px #00091A1A;
  grid-template-rows: max-content max-content;
}

.more_option_con > div{
  align-self: start;
}

.more_option_con > div:last-child{
  align-self: end;
}

.selectedMode{
  background-color: #E5EEFF;
}

.more_option_con > div{
  padding: 0 15px;
}

.more_option{
  height: 37px;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  gap: 10px;
}

.more_option_con > div > .more_option:not(:last-child){
  border-bottom: 1px solid #EBEDF2;
}

.more_option > h5{
  cursor: pointer;
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  height: 100%;
  display: grid;
  align-items: center;
  color: #6F7073;
}

.learn_more{
  height: 37px;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: space-between;
}

.learn_more > h5{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.right_row2{
  display: grid;
  justify-items: center;
  align-content: space-between;
  /* padding: 10px 0; */
  gap: .5rem;
  align-content: space-between;
}

.right_row2_col1{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.resolved_button{
  cursor: pointer;
  width: 80px;
  height: 30px;
  padding: 0 10px;
  border-radius: 4px;
  gap: 10px;
  outline: none;
  display: grid;
  /* grid-template-columns: max-content max-content; */
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.mark_resolved_button{
  background-color: #EEE6FF;
  border: 0.7px solid #DCCCFF;
  color: #19191A;
}

.mark_escalate_button{
  border: 0.7px solid #F8D2ED;
  background-color: #FFDDF5;
  color: #19191A;
}

.mark_resolved_icon{
  width: 15px;
  stroke: #19191A;
}

.mark_escalate_icon{
  width: 15px;
  fill: #19191A;
}

.resolved_button > h4{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #19191A;
}

.switch_mode_con{
  width: 156px;
  height: 36px;
  padding: 0 10px;
  border-radius: 20px;
  background-color: #F0F2F7;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.switch_mode_con > h4{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.confirmation_modal{
  width: 500px;
  padding: 2.5rem;
}

.confirmation_modal .modal-header .header {
  font-family: "Axiforma";
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-size: 20px;
}

.confirmation_modal .modal-header .email {
  color: #949699;
}

.confirmation_modal .modal-content {
  font-family: "Axiforma Medium";
  color: #949699;
  margin: 0vh 0 1rem 0;
  font-size: 12px;
  font-weight: 400;
}

.confirmation_modal .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.confirmation_modal .modal-controls input[type="button"] {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: 100;
  box-shadow: none;
  height: 40px;
}

.confirmation_modal .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #F0F2F7;
  margin-right: 2vw;
  width: 88px;
  font-size: 12px;
}

.confirmation_modal .modal-controls input[type="button"]:first-of-type:hover{
    background-color: #e6e6e7;
}

.confirmation_modal .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #5100FF;
  width: 101px;
  font-size: 12px;
}

.confirmation_modal .modal-controls input[type="button"]:last-of-type:hover{
    background-color: #5100FF;
}

.comment_modal{
  width: 500px;
  padding: 2.5rem;
}

.comment_modal .modal-header{
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.comment_modal .modal-header .header {
  font-family: "Axiforma";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.comment_modal .modal-header .header_department {
  width: max-content;
  height: 30px;
  padding: 0 10px;
  border-radius: 4px;
  border: 0.7px solid #DFE1E5;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  display: grid;
  align-items: center;
}

.comment{
  width: 420px;
  height: 120px;
  padding: 10px;
  border-radius: 4px;
  border: 0.7px solid #DFE1E5;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.comment_modal .modal-header .email {
  color: #949699;
}

.comment_modal .modal-content {
  font-family: "Axiforma Medium";
  color: #949699;
  margin: 0vh 0 1rem 0;
  font-size: 12px;
  font-weight: 400;
}

.comment_modal .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.comment_modal .modal-controls input[type="button"] {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: 100;
  box-shadow: none;
  height: 40px;
}

.comment_modal .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #F0F2F7;
  margin-right: 2vw;
  width: 88px;
  font-size: 12px;
}

.comment_modal .modal-controls input[type="button"]:first-of-type:hover{
    background-color: #e6e6e7;
}

.comment_modal .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #5100FF;
  width: 101px;
  font-size: 12px;
}

.comment_modal .modal-controls input[type="button"]:last-of-type:hover{
    background-color: #5100FF;
}

.form_group{
  display: grid;
  gap: 9px;
  margin: 0vh 0 3rem 0;
}

.form_group > label{
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.form_group > textarea{
  height: 60px;
  width: auto;
  border: 1px solid #f0f2f7;
  border-radius: 6px;
  padding: 10px 15px;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  resize: none;
  color: #19191A;
}

.form_group > textarea:focus{
  background-color: #F8F7FA;
  border-color: #5100FF;
}

.pagination{
  position: sticky;
  bottom: 0;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.2rem;
  background-color: white;
  height: 4rem;
  align-items: center;
}

.pagination_btn_left{
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid #C6C8CC;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_left > svg{
  fill: #292D32;
}

.pagination_btn_right{
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  background-color: #88898C;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_right > svg{
  fill: #F3F4F6;
}

.pagination_num{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.pagination_icon{
  width: 5px;
}

.escalate_department_con{
  /* box-shadow: 0px 20px 42px 0px #00091A1A; */
  display: grid;
  justify-items: center;
  position: relative;
}

/* .search_input{
  padding: 10px;
} */

.search2{
  height: 30px;
  width: 144px;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 4px;
  border: 1px solid #5100FF;
  border-radius: 4px;
  padding: 4px 10px;
  align-items: center;
}

.search_icon{
  width: 11.31px;
  fill: #88898C;
}

.search2 > input{
  border: none;
  outline: none;
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

.escalate_department_con > .department:not(:last-child){
  border-bottom: 1px solid #EBEDF2;
}

.department_con{
  width: 124px;
  height: 98px;
  border-radius: 8px;
  box-shadow: 0px 20px 42px 0px #00091A1A;
  background-color: white;
  display: grid;
  align-content: start;
  overflow-y: scroll;
  position: absolute;
  top: 2.2rem;
}

.department_con::-webkit-scrollbar{
  display: none;
}

.department_con > .department:not(:last-child){
  border-bottom: 1px solid #EBEDF2;
}

.department{
  cursor: pointer;
  padding: 7px 1rem;
}

.department > h4{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.selected_department{
  width: 124px;
  height: 30px;
  border-radius: 4px;
  background-color: #F0F2F7;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.selected_department >h4{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.remove_department{
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #FFFFFF;
}

.cancel_icon{
  width: 7px;
  fill: #19191A;
}