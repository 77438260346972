.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  padding-top: 2vh;
  border-top: 1px solid rgb(237, 235, 235);
  margin-bottom: 2vh;
}

.wrapper .user-profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  /* font-size: 13px; */
}

.abv{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #42414C;
  display: grid;
  align-items: center;
  justify-items: center;
  text-transform: uppercase;
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}

.wrapper .user-profile > div h4 {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0.1vh;
  /* font-weight: 600; */
  /* border: 1px solid red; */
}

.wrapper .user-profile > div p {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbebe;
  /* font-weight: 600; */
  /* border: 1px solid red; */
}

.wrapper input[type="button"] {
  justify-self: end;
  width: max-content;
  height: 35px;
  border-radius: 4px;
  padding: 0 14px;
  background-color: #f1f2f7;
  color: inherit;
  font-weight: 100;
  box-shadow: none;
  /* padding: 1.5vh 1vw; */
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #575759;
  display: grid;
  align-items: center;
}

.wrapper .div-dropdown {
font-family: "Axiforma";
font-size: 12px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;

}

.wrapper .dropdown {
  /* width: 30%; */
  border: 1px solid #c6c8cc;
  border-radius: 5px;
  color: #c6c8cc;
  padding: 1vh 1vw;
  text-align: left;
  overflow: hidden;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  width: 75px;
  height: 35px;

}

.wrapper .active {
  border: 1px solid green;
  text-align: center;
  color: green;
}

.wrapper .inactive {
  border: 1px solid red;
  text-align: center;
  color: red;
}

.wrapper .controls {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 2rem;
  justify-content: end;
  align-items: center;
}

.edit{
  grid-template-columns: max-content max-content max-content !important;
}

.edit_con{
  width: 23px;
  height: 27px;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  background-color: #EBEDF2;
  position: relative;
  /* z-index: -1; */
}

.edit_icon{
  width: 3px;
  fill: #292D32;
}

.more_option_con{
  position: absolute;
  top: 17px;
  left: -145px;
  border: 1px solid #F0F2F7;
  width: 150px;
  height: 100px;
  border-radius: 4px;
  background-color: white;
  padding: 10px;
  display: grid;
  align-items: center;
}

.more_option_con > h5{
  cursor: pointer;
  
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  height: 100%;
  display: grid;
  align-items: center;
}

.more_option_con > h5:first-child{
  border-bottom: 1px solid #F0F2F7;
}

@media screen and (max-width: 630px) {
  .wrapper {
    flex-direction: column;
  }
  .wrapper input[type="button"] {
    width: 100%;
    /* margin-top: 1vh; */
  }
  .wrapper .user-profile {
    margin-bottom: 1vh;
  }

  .wrapper .controls {
    width: 100%;
    flex-direction: column;
  }
  .wrapper .controls .dropdown {
    text-align: center;
    width: 100%;
    margin-bottom: 1vh;
  }
}


.delete_modal{
  width: 500px;
  padding: 2rem;
}

.delete_modal .modal-header .header {
  font-family: "Axiforma Bold";
  font-weight: 600;
  margin-bottom: 1vh;
  font-size: 20px;
}

.delete_modal .modal-header .email {
  color: #949699;
}

.delete_modal .modal-content {
  font-family: "Axiforma Medium";
  color: #949699;
  margin: 0vh 0 2vh 0;
  font-size: 14px;
}

.delete_modal .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.delete_modal .modal-controls input[type="button"] {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: .8rem 1.5rem;
  font-weight: 100;
  box-shadow: none;
}

.delete_modal .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #F0F2F7;
  margin-right: 2vw;
}

.delete_modal .modal-controls input[type="button"]:first-of-type:hover{
    background-color: #e6e6e7;
}

.delete_modal .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #B22424;
}

.delete_modal .modal-controls input[type="button"]:last-of-type:hover{
    background-color: #9f1f1f;
}
