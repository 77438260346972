.wrapper {
  height: 100vh;
}

.wrapper > header {
  text-transform: capitalize;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding: 3vh 4vw;
  border-bottom: 1px solid rgb(237, 235, 235);
  background-color: white;
  z-index: 1;
}

.wrapper > header > h1 {
  font-family: "Axiforma";
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.wrapper > section {
  width: 100%;
}

.wrapper .content {
  width: 100%;
  height: 100%;
  margin-top: 73px;
  /* padding: 4vh 4vw; */
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.wrapper .content .content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4vh 4vw;
  border-bottom: 1px solid rgb(237, 235, 235);
}

.wrapper .content .content-header .left {
  flex: 1;
}

.wrapper .content .content-header .left p:first-of-type {
  font-size: 20px;
  color: #19191a;
  font-weight: 600;
  margin-bottom: 2vh;
}

.wrapper .content .content-header .left p {
  color: #949699;
  font-size: 14px;
}

.wrapper .content .content-header .right {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.wrapper .content .content-header .right input[type="button"]:first-of-type {
  margin-right: 2vh;
  background-color: #efe5ff;
}

.add_product{
  background-color: #5100FF !important;
}

.wrapper .content .content-header .right input[type="button"] {
  width: 142px;
  height: 38px;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  border: 0px 0px 2px 0px;
  opacity: 0.10000000149011612px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: none;
  background-color: #191919;
  color: white;
}

.content-body{
  width: 85%;
  padding: 0 0 3rem;
  margin: auto;
  margin-top: 10vh;
}

.wrapper .content .content-body .grid {
  width: 50%;
  display: grid;
  gap: 50px;
  grid-template-columns: auto auto auto;
}

.customer_table_container{
  width: 100%;
  height: 100%;
  padding: 0 0 3rem;
  margin: auto;
}

.customer_table tr{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 12% 12% 12% 12% 12% 12% 12%;
  justify-content: center;
  align-content: center;
}

.customer_table{
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  position: relative;
}

.customer_table > thead{
  background-color: #F0F2F7;
  height: 87px;
  display: grid;
  position: sticky;
  top: 73px;
  /* width: 76%; */
}

.customer_table > tbody{
  display: block;
  /* margin-top: 6rem; */
}

/* tr{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
} */

.customer_table > thead > tr > th{
  font-family: "Axiforma";
  color: #19191A;
  /* padding: 1rem; */
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;

}

.customer_table > tbody > tr:hover{
  background-color: #F0F2F7;
}

.selected{
  background-color: #F0F2F7;
}

.customer_table > tbody > tr > td{
  color: #19191A;
  /* padding: 1rem; */
  border-bottom: 1px solid #E6E8ED;
  height: 63px;
  display: grid;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  align-content: center;
}

.customer_table > tbody > tr > td > h5{
  font-family: "Axiforma";
  font-weight: 500;
  height: 100%;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.happy{
  color: #1CD75A;
}

.angry{
  color: #fc3301;
}

.neutral{
  color: #B29A24;
}

.product_name{
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 1rem;
}

.product_name > h5{
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;

}

.product_name > img{
  width: 61px;
  height: 61px;
}

.table_icon_fill{
  width: 15px;
  fill: #6F7073;
}

.table_icon_fill2{
  width: 21px;
  fill: #6F7073;
}

.table_icon_stroke{
  width: 21px;
  stroke: #6F7073
}

.customer_details_modal{
  display: grid;
  width: 550px;
  border-radius: 8px;
  overflow: hidden;
}

.customer_details_modal_row_1{
  display: grid;
  background-color: white;
  padding: 3rem 2.5rem;
  gap: 2rem;
}

.customer_details_modal_info{
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

.customer_details_modal_info > h1{
  
  text-transform: capitalize;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.customer_details_modal_info > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #949699
}

.customer_details_modal_buttons{
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 1rem;
  justify-content: center;
}

.customer_details_modal_button{
  cursor: pointer;
  width: 171.88px;
  height: 37px;
  padding: 0 10px;
  border-radius: 4px;
  gap: 10px;
  border: none;
  outline: none;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.view_conversation_button{
  background-color: #F0F2F7;
  border: 1px solid #DFE1E5;
  color:#313233;
}

.mark_resolved_button{
  background-color: #5100FF;
  color: white;
}

.customer_details_modal_button > h4{
  font-family: "Axiforma";
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: inherit;
}

.arrow_right_icon{
  width: 7px;
  stroke: #949699;
}

.mark_resolved_icon{
  width: 15px;
  stroke: #FFFFFF;
}

.customer_details_modal_row_2{
  padding: 1.5rem 2.5rem;
  background-color: #F7F8FC;
  height: 225px;
  display: grid;
  gap: 1rem;
  align-content: start;
}

.customer_details_modal_row_2 > h2{
  font-family: "Axiforma";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.customer_details_modal_more_info{
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  gap: 1rem;
  justify-content: space-between;
}

.customer_details_modal_category{
  display: grid;
  gap: 4px;
  align-content: start;
}

.customer_details_modal_category > div{
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 3px;
}

.category_icon{
  width: 18px;
  fill: #6F7073;
}

.category_icon_stroke{
  width: 18px;
  stroke: #6F7073;
}

.customer_details_modal_category > div > h3{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #5100FF;
}

.customer_details_modal_category > div > h4{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

@media screen and (max-width: 250px) {
  .wrapper .content .content-body .grid {
    width: 100%;
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 400px) {
  .wrapper .content .content-body .grid {
    width: 100%;
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 600px) {
  .wrapper .content .content-body .grid {
    width: 100%;
  }

  .wrapper .content .content-header .right {
    flex-direction: column;
    justify-content: right;
    align-items: end;
  }

  .wrapper .content .content-header .right input[type="button"] {
    margin: 0 0 1vh 0 !important;
  }
}

.pagination{
  position: absolute;
  bottom: 25px;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 0 1.2rem;
  background-color: white;
  height: 68px;
  align-items: center;
}

.pagination_btn_left{
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid #C6C8CC;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_left > svg{
  fill: #292D32;
}

.pagination_btn_right{
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  background-color: #88898C;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_right > svg{
  fill: #F3F4F6;
}

.pagination_num{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.pagination_icon{
  width: 5px;
}