.wrapper {
  height: 100vh;
}

.wrapper > header {
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-bottom: 1px solid rgb(237, 235, 235);
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
}

.wrapper > header input[type="button"] {
  border-radius: 5px;
  background-color: #5102ff;
  color: white;
  padding: 2vh 3vw;
}

.wrapper > section {
  width: 100%;
}

.wrapper .content {
  width: 100%;
  height: calc(100vh - 95px);
  display: flex;
  flex-direction: column;
  padding: 4rem;
  gap: 2rem;
}

.wrapper .content > h3 {
  font-family: "Axiforma Semibold";
  font-size: 34px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.integrations_con{
  width: 100%;
  height: 95%;
  display: grid;
  grid-template-rows: max-content 1fr;
  gap: 1.5rem;
}

.integrations_con > p{
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #575759;
  display: flex;
  gap: 4px;
}

.integrations_col{
  display: grid;
  gap: 1rem;
}

.integrations{
  display: grid;
  gap: 1.5rem;
  align-content: start;
}

.integration {
  width: 486px;
  height: 50px;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  border: 1px solid #EBEDF2;
  border-radius: 12px;
  align-items: center;
  align-content: center;
  padding: 0 1rem;
}

.integration .channel {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.integration .channel > div h4 {
  font-family: "Axiforma";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;

}

.integration input[type="button"] {
  /* width: 100%; */
  border-radius: 5px;
  background-color: #f1f2f7;
  color: inherit;
  font-weight: 100;
  box-shadow: none;
  padding: 1.5vh 1vw;
}

.integration .controls {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.esclamation_icon{
  width: 18px;
  fill: #575759;
}

.channel_icon_con{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #EFF5F0;
}

.channel_icon{
    width: 15px;
}

.channel_icon2{
  width: 15px;
}

.calendar_icon{
  fill: #5100FF;
}

.file_icon{
  fill: #FF00C7;
}

.wordpress_sidebar{
  position: fixed;
  right: 0;
  top: 0;
  width: 466px;
  height: 100%;
  /* box-shadow: 0px 20px 42px 0px #00091A1A; */
  border-left: 1px solid #EBEDF2;
  background-color: white;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
}

.wordpress_sidebar_header{
  height: 137px;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 1rem;
  align-items: center;
  align-content: end;
  border-bottom: 1px solid #F0F2F7;
  padding: 1rem 2rem;
  justify-content: space-between;
}

.wordpress_sidebar_header_icon{
  width: 24px;
}

.wordpress_sidebar_header > div{
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

.wordpress_sidebar_header > button{
  cursor: pointer;
  outline: none;
  width: max-content;
  padding: 4px 6px;
  border-radius: 4px;
  border: 0.7px solid #C6C8CC;
  background-color: #FFFFFF;
  color: #19191A;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
  align-items: center;
}

.button_escalation{
  width: 16px;
  fill: #575759;
}

.wordpress_sidebar_body{
  padding: 2rem;
  display: grid;
  gap: 2rem;
  /* overflow-y: scroll; */
  height: 100%;
  align-content: start;
}

.wordpress_sidebar_section{
  display: grid;
  gap: 10px;
}

.wordpress_sidebar_section > header{
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 10px;
  align-items: center;
}

.wordpress_sidebar_icon{
  width: 17px;
  fill: #313233;
}

.wordpress_sidebar_icon_stroke{
  width: 17px;
  stroke: #313233;
}

.wordpress_sidebar_section > header > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.wordpress_sidebar_section > div{
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: #F8F7FA;
  border: 1px solid #EBEDF2;
  padding: 1rem;
  font-family: monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: scroll;
}

.wordpress_sidebar_section > div > section{
  cursor: pointer;
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.wordpress_sidebar_section > div > section > div{
  min-width: 78px;
  width: max-content;
  padding: 0 10px;
  height: 28px;
  border-radius: 4px;
  border: 1.5px solid #FFFFFF;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.wordpress_sidebar_section > div > section > div > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}

.wordpress_sidebar_section > input{
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: #F8F7FA;
  border: 1px solid #EBEDF2;
  padding: 1rem;
  font-family: monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: scroll;
}

.copy_icon{
  stroke: #FFFFFF;
  width: 14px;
}

.wordpress_sidebar_section > div:hover section{
  display: grid;
  width: 100%;
  height: 100%;
  background-color: #5102ff;
}

.wordpress_sidebar_section > div::-webkit-scrollbar {
  width: 7px;
}

.wordpress_sidebar_section > div::-webkit-scrollbar-thumb {
  background: #FFFFFF; 
  border-radius: 20px;
}

.wordpress_sidebar_section1 > div{
  height: 128px;
}

.wordpress_sidebar_section2 > div{
  height: 74px;
}

.wordpress_sidebar_section3 > div{
  height: 36px;
  border-radius: 6px;
  background-color: #F8F7FA;
  border: 1px solid #EBEDF2;
  padding: 0 1rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr max-content;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  gap: 10px;
}

.wordpress_sidebar_section3 > div > p{
  font-family: monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wordpress_sidebar_bottom{
  height: 137px;
  border-top: 1px solid #F0F2F7;
  padding: 1rem 0;
}

.wordpress_sidebar_bottom > div{
  width: 100%;
  height: 48px;
  border-radius: 6px;
  background-color: #F8F7FA;
  border: 1px solid #EBEDF2;
  padding: 0 1rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
}

.wordpress_sidebar_bottom > div > h5{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.wordpress_sidebar_bottom > div > div{
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
}


.download_btn{
  width: 178px;
  height: 40px;
  border-radius: 6px;
  background-color: #19191A;
  border: none;
  outline: none;
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 5px;
  align-items: center;
  padding: 0 20px;
  justify-items: center;
}

.download_btn > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}

.download_icon_stroke{
  width: 16px;
  stroke: #FFFFFF;
}

.copy_icon2{
  width: 76px;
  height: 28px;
  padding: 6px 10px;
  border-radius: 4px;
  border: 0.7px solid #C6C8CC;
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  background-color: #FFFFFF;
}

.copy_icon2 > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.copy_icon2 > svg{
  width: 12px;
  stroke: #575759;
}

.wordpress_sidebar_bottom > div > div > p{
  text-transform: uppercase;
  font-family: monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.color_box{
  width: 46px;
  height: 38px;
  box-shadow: 0px 0px 12px 0px #00091A1A;
}

.color_box {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background-color: transparent;
  width: 100px;
  height: 100px; */
  border: none;
  cursor: pointer;
}
.color_box::-webkit-color-swatch {
  border-radius: 4px;
  border: 4px solid #FFFFFF;
}
.color_box::-moz-color-swatch {
  border-radius: 4px;
  border: 4px solid #FFFFFF;
}

.wordpress_sidebar_body_how{
  padding: 0;
  display: grid;
  /* gap: 2rem; */
  overflow-y: scroll;
  height: calc(100vh - 137px);
  align-content: start;
}

.wordpress_sidebar_section_how{
  position: relative;
  display: grid;
  gap: 10px;
  padding-bottom: 2rem;
  border-bottom: 1px solid #F0F2F7;
}

.wordpress_sidebar_section_how > header{
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content max-content 1fr;
  justify-content: start;
  gap: 10px;
  align-items: center;
  height: 50px;
  padding: 14px 0;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #F0F2F7;
  padding: 0 2rem;
}

.wordpress_sidebar_how_icon{
  width: 17px;
  fill: #313233;
}

.wordpress_sidebar_how_icon_stroke{
  width: 17px;
  stroke: #313233;
}

.wordpress_sidebar_bottom > div > .copy_icon2{
  width: 76px;
  height: 28px;
  padding: 0 10px;
  border-radius: 4px;
  border: 0.7px solid #C6C8CC;
}

.wordpress_sidebar_bottom > div > .copy_icon2 > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.wordpress_sidebar_bottom > div > .copy_icon2 > svg{
  width: 12px;
  stroke: #575759;
}

.wordpress_sidebar_section_how > header > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.how_process_con{
  display: grid;
  gap: 1rem;
  padding: 1rem 2rem;
}

.how_process{
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 10px;
  align-items: center;
}

.how_process > div{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #5100FF;
}

.how_process > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.how_process > p > span{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.code_div{
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: #F8F7FA;
  border: 1px solid #EBEDF2;
  padding: 1rem;
  font-family: monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: scroll;
}

.icon_colapse{
  stroke: #292D32;
  width: 16px;
  justify-self: end;
}

.rotate_icon{
  transform: rotateZ(-90deg);
}

.connected_departments_con > header{
  height: 71px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 10px;
  align-items: center;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: #F0F2F7;
  padding: 0 2rem;
}

.no_connected_department_con{
  display: grid;
  gap: 1.5rem;
  justify-content: center;
  justify-items: center;
  height: 240px;
  align-content: center;
}

.no_connected_department_icon{
  width: 77px;
  height: 77px;
  border-radius: 50%;
  background-color: #FFFFFF;
  display: grid;
  align-items: center;
  justify-items: center;
  box-shadow: 0px 20px 42px 0px #08001A1A;
}

.no_connected_department_icon > svg{
  width: 29px;
  fill: #5100FF;
}

.no_connected_department_con > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.connected_departments{
  height: 240px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem;
  justify-content: start;
  align-content: start;
}

.connected_department{
  width: max-content;
  height: 28px;
  padding: 6px 10px;
  border-radius: 4px;
  border: 0.7px;
  border: 0.7px solid #C6C8CC;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
  display: grid;
  align-items: center;
  justify-items: center;
}

.department_con{
  width: 124px;
  height: 98px !important;
  border-radius: 8px;
  box-shadow: 0px 20px 42px 0px #00091A1A;
  background-color: white !important;
  display: grid;
  align-content: start;
  position: absolute !important;
  overflow-y: scroll;
  bottom: -102px;
  z-index: 4;
}

.department_con::-webkit-scrollbar{
  display: none;
}

.department_con > .department:not(:last-child){
  border-bottom: 1px solid #EBEDF2;
}

.department{
  cursor: pointer;
  padding: 7px 1rem;
}

.department > h4{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.live_chat_sidebar_icon_stroke{
  width: 17px;
  stroke: #313233;
}

.live_chat_sidebar_section{
  position: relative;
  display: grid;
  gap: 10px;
}

.live_chat_sidebar_section > header{
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 10px;
  align-items: center;
}

.live_chat_sidebar_section > header > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.live_chat_sidebar_section > input{
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: #F8F7FA;
  border: 1px solid #EBEDF2;
  padding: 10px 1rem;
  font-family: monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: scroll;
}

.live_chat_sidebar_section > textarea{
  resize: none;
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: #F8F7FA;
  border: 1px solid #EBEDF2;
  padding: 10px 1rem;
  font-family: monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: scroll;
}

.add_department_button{
  width: 120px;
  height: 40px;
  border-radius: 6px;
  background-color: #19191A;
  color: #FFFFFF;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  outline: none;
  display: grid;
  align-items: center;
  justify-items: center;
}

.live_chat_sidebar_icon{
  width: 17px;
  fill: #313233;
}

.live_chat_sidebar_icon_stroke{
  width: 17px;
  stroke: #313233;
}