.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  justify-self: center;
  position: relative;
  max-width: 650px;
  /* width: 650px; */
  background-color: white;
  /* padding: 20px; */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal-close {
  position: absolute;
  top: -10px;
  right: -19px;
}

.cancel_icon{
  width: 15px;
  fill: #FFFFFF;
}