.wrapper {
  width: 650px;
  padding: 60px 80px;
}

.wrapper > header {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 10px;
}

.wrapper > p {
  
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  margin-top: 7px;
  margin-bottom: 13px;
}

#errorMsg{
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(252, 52, 0);
  margin: 0;
}

.wrapper > header > p {
  
  /* width: 200px; */
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.wrapper > .socials {
  width: 100%;
  /* height: 306px; */
  padding: 50px;
  margin-bottom: 25px;
  border-radius: 12px;
  border: 1px solid #f0f2f7;
  gap: 20px;
}

.button-wrapper {
  width: 100%;
  /* padding-top: 25px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

/* .socials-wrapper > .socials-row:not(:last-of-type) {
  background-color: red;
  margin-bottom: 25px;
} */

.socials-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 30px;
  text-align: center;
}

.socials-wrapper > div.social-buttons {
  
  padding: 20px 15px 20px 15px;
  border: 1px solid #f0f2f7;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  color: #6f7073;
  /* text-align: left; */
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  gap: 10px 10px;
  align-content: start;
}

.socials-wrapper > div.social-buttons:hover {
  border-color: #5100ff;
}

.button-wrapper > p {
  
  margin-right: 20px;
}

.button-wrapper > button {
  cursor: pointer;
  min-width: 145px;
  
  background-color: #5100ff;
  color: white;
  border: none;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
}

input[type="checkbox"]:checked {
  accent-color: #5102ff;
}

.form-wrapper {
  width: 100%;
  /* height: Hug 306px; */
  padding: 30px 20px;
  border-radius: 12px;
  /* border: 1px solid #f0f2f7; */
  border: none;
  gap: 20px;
  /* margin-top: 30px; */
}

.form-wrapper > .form-row {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;
  margin-bottom: 20px;
}

.form-wrapper > .form-row > div > label {
  
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.form-wrapper > .form-row > div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.form-wrapper > .form-row > div > input,
.form-wrapper > .form-row  > input,
.form-wrapper > .form-row > div > button {
  height: 50px;
  width: auto;
  border: 1px solid #f0f2f7;
  border-radius: 6px;
  padding: 10px 15px;
  outline: none;
  color: #313233;
}

.form-wrapper > .form-row > div > input::placeholder {
  color: #C6C8CC;
}

/* .form-wrapper > .form-row > div > input[type="file"] {
  display: none;
} */

.file-name {
  width: 100% !important;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  font-size: 10px;
  margin-top: 2vh !important;
  color: #5102ff;
}

.file-name > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-name button {
  background-color: #5102ff;
  border: none;
  color: white;
  padding: 2px 5px;
  margin-left: 0.5vw;
  /* border-radius: 50%; */
  font-size: 10px;
  font-weight: 600;
}

.file-icon {
  width: 24px;
  margin-right: 0.5vw;
}

.form-wrapper > .form-row > div > button {
  background-color: transparent;
}

.form-wrapper > .form-row > div > button > svg {
  width: 24px;
}

.form-wrapper > .form-row > div > input:focus {
  border: 1px solid #5102ff;
}

.form-wrapper > .form-row-textarea {
  display: flex;
  flex-direction: column;
}

.form-wrapper > .form-row-textarea > label {
  
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.form-wrapper > .form-row-textarea > textarea {
  
  width: 100%;
  border: 1px solid #f0f2f7;
  border-radius: 6px;
  resize: none;
  background-color: transparent;
  outline: none;
  padding: 15px;
  color: #313233;
}

.form-wrapper > .form-row-textarea > textarea::placeholder {
  color: #C6C8CC;
}

.form-wrapper > .form-row-textarea > textarea:focus {
  border-color: #5102ff;
}

.error {
  border: 1px solid red !important;
}

.error-message {
  text-align: center;
  color: red;
  font-weight: 600;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.error-label {
  color: red !important;
}

.error-input {
  border: 1px solid red !important;
}

.error-message.active {
  opacity: 1;
  visibility: visible; /* Now, the text will be visible and take up space */
}

.icon-divider {
  
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-bottom: 20px;
  /* text-align: left; */
}

.icon-divider > svg {
  width: 20px;
  margin-right: 10px;
}

.form-row:last-of-type {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  /* margin-bottom: 20px; */
}

.button-wrapper-extra {
  justify-content: center !important;
}

main.plans {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 0px 10px;
  margin-bottom: 25px;
  justify-content: space-between;
}

main.plans > section {
  
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  color: #6f7073;
  font-weight: 400;
  font-size: 10px;
  width: 180px;
  height: 350px;
}

.plans > section > main > ul {
  list-style-type: none;
}

.plans > section{
  border: 1px solid transparent;
}

.plans > section > header {
  display: grid;
  justify-content: start;
  grid-template-columns: max-content max-content 1fr;
  gap: 10px;
  align-items: center;
  padding: 10px 10px;
  border-bottom: 1px solid #ebedf2;
}

/* .plans > section > header > svg {
  margin-right: 15px;
} */

.plans > section > header > h2{
  
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.main.plans > section > main > .plan-amount {
  color: red;
}

.background-color-change {
  background-color: #f7f8fc;
  border-radius: 6px;
  padding: 40px 40px;
}

.plans > section > main > .plan-amount {
  padding: 8px 0;
  border-bottom: 1px solid #ebedf2;
}

.plan-button-div {
  align-self: end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.plan-button-div > button {
  width: 95px;
  height: 30px;
  cursor: pointer;
  /* padding: 10px; */
  border-radius: 4px;
  border: 1px solid #ebedf2;
  background-color: transparent;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5px;
  display: grid;
  align-items: center;
  justify-items: center;
}

.plans > section > main{
  display: grid;
  grid-template-rows: max-content max-content 1fr;
  height: 89%;
}

.plans > section:first-of-type .plan-button-div > button {
  color: #5100ff;
}

.plans > section:nth-of-type(2) .plan-button-div > button {
  color: #e17613;
}

.plans > section:nth-of-type(3) .plan-button-div > button {
  color: #1cd370;
}

.plans > section > main > .plan-amount {
  color: #5100ff;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.plans > section:nth-of-type(2) > main > .plan-amount {
  color: #e27612;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.plans > section:nth-of-type(2) > main > .plan-amount > p > span {
  color: #949699;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: line-through;
  margin-left: 10px;
}

.plans > section:nth-of-type(3) > main > .plan-amount {
  color: #1cd370;
}

.plans > section > main > ul {
  height: max-content;
  padding: 9px 0;
}

.plans > section > main > ul > li {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.plans > section > main > ul > li:not(:last-of-type) {
  margin-bottom: 5px;
}

.plans > section > main > ul > li > svg {
  margin-right: 10px;
}

.badge {
  text-align: center;
  background: linear-gradient(180deg, #eda96b 0%, #cf823c 100%);
  color: white;
  border-radius: 20px;
  padding: 2px 5px;
  /* margin-left: 5px; */
}
