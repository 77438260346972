.wrapper {
  position: relative;
  height: 100vh;
}

.wrapper > header {
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-bottom: 1px solid rgb(237, 235, 235);
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
}

.wrapper > header input[type="button"] {
  border-radius: 5px;
  background-color: #5102ff;
  color: white;
  padding: 2vh 3vw;
}

.wrapper > section {
  width: 100%;
}

.wrapper .content {
  width: 100%;
  height: calc(100vh - 95px);
  display: flex;
  flex-direction: column;
  padding: 4rem;
  gap: 2rem;
}

.wrapper .content > h3 {
  font-family: "Axiforma Semibold";
  font-size: 34px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.modes_con{
  width: 100%;
  height: 95%;
  display: grid;
  grid-template-rows: max-content 1fr;
  gap: 1.5rem;
}

.modes_con > p{
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #575759;
  display: flex;
  gap: 4px;
}

.modes_col{
  display: grid;
  gap: 1rem;
}

.modes{
  display: grid;
  gap: 1.5rem;
  align-content: start;
}

.mode {
  width: 486px;
  height: 50px;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  border: 1px solid #EBEDF2;
  border-radius: 12px;
  align-items: center;
  align-content: center;
  padding: 0 1rem;
}

.mode .channel {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.mode .channel > div h4 {
  font-family: "Axiforma";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;

}

.mode input[type="button"] {
  /* width: 100%; */
  border-radius: 5px;
  background-color: #f1f2f7;
  color: inherit;
  font-weight: 100;
  box-shadow: none;
  padding: 1.5vh 1vw;
}

.mode .controls {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.esclamation_icon{
  width: 18px;
  fill: #575759;
}

.channel_icon_con{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #EFF5F0;
}

.channel_icon{
    width: 15px;
}

.channel_icon2{
  width: 15px;
}

.auto_icon{
  fill: #00B2FF;
}

.supervised_icon{
  stroke: #5100FF;
}

.hybrid_icon{
  fill: #FF00C7;
}

.settings_sidebar{
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100%;
  background-color: #F3F4F6;
}

.settings_sidebar_header{
  height: 214px;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 1rem;
  align-items: center;
  align-content: end;
  border-bottom: 1px solid #DFE1E5;
  padding: 1rem 2rem;
}

.settings_sidebar_header {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.settings_sidebar_header_mode{
  display: grid;
}

.settings_sidebar_header_mode > h5{
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.settings_sidebar_header_mode > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.settings_sidebar_header_icon{
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #FFFFFF;
  display: grid;
  align-items: center;
  justify-items: center;
}

.settings_sidebar_header_icon > svg{
  width: 18px;
}

.settings_sidebar_body{
  padding: 2rem;
  display: grid;
  gap: 2rem;
  overflow-y: scroll;
  height: calc(100vh - 137px);
  align-content: start;
}

.settings_sidebar_section{
  display: grid;
  gap: 1rem;
}

.settings_sidebar_section > header{
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 10px;
  align-items: center;
}

.settings_sidebar_icon_stroke{
  width: 12px;
  stroke:#5100FF;
}

.settings_sidebar_section > header > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.no_mode_con{
  display: grid;
  gap: 1.5rem;
  justify-content: center;
  justify-items: center;
  height: 450px;
  align-content: center;
}

.no_mode_icon{
  width: 77px;
  height: 77px;
  border-radius: 50%;
  background-color: #FFFFFF;
  display: grid;
  align-items: center;
  justify-items: center;
}

.no_mode_icon > svg{
  width: 29px;
  fill: #5100FF;
}

.no_mode_con > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}