.wrapper {
  padding: 35px;
  width: 500px;
}
.header p {
  
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 18px;
}

.form {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  gap: 27px;
}
.error_msg{
  font-family: Axiforma !important;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(252, 52, 0);
}

.form input[type="text"],
.form input[type="number"],
.form textarea {
  width: 100%;
}

.form input[type="text"],.form input[type="number"] {
  font-family: Axiforma !important;
  height: 40px;
  margin-bottom: 20px;
  background-color: #f8f7fa;
  border-radius: 4px;
  border: none;
  padding: 10px;
  outline: none;
  color: #19191A;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

.form input[type="text"]:focus,
.form input[type="number"]:focus {
  outline: 1px solid #5100ff;
}

.form textarea {
  
  font-size: 12px !important;
  font-weight: 400;
  resize: none;
  color: #19191A;
  padding: 3vh 10px;
}

.form textarea:focus {
  outline: 1px solid #5100ff;
}

.form input[type="text"]::placeholder,
.form input[type="number"]::placeholder,
.form textarea::placeholder {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #adafb2;
}

.price_con{
  position: relative;
}

.price_input_padding{
  padding-left: 2.5rem !important;
}

.currency_dropdown{
  position: absolute;
  top: 12px;
  left: 9px;
  width: 25.26px;
  height: 15.56px;
  border-radius: 6.48px;
  background-color: #FFFFFF;
  display: grid;
  grid-auto-flow: column;
  /* padding: 4px; */
  align-items: center;
  justify-content: center;
  gap: 1px;
}

.currency_dropdown >p{
  font-size: 8px;
  color: #141C3A;
}

.dropicon{
  width: 7.56px;
  transform: rotateZ(-90deg);
}

.currencies{
  position: absolute;
  top: 29px;
  left: 10px;
  display: grid;
  background-color: white;
  width: 1.5rem;
  justify-items: center;
  padding: 2px 0;
  border: 1px solid #f0f2f7;
  border-radius: 4px;
}

.currencies > p{
  cursor: pointer;
  font-size: 8px;
  width: 100%;
  text-align: center;
}

.currencies > p:not(:last-child){
  border-bottom: 1px solid #f0f2f7;
}

.content_wrapper-2 {
  display: grid;
  grid-template-rows: 50% 50%;
}

.product_image_container{
  padding: 35px;
  border-radius: 8px;
  background-color: #DFFFF9;
  border: 0.87px solid #03CF92;
  position: relative;
}

.remove_icon_con{
  position: absolute;
  top: 9px;
  right: 9px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #FFFFFF;
  display: grid;
  align-items: center;
  justify-items: center;
}

.remove_icon{
  fill: #03CF92;
  width: 8px;
}

.product_image{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.submit-wrapper {
  height: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

#file-upload{
  display: none;
}

#submit {
  width: 119px;
  height: 36px;
  padding: 10px 20px 10px 20px !important;
  border-radius: 4px;
  gap: 102px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  background: #5100ff;
  border: none;
  color: white;
  
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #f0f2f7;
  border-radius: 10px;
  color: #f0f2f7;
  /* padding: 5vh 2vw 2vh 2vw; */
}

.hover:hover {
  border-color: #5100ff;
  stroke: #5100ff;
  fill: #5100ff;
}

.hover:hover > svg > path {
  border-color: #5100ff;
  stroke: #5100ff;
  fill: #5100ff;
}

.grid-item .package-icon {
  width: 50px;
  fill: #f0f2f7;
  margin-bottom: 2vh;
}

.grid-item button {
  display: flex;
  justify-content: baseline;
  color: #575759;
  font-size: 8.73px;
  font-weight: 400;
  padding: 1vh 2vw;
  border: none;
  border-radius: 5px;
  background-color: #f2f1f4;
}

.grid-item button p {
  margin-right: 1vh;
}

.grid-item button .upload-icon {
  stroke: #101828;
  width: 8.73px;
}