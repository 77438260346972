.wrapper {
  height: 100vh;
}

.wrapper > header {
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-bottom: 1px solid rgb(237, 235, 235);
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
}

.wrapper > header input[type="button"] {
  border-radius: 5px;
  background-color: #5102ff;
  color: white;
  padding: 2vh 3vw;
}

.wrapper > section {
  width: 100%;
}

.wrapper .content {
  width: 100%;
  height: calc(100vh - 95px);
  display: flex;
  flex-direction: column;
  padding: 4rem;
  gap: 2rem;
}

.wrapper .content > h3 {
  font-family: "Axiforma Semibold";
  font-size: 34px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.engagements_con{
  width: 100%;
  height: 95%;
  display: grid;
  grid-template-rows: max-content 1fr;
  gap: 1.5rem;
}

.engagements_con > p{
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #575759;
  display: flex;
  gap: 4px;
}

.engagements_col{
  display: grid;
  gap: 1rem;
}

.engagements{
  display: grid;
  gap: 1.5rem;
  align-content: start;
}

.engagement {
  width: 486px;
  height: 50px;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  border: 1px solid #EBEDF2;
  border-radius: 12px;
  align-items: center;
  align-content: center;
  padding: 0 1rem;
}

.engagement .channel {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.engagement .channel > div h4 {
  font-family: "Axiforma";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;

}

.engagement input[type="button"] {
  /* width: 100%; */
  border-radius: 5px;
  background-color: #f1f2f7;
  color: inherit;
  font-weight: 100;
  box-shadow: none;
  padding: 1.5vh 1vw;
}

.engagement .controls {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.esclamation_icon{
  width: 18px;
  fill: #575759;
}

.channel_icon_con{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #EFF5F0;
}

.channel_icon{
    width: 15px;
}

.channel_icon2{
  width: 15px;
}

.calendar_icon{
  fill: #5100FF;
}

.file_icon{
  fill: #FF00C7;
}