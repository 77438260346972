.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(228, 217, 250);
  /* background: linear-gradient(
    140deg,
    rgba(228, 217, 250, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    #e4d9fa 100%
  ); */

  background: linear-gradient(
    135deg,
    #d7cbfa 0%,
    #d7cbfa 0%,
    white 25%,
    white 75%,
    #d7cbfa 100%,
    #d7cbfa 100%
  );
}

.center {
  margin-top: -20vh;
  text-align: center;
}

.status {
  font-size: 250px;
  color: rgb(155, 45, 45);
  font-family: "Poppins", sans-serif;
}

.message {
  font-size: 25px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin: 0vh 0 2vh 0;
}

input[type="button"] {
  border: none;
  border-radius: 50px;
  padding: 2.5vh 5vh;
  color: white;
  background-color: #5102ff;
  font-weight: 600;
  box-shadow: 1px 2px 5px #9870ef;
}
