.wrapper {
  cursor: pointer;
  /* height: 100vh; */
  font-size: 12px;
  background-color: #ecedf2;
}

.wrapper > header {
  height: 95px;
  display: flex;
  align-items: center;
  padding: 3vh 4vw;
  border-bottom: 1px solid #c6c8cc;
  font-weight: 600;
  font-size: 20px;
}

.wrapper .onboardingIcon {
  width: 24px;
  fill: #292d32;
  stroke: #292d32;
  margin-right: 1vw;
}

.wrapper .content {
  height: auto;
  /* height: calc(100vh - 95px); */
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  padding: 4vh 4vw;
}

.section-1 {
  background-color: #f3f4f6;
  margin-bottom: 3vh;
  padding: 10px 11px;
  border-radius: 16px;
}

.section-1 > .section-1-section {
  border-radius: 8px;
  background-color: #ecedf2;
  padding: 12px 34px;
}

.section-1-section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.section-1-section-header > p {
  font-weight: 600;
  font-size: 20px;
  color: #19191a;
}

.section-1-section-header > p > span {
  font-weight: 600;
  font-size: 20px;
  color: #6f7073;
}

.section-1-section-header > svg {
  margin-left: 15px;
}

.section-1-section-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  border-top: 1px solid #dadbdf;
  padding: 20px 0 10px 0;
}

.section-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  background-color: #f3f4f6;
  margin-bottom: 3vh;
  padding: 12px;
  border-radius: 16px;
}

.section-2 > .card {
  border: 1px solid #ebedf2;
  border-radius: 8px;
}

.section-2 > .card:first-of-type {
  padding: 30px 30px 0 30px;
}

.section-2 > .card:first-of-type > p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 30px;
}

.rapid-icon {
  width: 529px;
  height: 234px;
  /* width: 459px; */
  /* width: 100%;
  height: 171.54px; */
  /* margin-left: -60px; */
}

.section-2 > .card:nth-of-type(2) {
  background-color: #d0d3d8;
  background-image: url("../../assets/images/QuickVideo.png");
  background-size: 100% 100%;
  position: relative;
  padding: 30px 30px;
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-2-thick {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.card-2-thin {
  font-size: 12px;
  font-weight: 400;
  color: #6f7073;
}

.section-3 {
  background-color: #f3f4f6;
  border-radius: 16px;
  padding: 30px 30px;
}

.section-3-header {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
}

.section-3-header .message-icon {
  margin-right: 12px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ecedf2;
  border-radius: 12px;
  margin: 20px 30px;
}

.body-body {
  width: 70%;
  padding: 54px 20px 0 20px;
}

.body-body p {
  text-align: center;
}

.body-body > .body-body-p-1 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 11px;
}

.body-body > .body-body-p-2 {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 43px;
  color: #6f7073;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.flex > p {
  font-size: 12px;
  font-weight: 400;
  width: 50%;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  color: #19191a;
}

.flex > p > span {
  color: #5100ff;
}

.flex > button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px;
  background-color: #5100ff;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.copy-svg {
  margin-left: 11px;
  width: 24px;
}

.code > code > pre {
  background-color: white;
  color: #949699;
  font-size: 12px;
  line-height: 34px;
  border-radius: 12px 12px 0 0;
  box-shadow: 0px 20px 42px 0px #00091a21;
  /* box-shadow: 0px 0px 42px 0px #00091a21; */
}

.base {
  display: flex;
  justify-content: center;
  align-items: center;
}

.base > section {
  /* width: 80%; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 14px;
  text-align: center;
  margin-top: 1vh;
}

.base > section > .button-1 {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  border: 1px solid #949699;
  border-radius: 4px;
  padding: 10px 20px 10px 20px;
  color: #6f7073;
}

.base > section > .button-2 {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  border: 1px solid #949699;
  border-radius: 4px;
  padding: 10px 20px 10px 20px;
  color: #6f7073;
}

.base > section > .button-2 > span {
  margin-right: 1vw;
}

.base > section > button > .paper-plane {
  width: 24px;
  margin-right: 1vw;
}

.base > section > p {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
}

.disable{
  background-color: #d4c0ff !important;
}

.reviewwrapper {
  width: 650px;
  height: 600px;
  padding: 5rem 7rem;
  background-color: #EBEDF2;
  border-radius: 6px;
}

.review_con{
  display: grid;
  justify-items: center;
  gap: 3.5rem;
}

.icon_container{
  width: 102px;
  height: 102px;
  border-radius: 50%;
  background: #5100FF1F;
  display: grid;
  align-items: center;
  justify-items: center;
}

.icon_review_container{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #5100FF;
  display: grid;
  align-items: center;
  justify-items: center;
}

.icon_review{
  height: 33.33px;
  fill: #FFFFFF;
}

.review_con > h4{
  font-family: "Axiforma";
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.review_con > div > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #6F7073;
}

.copy_to_clipboard_con{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 2rem;
}

.copy_to_clipboard{
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 10px;
  border: 1px solid #dcdede;
  box-sizing: border-box;
  border-radius: 8px;
  height: 42px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.copy_to_clipboard > p{
  font-family: var(--montserrat-medium);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: .5px;
  color: #949699;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.copy_to_clipboard > svg{
  font-size: 20px;
  color: #9fa19d;
}

.copy_to_clipboard_con > button {
  background-color: #5100FF !important;
  border: none;
  outline: none;
  width: 75px;
  height: 38px;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: none;
  color: white;
}