.card {
  width: 225px;
  /* height: 126px; */
  border-radius: 6px;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;
}

.main {
  padding: 10px;
  font-size: 10px;
  font-weight: 600;
  color: white;
  text-align: center;
}

.facebook {
  background-color: #1877f2;
}

.instagram {
  background-color: #7823bd;
}

.mail {
  background-color: #ba23bd;
}

.whatsapp {
  background-color: #25d366;
}
