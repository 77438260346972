.custom_toast{
    display: grid;
    gap: 8px;
    padding: 6px;
}

.custom_toast >h5{
    font-family: "Axiforma";
    border: none;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.custom_toast > p{
    font-family: "Axiforma";
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.custom_toast > .success{
    color:#5100FF;
}