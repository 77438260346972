.delete_modal {
  width: 500px;
  padding: 2.5rem;
}

.delete_modal .modal-header .header {
  font-weight: 600;
  margin-bottom: 1vh;
  font-size: 20px;
}

.delete_modal .modal-header .email {
  color: #949699;
}

.delete_modal .modal-content {
  color: #949699;
  margin: 4vh 0 4vh 0;
}

.delete_modal .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.delete_modal .modal-controls input[type="button"] {
  border: none;
  border-radius: 5px;
  padding: 2vh 3vw;
  font-weight: 100;
  box-shadow: none;
}

.delete_modal .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #f0f2f7;
  margin-right: 2vw;
}

.delete_modal .modal-controls input[type="button"]:first-of-type:hover {
  background-color: #e6e6e7;
}

.delete_modal .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #b22424;
}

.delete_modal .modal-controls input[type="button"]:last-of-type:hover {
  background-color: #9f1f1f;
}

.reply_suggested_faq {
  width: 500px;
  padding: 2.5rem;
}

.reply_suggested_faq .modal-header .header {
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 20px;
}

.reply_suggested_faq .modal-header .email {
  color: #949699;
}

.reply_suggested_faq .modal-content {
  color: #949699;
  margin: 4vh 0 4vh 0;
}

.reply_suggested_faq .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.reply_suggested_faq .modal-controls input[type="button"] {
  border: none;
  border-radius: 5px;
  padding: 2vh 3vw;
  font-weight: 100;
  box-shadow: none;
}

.reply_suggested_faq .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #f0f2f7;
  margin-right: 2vw;
}

.reply_suggested_faq .modal-controls input[type="button"]:first-of-type:hover {
  background-color: #e6e6e7;
}

.reply_suggested_faq .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #b22424;
}

.reply_suggested_faq .modal-controls input[type="button"]:last-of-type:hover {
  background-color: #9f1f1f;
}

#uploadfaq_box {
  background-color: white !important;
}

.wrapper {
  height: 100vh;
  font-size: 12px;
  overflow: hidden;
}

.wrapper > header {
  height: 95px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content max-content 1fr;
  align-items: center;
  padding: 3vh 4vw;
  border-bottom: 1px solid rgb(237, 235, 235);
  font-weight: 600;
  font-size: 20px;
  font-family: "Axiforma SemiBold";
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  gap: 10px;
}

.wrapper .trainingIcon {
  width: 24px;
  fill: black;
  stroke: black;
  margin-right: 1vw;
  justify-self: start;
}

.categorise_faq_btn {
  justify-self: end;
  cursor: pointer;
  background-color: #5100ff;
  border: none !important;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  padding: 10px 15px 10px 15px;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.categorise_faq_btn > svg {
  stroke: #ffffff;
}

.faq_btn {
  justify-self: end;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #313233 !important;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  padding: 10px 15px 10px 15px;
  border-radius: 4px;
  color: #313233;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.faq_btn-icon {
  width: 16px;
  fill: #313233;
}

.faq_btn-icon_stroke {
  width: 16px;
  stroke: #313233;
}

.create_faq_category {
  width: 650px;
  padding: 2.5rem;
}

.create_faq_category .modal-header .header {
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 20px;
  font-family: "Axiforma";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.create_faq_category .modal-header .email {
  color: #949699;
}

.create_faq_category .modal-content {
  color: #949699;
  margin: 4vh 0 4vh 0;
}

.create_faq_category .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.create_faq_category .modal-controls input[type="button"] {
  border: none;
  border-radius: 5px;
  padding: 2vh 3vw;
  font-weight: 100;
  box-shadow: none;
}

.create_faq_category .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #f0f2f7;
  margin-right: 2vw;
}

.create_faq_category_form {
  width: 100%;
  /* margin-top: 20px; */
}

.sec-1 {
  width: 100%;
  background-color: #f8f7fa;
  /* height: 160px; */
  margin-bottom: 2vh;
  height: 205px;
  padding: 13px 20px;
  border-radius: 8px;
  border: 1px solid #dfe1e5;
}

.sec-head {
  /* padding: 15px 20px; */
  padding-bottom: 10px;
  border-bottom: 1px solid #dfe1e5;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.011em;
  text-align: left;
  color: #19191a;
}

.sec-body {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 10px;
  align-content: start;
  align-items: start;
  color: #6f7073;
  padding-top: 11px;
  /* padding: 20px; */
}

.esclamation_icon {
  margin-top: 4px;
  width: 17px;
  fill: #88898c;
}

.sec-body > p > span {
  font-size: 12px;
}

.sec-body span {
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.uploadfileButton {
  cursor: pointer;
  margin-top: 1rem;
  height: 31px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #adafb2;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0px 6px;
  border-radius: 4px;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  border-style: dashed;
}

.downloadIcon {
  width: 13px;
  stroke: #5100ff;
}

.checkIcon {
  width: 13px;
  stroke: #03cf92;
}

.create_faq_category_form > form {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 160px 1fr;
  align-content: start;
  align-items: center;
  gap: 14px;
}

.create_faq_category_form > header {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2vh;
}

.create_faq_category_form_group {
  margin-bottom: 1rem;
  display: grid;
  gap: 5px;
  position: relative;
  align-content: start;
}

.create_faq_category_form_group > header {
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  margin-bottom: 20px;
}

.create_faq_category_form_group > label {
  width: max-content;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.create_faq_category_form_group > input {
  width: 100% !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
  outline: none;
  background-color: transparent;
  padding: 0;
  font-family: "Axiforma", sans-serif;
  border: 1px solid #adafb2;
  border-radius: 4px;
  padding: 6px 8px;
}

.create_faq_category_form_group > textarea {
  width: 100%;
  resize: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
  outline: none;
  background-color: transparent;
  padding: 0;
  font-family: "Axiforma", sans-serif;
  border: 1px solid #adafb2;
  border-radius: 4px;
  padding: 6px 8px;
}

.create_faq_category_form_group > textarea::placeholder {
  font-family: "Axiforma", sans-serif;
  color: #949699;
}

.create_faq_category_form_group > input::placeholder {
  font-family: "Axiforma", sans-serif;
  color: #949699;
}

.create_faq_category_form_group > div > input {
  width: 68px;
  height: 26px;
  border-radius: 4px;
  gap: 10px;
  /* border: 1px solid #6f7073; */
  border: 1px solid #ebedf2;
  background-color: white;
  /* color: #6f7073; */
  color: #ebedf2;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.011em;
}

.create_faq_category_form_group:focus-within,
.create_faq_category_form_group:focus-within .input-button {
  border-color: #88898c;
  color: #88898c;
}

/* .create_faq_category_form_group:focus-within,
.create_faq_category_form_group:focus-within label{
  border-color: #5100FF;
  color: #5100FF;
} */

.create_faq_category_form_group:focus-within,
.create_faq_category_form_group:focus-within input,
.create_faq_category_form_group:focus-within textarea {
  border-color: #5100ff;
}

.create_faq_category .modal-controls {
  margin-top: 4rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

.create_faq_category .modal-controls input[type="button"] {
  padding: 10px 20px;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.create_faq_category .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #5100ff;
}

/* .create_faq_category .modal-controls input[type="button"]:first-of-type:hover{
  background-color: #5100FF;
} */

.create_faq_category .modal-controls input[type="button"]:first-of-type {
  color: #6f7073;
  background-color: #f1f1f1;
  border: none;
}

.products_collapse {
  position: absolute;
  right: 7px;
  top: 29px;
}

.products_collapse_icon_cancel {
  width: 10px;
  fill: #19191a;
}

.products_collapse_icon {
  width: 16px;
  transform: rotate(-90deg);
}

.products {
  position: absolute;
  top: 68px;
  width: 100%;
  background-color: white;
  padding: 10px;
  z-index: 2;
  border-radius: 4px;
  box-shadow: 0px 20px 42px 0px #00091a1a;
}

.product {
  font-size: 12px;
  cursor: pointer;
  padding: 5px 0;
}

.wrapper .content {
  /* margin-top: 95px; */
  /* height: calc(100vh - 95px); */
  /* display: flex;
      flex-direction: row; */
  display: grid;
  grid-template-columns: 1fr 392px;
  position: sticky;
  /* padding-top: 50px; */
  background-color: #f3f4f6;
  /* gap: 2rem; */
}

.right_con {
  padding-right: 25px;
  margin-right: 25px;
  overflow-y: scroll;
}

.right_con::-webkit-scrollbar {
  width: 5px;
}

.right_con::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.right_con::-webkit-scrollbar-thumb {
  background: #5100ff;
  border-radius: 20px;
}

.wrapper .content .right {
  position: sticky;
  /* width: 23%; */
  right: 0;
  background-color: white;
}

.new_detected_con {
  display: grid;
  padding: 3rem 1rem;
}

.new_detected_emptycontent {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.new_detected_content_col h4 {
  margin-bottom: 15px;

  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.011em;
  text-align: left;
  color: #19191a;
}

.new_detected_content_col p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 15px;
  color: #757575;
}

.new_detected_content_icon {
  width: 77px;
  height: 77px;
  padding: 17px, 15px, 14px, 16px;
  border-radius: 400px;
  box-shadow: 0px 20px 42px 0px #08001a1a;
  background-color: #ffffff;
  display: grid;
  align-content: center;
  justify-items: center;
}

.question_icon {
  width: 38.33px;
  height: 36.67px;
  fill: #5100ff;
}

.new_detected_content {
  display: grid;
}

.new_detected_content_faqs {
  display: grid;
}

.new_detected_content_faqs_con {
  border-bottom: 1px solid #ebedf2;
  display: grid;
  align-content: center;
  gap: 4px;
}

.new_detected_content_faqs_question {
  display: grid;
  grid-template-columns: max-content 1fr max-content max-content;
  /* justify-content: start; */
  align-content: center;
  gap: 10px;
  min-height: 60px;
  padding: 13px 5px;
  align-items: center;
}

.new_detected_content_faq_question {
  display: grid;
  gap: 5px;
}

.new_detected_content_faq_question > h4 {
  justify-self: start;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
  font-family: "Axiforma";
}

.new_detected_content_faq_question > p {
  justify-self: start;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  line-break: anywhere;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.reply_icon {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: #f0f2f7;
  border: none;
  outline: none;
  display: grid;
  align-items: center;
  justify-items: center;
}

.reply_icon > svg {
  width: 16px;
  fill: #313233;
  transform: rotate3d(1, 30, 1, 180deg);
}

.view_icon {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #c6c8cc;
  display: grid;
  align-items: center;
  justify-items: center;
}

.view_icon > svg {
  width: 16px;
  fill: #313233;
}

.right_head {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1vw;
  justify-content: space-between;
  border-bottom: 1px solid #f0f2f7;
  align-items: center;
  padding: 0 15px;
  height: 60px;
  position: sticky;
  top: 0;
  background-color: white;
}

.right_head > h4 {
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #ff00c7; */
  color: #000000;
}

.right_head > button {
  cursor: pointer;
  background-color: #f1f2f7;
  border: none !important;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 15px 10px 15px;
  border-radius: 2px;
  color: #19191a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.button-wrapper button {
  cursor: not-allowed;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.011em;
  text-align: left;
  border-radius: 4px;
  padding: 10px 15px 10px 15px;
  border: none;
  background-color: #e3e0ed;
  color: white;
}

.new_detected_content_faqs_con > .button-wrapper > button {
  cursor: pointer;
  background-color: #19191a;
}

.wrapper .content .left {
  background-color: #f3f4f6;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px;
  padding-top: 0;
  padding-right: 0;
  /* overflow-y: scroll; */
}

.wrapper .content > .left::-webkit-scrollbar {
  width: 5px;
}

.wrapper .content > .left::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.wrapper .content > .left::-webkit-scrollbar-thumb {
  background: #5100ff;
  border-radius: 20px;
}

.left > .saved-faq {
  width: 95%;
  /* background-color: #ffffff; */
}

.left > .saved-faq > .saved-faq-header_con {
  padding-right: 20px;
}

.saved-faq-header {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #f0f2f7;
  align-items: center;
  position: sticky;
  top: 0px;
  background-color: white;
  width: 100%;
}

.saved-faq-header > .head {
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #5100ff;
}

.saved-faq-header > .tail {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-auto-flow: column;
  gap: 1vw;
  justify-content: end;
}

.saved-faq-header > .tail > button {
  cursor: pointer;
  background-color: #f1f2f7;
  border: none !important;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  padding: 10px 15px 10px 15px;
  border-radius: 2px;
  color: #313233;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.faq_add_product {
  justify-self: end;
  cursor: pointer;
  width: max-content;
  display: grid;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 0 15px;
  border-radius: 4px;
  border: 1px solid #313233;
}

.button-icon {
  width: 15px;
  margin-right: 0.5vw;
}

.left > .saved-faq > .saved-faq-body_con {
  padding-right: 20px;
  width: calc(100% + 5px);
  height: calc(100vh - 270px);
  overflow-y: scroll;
}

.left > .saved-faq > .saved-faq-body_con::-webkit-scrollbar {
  width: 5px;
}

.left > .saved-faq > .saved-faq-body_con::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.left > .saved-faq > .saved-faq-body_con::-webkit-scrollbar-thumb {
  background: #5100ff;
  border-radius: 20px;
}

.saved-faq-body {
  padding: 20px;
  background-color: #ffffff;
  /* height: 100%; */
}

.loading {
  width: 100%;
  text-align: center;
  color: #6f7073;
  font-weight: 600;
}

.add-faq {
  width: 100%;
  /* margin-top: 20px; */
}

.add-faq > header {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2vh;
}

.add-faq > form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-faq textarea {
  width: 100%;
  outline: none;
  margin-bottom: 1vh;
  resize: none;
}

/* .add-faq textarea::placeholder {
  
} */

.add-faq textarea:focus {
  outline: 1px solid #5100ff;
}

.add-faq > form > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.add-faq > form > div > input {
  width: 125px;
  height: 35px;
  top: 331px;
  left: 1229px;
  padding: 10px 15px 10px 15px;
  border-radius: 4px;
  gap: 10px;
  border: 1px solid #6f7073;
  background-color: white;
  color: #6f7073;
}

.update-faq {
  width: 100%;
  /* margin-top: 20px; */
}

.update-faq > header {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2vh;
}

.update-faq > form {
  margin-bottom: 2vh;
  /* border: 1px solid #88898c; */
  border: 1px solid #ebedf2;
  border-radius: 8px;
  padding: 20px 20px;
}

.update-faq > form > header {
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  margin-bottom: 20px;
}

.update-faq > form > textarea {
  width: 100%;
  resize: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #949699;
  outline: none;
  background-color: transparent;
  padding: 0;
  font-family: "Axiforma", sans-serif;
}

.update-faq > form > textarea::placeholder {
  font-family: "Axiforma", sans-serif;
  color: #949699;
}

.update-faq > form > div > input {
  /* width: 125px;
  height: 35px;
  top: 331px;
  left: 1229px; */
  /* here */
  /* width: 68px;
  height: Hug 26px;
  top: 84px;
  left: 20px;
  gap: 7px; */
  /*  */
  /* padding: 10px 15px 10px 15px; */
  width: 68px;
  height: 26px;
  border-radius: 4px;
  gap: 10px;
  /* border: 1px solid #6f7073; */
  border: 1px solid #ebedf2;
  background-color: white;
  /* color: #6f7073; */
  color: #ebedf2;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.011em;
}

.update-faq > form:focus-within,
.update-faq > form:focus-within .input-button {
  border-color: #88898c;
  color: #88898c;
}

.update_suggested-faq {
  width: 100%;
  /* margin-top: 20px; */
}

.update_suggested-faq > header {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2vh;
}

.update_suggested-faq > form {
  margin-bottom: 2vh;
  /* border: 1px solid #88898c; */
  border: 1px solid #ebedf2;
  border-radius: 8px;
  padding: 20px 20px;
  position: relative;
}

/* .update_suggested-faq > form:first-of-type{
  padding: 20px 20px;
} */

.update_suggested-faq > form > header {
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  margin-bottom: 20px;
}

.update_suggested-faq > form > label {
  width: max-content;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  position: absolute;
  top: -10px;
  padding: 0 3px;
  background-color: white;
}

.update_suggested-faq > form > textarea {
  width: 100%;
  resize: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #949699;
  outline: none;
  background-color: transparent;
  padding: 0;
  font-family: "Axiforma", sans-serif;
}

.update_suggested-faq > form > textarea::placeholder {
  font-family: "Axiforma", sans-serif;
  color: #949699;
}

.update_suggested-faq > form > div > input {
  /* width: 125px;
  height: 35px;
  top: 331px;
  left: 1229px; */
  /* here */
  /* width: 68px;
  height: Hug 26px;
  top: 84px;
  left: 20px;
  gap: 7px; */
  /*  */
  /* padding: 10px 15px 10px 15px; */
  width: 68px;
  height: 26px;
  border-radius: 4px;
  gap: 10px;
  /* border: 1px solid #6f7073; */
  border: 1px solid #ebedf2;
  background-color: white;
  /* color: #6f7073; */
  color: #ebedf2;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.011em;
}

.update_suggested-faq .reply-products-select {
  width: 100%;
  height: 30px;
  border: 1px solid #ebedf2;
  color: #ebedf2;
  border-radius: 4px;
  float: right;
  outline: none;
  /* text-align: center; */
  padding: 0 1vw;
}

.update_suggested-faq > form:focus-within,
.update_suggested-faq > form:focus-within .input-button,
.update_suggested-faq > form:focus-within .reply-products-select {
  border-color: #88898c;
  color: #88898c;
}

.update_suggested-faq > form:focus-within,
.update_suggested-faq > form:focus-within label {
  border-color: #5100ff;
  color: #5100ff;
}

.update_suggested-faq_input {
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.update_suggested-faq_input > input[type="submit"] {
  height: 35px;
  font-size: 16px;
}

.faq_tab_con {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  justify-content: start;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 50px;
  background-color: #f3f4f6;
}

.faq_tab {
  /* cursor: pointer; */
  padding: 5px;
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: max-content;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #adafb2;
  border-bottom: 1px solid transparent;
}

.faq_tab_hover {
  /* border-bottom: 1px solid #19191A; */
  color: #19191a;
}

.coming-soon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 260px);
  background: #ffffff;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  position: relative;
}

.coming-soon-container_content {
  text-align: center;
  max-width: 600px;
  display: grid;
  gap: 2rem;
  align-items: center;
  justify-items: center;
}

.coming-soon-title {
  font-size: 3rem;
  font-weight: bold;
  /* background: linear-gradient(120deg, #3f2c93 0%, #dc45ee 100%); */
  background: -webkit-linear-gradient(10deg, #3f2c93 0%, #dc45ee 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  opacity: 0.9;
}

.coming-soon-description {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #949699;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.coming-soon-container_content {
  animation: fadeIn 1.5s ease-in-out;
}

.coming-soon_button {
  cursor: pointer;
  outline: none;
  box-shadow: 0px 30px 50px 0px #00091a17;
  border: none;
  width: max-content;
  height: 35px;
  border-radius: 4px;
  background-color: #5100ff;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 0 8px;
}

.coming-soon_button > p {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.coming-soon_button_icon {
  width: 18px;
}

.coming-soon_button_icon > path {
  stroke: #ffffff;
}

/* .coming-soon_button_empty_icon{

} */

.coming-soon_button > div {
  width: 86px;
  height: 10px;
  border-radius: 12px;
  background-color: #d9d9d9;
}

.pagination {
  position: sticky;
  bottom: 1.5vh;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 0 1.2rem;
  background-color: white;
  height: 68px;
  align-items: center;
}

.pagination_btn_left {
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid #c6c8cc;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_left > svg {
  fill: #292d32;
}

.pagination_btn_right {
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  background-color: #88898c;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_right > svg {
  fill: #f3f4f6;
}

.pagination_num {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.pagination_icon {
  width: 5px;
}

.totalSuggestedFaq {
  font-family: "Axiforma Bold";
  /* color: #ff00c7; */
  color: #fc3301;
  font-size: 40px;
}
