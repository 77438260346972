.wrapper {
  height: 100vh;
  overflow: hidden;
}

.wrapper > header {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding: 3vh 4vw;
  border-bottom: 1px solid rgb(237, 235, 235);
  background-color: white;
  box-shadow: 0px 0px 9px 0px #0000000D;
  z-index: 2;
}

.wrapper > header > h1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.wrapper > section {
  width: 100%;
}

.wrapper .content {
  width: 100%;
  margin-top: 73px;
  /* padding: 4vh 4vw; */
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.wrapper .content .content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4vh 4vw;
  border-bottom: 1px solid rgb(237, 235, 235);
  position: sticky;
  width: 100%;
  top: 73px;
  background-color: white;
  z-index: 2;
}

.wrapper .content .content-header .left {
  flex: 1;
}

.wrapper .content .content-header .left p:first-of-type {
  
  font-size: 20px;
  color: #19191a;
  font-weight: 600;
  margin-bottom: 2vh;
}

.wrapper .content .content-header .left p {
  
  font-weight: 400;
  color: #949699;
  font-size: 14px;
}

.wrapper .content .content-header .right {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.wrapper .content .content-header .right input[type="button"]:first-of-type {
  margin-right: 2vh;
  background-color: #efe5ff;
}

.add_product{
  background-color: #5100FF !important;
}

.wrapper .content .content-header .right input[type="button"] {
  
  width: 142px;
  height: 38px;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  border: 0px 0px 2px 0px;
  opacity: 0.10000000149011612px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: none;
  background-color: #191919;
  color: white;
}

.content-body{
  width: 85%;
  padding: 0 0 3rem;
  margin: auto;
  margin-top: 10vh;
}

.wrapper .content .content-body .grid {
  width: 50%;
  display: grid;
  gap: 50px;
  grid-template-columns: auto auto auto;
}

.inventory_table_container{
  width: 100%;
  padding: 0 0 3rem;
  margin: auto;
  /* margin-top: 1vh; */
}

.inventory_table{
  width: 100%;
  border-collapse: collapse
}

.inventory_table > thead{
  position: sticky;
  top: 205.89px;
  background-color: white;
  display: grid;
  justify-items: center;
  border-bottom: 1px solid #F0F2F7;
  z-index: 1;
}

.inventory_table > tbody{
  margin: auto;
  display: block;
  height: calc(100vh - 160px);
  overflow: scroll;
}

.inventory_table tr{
  width: 85%;
  display: grid;
  grid-template-columns: 26% 18% 18% 20% 18%;
  justify-content: center;
  align-content: center;
  margin: auto;
}

.inventory_table > tbody > tr:last-child{
  padding-bottom: 2rem;
}

.inventory_table > thead > tr > th{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #6F7073;
  padding: 1rem;
  height: 74px;
  display: grid;
  align-items: center;
}

.inventory_table > tbody > tr > td{
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #949699;
  padding: 1rem;
  border: 1px solid #F0F2F7;
  height: 85px;
}

.inventory_table > tbody > tr:first-child > td{
  border-top: none;
}

.product_name{
  display: grid;
  grid-template-columns: 1.5fr 1fr !important;
  gap: 1rem;
}

.product_name > h5{
  
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
  /* justify-self: end; */
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  height: 3em;

}

.product_name > img{
  width: 61px;
  height: 61px;
}

.inventory_table > tbody > tr > td > div{
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.inventory_table > tbody > tr > td > div > p{
  
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.table_icon_fill{
  width: 15px;
  fill: #6F7073;
}

.table_icon_fill2{
  width: 21px;
  fill: #6F7073;
}

.table_icon_stroke{
  width: 21px;
  stroke: #6F7073
}

.edit_product{
  grid-template-columns: max-content max-content max-content !important;
}

.edit_product_con{
  width: 23px;
  height: 27px;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  background-color: #EBEDF2;
  position: relative;
  /* z-index: -1; */
}

.edit_product_icon{
  width: 3px;
  fill: #292D32;
}

.more_option_con{
  position: absolute;
  top: 23px;
  left: -150px;
  border: 1px solid #F0F2F7;
  width: 150px;
  height: 100px;
  border-radius: 4px;
  background-color: white;
  padding: 10px;
  display: grid;
  align-items: center;
}

.more_option_con > h5{
  cursor: pointer;
  
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  height: 100%;
  display: grid;
  align-items: center;
}

.more_option_con > h5:first-child{
  border-bottom: 1px solid #F0F2F7;
}

@media screen and (max-width: 250px) {
  .wrapper .content .content-body .grid {
    width: 100%;
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 400px) {
  .wrapper .content .content-body .grid {
    width: 100%;
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 600px) {
  .wrapper .content .content-body .grid {
    width: 100%;
  }

  .wrapper .content .content-header .right {
    flex-direction: column;
    justify-content: right;
    align-items: end;
  }

  .wrapper .content .content-header .right input[type="button"] {
    margin: 0 0 1vh 0 !important;
  }
}

.delete_modal{
  width: 500px;
  padding: 1rem;
}

.delete_modal .modal-header .header {
  font-weight: 600;
  margin-bottom: 1vh;
  font-size: 20px;
}

.delete_modal .modal-header .email {
  color: #949699;
}

.delete_modal .modal-content {
  color: #949699;
  margin: 4vh 0 4vh 0;
}

.delete_modal .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.delete_modal .modal-controls input[type="button"] {
  border: none;
  border-radius: 5px;
  padding: 2vh 3vw;
  font-weight: 100;
  box-shadow: none;
}

.delete_modal .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #F0F2F7;
  margin-right: 2vw;
}

.delete_modal .modal-controls input[type="button"]:first-of-type:hover{
    background-color: #e6e6e7;
}

.delete_modal .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #B22424;
}

.delete_modal .modal-controls input[type="button"]:last-of-type:hover{
    background-color: #9f1f1f;
}

.pagination{
  position: sticky;
  bottom: 0;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 0 1.2rem;
  background-color: white;
  height: 68px;
  align-items: center;
}

.pagination_btn_left{
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid #C6C8CC;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_left > svg{
  fill: #292D32;
}

.pagination_btn_right{
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  background-color: #88898C;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_right > svg{
  fill: #F3F4F6;
}

.pagination_num{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.pagination_icon{
  width: 5px;
}