.checkIcons {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.checkIcons > div > input:checked{
  justify-self: center;
  accent-color: #F9F5FF;
  width: max-content;
  border-radius: 4px;
}

.checkIcons > div{
  height: 19px;
}

/* .checkIcons > img:not(:last-of-type) {
  margin-right: 1vh;
} */

.checkIcons p {
  font-weight: 600;
  margin-left: 1.5vw;
}

.starIcons_checked{
  fill: #EAC409;
  stroke: #EAC409;
  width: 15px;
  margin-top: 2px;
}

.starIcons{
  fill: #949699;
  /* stroke: #949699; */
  width: 15px;
  margin-top: 2px;
}

.list-item {
  justify-content: center;
  align-items: center;
}

.list-item:hover {
  background-color: #f3f4f6;
}

.selected {
  background-color: #f3f4f6;
}

.message-date {
  font-size: 10px;
  color: rgb(171, 169, 169);
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-text {
  /* width: 40%; */
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.message-text > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.indicator-circle {
  background: #CBB3FF;
  height: 12px;
  width: 12px;
  display: flex;
  padding: 2px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  animation: blink 10s infinite;
}

@keyframes blink {
  0%, 50%, 100% {
    /* opacity: 1; */
  }
  25%, 75% {
    /* opacity: 0; */
  }
}
.indicator-circle .inner-circle{
  background: linear-gradient(#9027E3,#4A19D2);
  height: 6px;
  width: 6px;
  border-radius: 6px;
}