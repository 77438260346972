.teams_container{
  display: grid;
  height: calc(100vh - 255px);
  overflow-y: scroll;
}

.usersList {
  width: 80%;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.no_teams{
  display: grid;
  justify-items: center;
  gap: 1rem;
  align-self: center;
}

.no_teams > p{
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #949699;
}

.create_team{
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  width: 157px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #C6C8CC;
  box-shadow: 0px 30px 50px 0px #00091A17;
  background-color: #EEE9FF;
}

.create_team > h4{
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;

}

.inviteicon{
  stroke: #5100FF;
  width: 15px;
}

.team_form{
  width: 500px;
  height: max-content;
  align-content: start;
  display: grid;
  gap: 1rem;
  align-content: space-between;
  padding: 2rem 3rem;
}

.team_form > h2{
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.form_group{
  display: grid;
  gap: 9px;
}

.form_group > label, .form_group2 > label{
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.form_group > input, .form_group2 > input{
  height: 50px;
  width: auto;
  border: 1px solid #f0f2f7;
  border-radius: 6px;
  padding: 10px 15px;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  color: #19191A;
}

.form_group > input:focus{
  background-color: #F8F7FA;
  border-color: #5100FF;
}

.form_group2{
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
}

.form_group2 > div{
  height: 30px;
  width: 88px;
  border: 1px solid #ADAFB2;
  border-radius: 6px;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  color: #19191A;
  position: relative;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-auto-flow: column;
  padding: 0 4px;
}

.form_group2 > div > p{
  width: max-content;
  text-overflow: ellipsis;
  overflow: hidden;
}

.form_group2 > div > p{
  height: 18px;
}

.more_option_con{
  position: absolute;
  top: 17px;
  right: -75px;
  border: 1px solid #F0F2F7;
  width: 92px;
  height: 64px;
  border-radius: 8px;
  background-color: white;
  padding: 10px;
  display: grid;
  align-items: center;
}

.more_option_con > h5{
  cursor: pointer;
  
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  height: 100%;
  display: grid;
  align-items: center;
  color: #6F7073;
}

.send_team{
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  width: max-content;
  gap: 1rem;
  height: 38px;
  border-radius: 4px;
  box-shadow: 0px 30px 50px 0px #00091A17;
  background-color: #5100FF;
  padding: 10px 20px 10px 20px;
  justify-self: end;

}

.send_team > h4{
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;

}

.inviteicon{
  stroke: #5100FF;
  width: 15px;
}

.sendicon{
  stroke: #FFFFFF;
  width: 15px;
}

.collapse_icon{
  width: 18px;
  transform: rotateZ(-90deg);
}

.confirmation_modal{
  width: 500px;
  padding: 2.5rem;
  display: grid;
  gap: 1rem;
}

.confirmation_modal .modal-header .header {
  font-family: "Axiforma";
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-size: 20px;
}

.confirmation_modal .modal-header .email {
  color: #949699;
}

.confirmation_modal .modal-content {
  font-family: "Axiforma Medium";
  color: #949699;
  margin: 0vh 0 1rem 0;
  font-size: 12px;
  font-weight: 400;
}

.confirmation_modal .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.confirmation_modal .modal-controls input[type="button"] {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: 100;
  box-shadow: none;
  height: 40px;
}

.confirmation_modal .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #F0F2F7;
  margin-right: 2vw;
  width: 88px;
  font-size: 12px;
}

.confirmation_modal .modal-controls input[type="button"]:first-of-type:hover{
    background-color: #e6e6e7;
}

.confirmation_modal .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #5100FF;
  width: 101px;
  font-size: 12px;
}

.confirmation_modal .modal-controls input[type="button"]:last-of-type:hover{
    background-color: #5100FF;
}

.search2{
  height: 30px;
  width: 144px;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 4px;
  border: 1px solid #5100FF;
  border-radius: 4px;
  padding: 4px 10px;
  align-items: center;
}

.search_icon{
  width: 11.31px;
  fill: #88898C;
}

.search2 > input{
  border: none;
  outline: none;
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

.escalate_department_con > .department:not(:last-child){
  border-bottom: 1px solid #EBEDF2;
}

.department_con{
  width: 124px;
  height: 98px;
  border-radius: 8px;
  box-shadow: 0px 20px 42px 0px #00091A1A;
  background-color: white;
  display: grid;
  align-content: start;
  /* position: absolute; */
  overflow-y: scroll;
}

.department_con::-webkit-scrollbar{
  display: none;
}

.department_con > .department:not(:last-child){
  border-bottom: 1px solid #EBEDF2;
}

.department{
  cursor: pointer;
  padding: 7px 1rem;
}

.department > h4{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.selected_department{
  width: 124px;
  height: 30px;
  border-radius: 4px;
  background-color: #F0F2F7;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
}

.selected_department >h4{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.remove_department{
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #FFFFFF;
}

.cancel_icon{
  width: 7px;
  fill: #19191A;
}