.checkIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.checkIcon1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.checkIcon1 > img:not(:last-of-type) {
  margin-right: 1vh;
}

.checkIcon1 p {
  font-weight: 600;
  margin-left: 1.5vw;
}

.checkIcon2{
  position: relative;
  width: 38px;
  height: 38px;
  padding: 5px;
  border-radius: 20px;
  border: 1.5px solid #C6C8CC;
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 10px;
}

.receiveIcon{
  fill: #292D32;
  width: 20px;
}

.list-item {
  justify-content: center;
}

.list-item:hover {
  background-color: #f3f4f6;
}

.selected {
  background-color: #f3f4f6;
}

.message-text {
  display: grid;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.message-text > h3 {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.message-text_bottom{
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 1rem;
}

.message-text_bottom > h6 {
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.message-date {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.message-date > h6{
  font-size: 10px;
  color: rgb(171, 169, 169);
  font-weight: 600;
}

.toggle_escalated{
  /* cursor: pointer;
  border-radius: 2px;
  border: 0.5px solid #CAEAD1;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 2px 5px 2px 10px;
  gap: 3px; */
  width:56px;
  height:19px;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 0.5px;
  gap: 4px;

}

.toggle_escalated > p{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.escalated{
  color: #313233;
}

.not_escalated{
  color: #2AAB46;
}

.escalated + svg{
  width: 7px;
  transform: rotateZ(-90deg);
  stroke: #313233;
}

.not_escalated + svg{
  width: 7px;
  transform: rotateZ(-90deg);
  stroke: #2AAB46;
}

.escalated + svg > path{
  stroke: #313233;
}

.not_escalated + svg > path{
  stroke: #2AAB46;
}

.count{
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: #f7f8fc;
  border: 1px solid #c6c8cc;
  border-radius: 4px;
  height: 16px;
  padding: 0 3px;
  width: max-content;
  color: #5100ff;
  font-family: "Axiforma";
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: left;
}