.wrapper {
  height: 100vh;
  width: 100%;
  position: static;
  overflow: hidden;
}

.wrapper > header {
  text-transform: capitalize;
  width: 100%;
  height: 6rem;
  position: sticky;
  display: grid;
  grid-auto-flow: column;
  /* justify-content: space-between; */
  gap: 1rem;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding: 1rem 3rem;
  border-bottom: 1px solid rgb(237, 235, 235);
  background-color: white;
  z-index: 5;
}

.wrapper > header > h1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
}
.resolution-time-filter {
  display: flex;
  align-items: center;
}
.resolution-time-filter > p {
  font-size: 12px;
  font-weight: 600;
  padding: 0 7px;
}
.form_group2 > div {
  cursor: pointer;
  height: 36px;
  border-radius: 6px;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  width: 115px;
  color: #19191a;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-auto-flow: column;
  grid-template-columns: max-content 1fr max-content;
  padding: 0 8px;
  gap: 8px;
  background-color: #f7f8fc;
}

.form_group2 > div > p {
  text-transform: capitalize;
  width: max-content;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.011em;
  text-align: left;
  color: #19191a;
}

.form_group2 > div > p {
  height: 18px;
}
.collapse_icon {
  width: 15px;
  stroke: #88898c;
  transform: rotateZ(-90deg);
}
.more_option_con {
  position: absolute;
  top: 42px;
  left: 0;
  border: 1px solid #5100ff;
  width: 115px;
  /* height: 115px; */
  border-radius: 8px;
  background-color: white;
  display: grid;
  padding: 5px 5px;
  align-items: center;
  box-shadow: 0px 20px 42px 0px #00091a1a;
  grid-template-rows: max-content max-content;
}

.more_option_con > div {
  align-self: start;
}
.more_option_con > div > hr {
  height: 0.2px;
  margin: 2px;
  background-color: #ebedf2;
}
.more_option_con > div:last-child {
  align-self: start;
}

.selectedMode {
  background-color: #e5eeff;
  border-radius: 5px;
}

.more_option_con > div {
  /* padding: 0 9px; */
}

.more_option {
  height: 37px;
  display: flex;
  text-align: left;
  align-items: center;
  gap: 10px;
  padding: 0 2px;
}

.more_option_con > div > .more_option:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}

.more_option > h5 {
  cursor: pointer;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  height: 100%;
  display: grid;
  align-items: center;
  color: #6f7073;
}

.learn_more {
  height: 37px;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: space-between;
}

.learn_more > h5 {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.header_col2 {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  justify-content: end;
}

.filters {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;
}

.filters > h4 {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
  text-transform: none;
}

.header_button {
  cursor: pointer;
  width: 115px;
  height: 36px;
  padding: 0 10px;
  border-radius: 4px;
  background-color: #f7f8fc;
  border: 1px solid #f0f2f7;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
}

.header_button > p {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #19191a;
}

.header_btn_icon {
  height: 12px;
  fill: #88898c;
}

.header_btn_icon_stroke {
  height: 16px;
  stroke: #19191a;
}

.resolve_btn {
  width: 114px;
  height: 34px;
  padding: 5px 10px;
  border-radius: 4px;
  background: #5100ff;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
}

.search2 {
  width: 279px;
  height: 36px;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 10px;
  border: 1px solid #f0f2f7;
  border-radius: 8px;
  /* margin: 20px 0; */
  padding: 10px;
  align-items: center;
}

.search_icon {
  width: 15.31px;
}

.search2 > input {
  border: none;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.wrapper > section {
  width: 100%;
}

.wrapper .content {
  width: 100%;
  height: calc(100vh - 79.89px);
  /* margin-top: 73px; */
  /* padding: 4vh 4vw; */
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.wrapper .content .content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 4vh 4vw; */
  border-bottom: 1px solid rgb(237, 235, 235);
}

.wrapper .content .content-header .left {
  flex: 1;
}

.wrapper .content .content-header .left p:first-of-type {
  font-size: 20px;
  color: #19191a;
  font-weight: 600;
  margin-bottom: 2vh;
}

.wrapper .content .content-header .left p {
  color: #949699;
  font-size: 14px;
}

.wrapper .content .content-header .right {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.wrapper .content .content-header .right input[type="button"]:first-of-type {
  margin-right: 2vh;
  background-color: #efe5ff;
}

.add_product {
  background-color: #5100ff !important;
}

.wrapper .content .content-header .right input[type="button"] {
  width: 142px;
  height: 38px;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  border: 0px 0px 2px 0px;
  opacity: 0.10000000149011612px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: none;
  background-color: #191919;
  color: white;
}

.content-body {
  width: 85%;
  /* padding: 0 0 3rem; */
  margin: auto;
  margin-top: 10vh;
}

.wrapper .content .content-body .grid {
  width: 50%;
  display: grid;
  gap: 50px;
  grid-template-columns: auto auto auto;
}

.ticket_table_container {
  width: 100%;
  padding: 0 0 3rem;
  margin: auto;
}

.ticket_table tr {
  width: 100%;
  display: grid;
  grid-template-columns: 10% 15% 10% 15% 17% 10% 82px;
  justify-content: center;
  align-content: center;
}

.ticket_table {
  width: 100%;
  border-collapse: collapse;
}

.ticket_table > thead {
  background-color: #f0f2f7;
  height: 87px;
  display: grid;
  position: sticky;
  top: 73px;
  /* width: 76%; */
}

.ticket_table > tbody {
  display: block;
  height: calc(100vh - 227px);
  overflow-y: scroll;
  padding-bottom: 2rem;
}

.ticket_table > tbody::-webkit-scrollbar {
  display: none;
}

/* tr{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
} */

.ticket_table > thead > tr > th {
  color: #19191a;
  /* padding: 1rem; */
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 3.5px;
}

.ticket_table > tbody > tr:hover {
  background-color: #f0f2f7;
}

.selected {
  background-color: #e5eeff;
}

.ticket_table > tbody > tr > td {
  color: #19191a;
  /* padding: 1rem; */
  border-bottom: 1px solid #e6e8ed;
  height: 63px;
  display: grid;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  align-content: center;
  padding: 0 3.5px;
}

.ticket_table > tbody > tr > td > input:checked {
  justify-self: center;
  accent-color: #f9f5ff;
  width: max-content;
  border-radius: 4px;
}

.ticket_table > tbody > tr > td > h5 {
  font-family: "Axiforma";
  font-weight: 500;
  height: 100%;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.happy {
  color: #1cd75a;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
}

.angry {
  color: #fc3301;
  /* color: #ff00c7; */
  font-size: 12px;
  font-weight: 500;
  font-family: "Axiforma";
}

.product_name {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 1rem;
}

.product_name > h5 {
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.product_name > img {
  width: 61px;
  height: 61px;
}

.table_icon_fill {
  width: 15px;
  fill: #6f7073;
}

.table_icon_fill2 {
  width: 21px;
  fill: #6f7073;
}

.table_icon_stroke {
  width: 21px;
  stroke: #6f7073;
}

.ticket_details_modal {
  display: grid;
  width: 550px;
  border-radius: 8px;
  overflow: hidden;
}

.ticket_details_modal_row_1 {
  display: grid;
  background-color: white;
  padding: 3rem 2.5rem 2rem;
  gap: 1rem;
}

.ticket_details_modal_info {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

.ticket_details_modal_info > h1 {
  text-transform: capitalize;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
}

.ticket_details_modal_info > p {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #949699;
}

.ticket_details_modal_buttons {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 1rem;
  justify-content: start;
}

.ticket_details_modal_button {
  cursor: pointer;
  width: max-content;
  height: 30px;
  padding: 0 13px;
  border-radius: 4px;
  gap: 6px;
  outline: none;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.sla_status {
  width: max-content !important;
  background-color: transparent;
  border: 0.7px solid #c6c8cc;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 3px;
  background-color: #f7f8fc;
}

.view_conversation_button2 {
  width: max-content !important;
  background-color: transparent;
  border: 0.7px solid #c6c8cc;
  color: #19191a;
}

.view_conversation_button {
  background-color: #f0f2f7;
  border: 1px solid #dfe1e5;
  color: #313233;
}

.mark_resolved_button {
  border: 1px solid #dcccff;
  background-color: #eee6ff;
  color: #19191a;
}

.escalate_button {
  grid-template-columns: max-content;
  border: 1px solid #f8d2ed;
  background-color: #ffddf5;
  color: #19191a;
}

.view_conversation_button > svg {
  width: 10px;
  stroke: #949699;
}

.ticket_details_modal_button2 {
  width: 72px;
  cursor: pointer;
  height: 30px;
  padding: 0 10px;
  border-radius: 4px;
  outline: none;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.ticket_details_modal_button2 > h4 {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #19191a;
}

.ticket_details_modal_button > h4 {
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: inherit;
}

.arrow_right_icon {
  width: 7px;
  stroke: #949699;
}

.mark_resolved_icon {
  width: 15px;
  stroke: #19191a;
}

.ticket_details_modal_row_2 {
  padding: 2rem;
  background-color: #f7f8fc;
  height: 225px;
  display: grid;
  gap: 1.5rem;
  grid-template-rows: max-content 1fr;
  align-content: start;
}

.ticket_details_modal_row_2 > h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.ticket_details_modal_more_info {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 1rem;
  justify-content: space-between;
}

.ticket_details_mores {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 1.5rem;
}

.ticket_details_more {
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
  align-items: center;
}

.ticket_details_more_icon {
  width: 9px;
  fill: #6f7073;
}

.ticket_details_more_icon_stroke {
  width: 12px;
  stroke: #6f7073;
}

.ticket_details_more > h4 {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  margin-top: 2px;
  text-align: left;
  color: #949699;
}

.ticket_details_modal_category {
  display: grid;
  gap: 4px;
  align-content: start;
}

.ticket_details_modal_category > div {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 3px;
}

.category_icon {
  width: 18px;
  fill: #6f7073;
}

.category_icon_stroke {
  width: 18px;
  stroke: #6f7073;
}

.ticket_details_modal_category > div > h3 {
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #5100ff;
}

.ticket_details_modal_category > div > h4 {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

@media screen and (max-width: 250px) {
  .wrapper .content .content-body .grid {
    width: 100%;
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 400px) {
  .wrapper .content .content-body .grid {
    width: 100%;
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 600px) {
  .wrapper .content .content-body .grid {
    width: 100%;
  }

  .wrapper .content .content-header .right {
    flex-direction: column;
    justify-content: right;
    align-items: end;
  }

  .wrapper .content .content-header .right input[type="button"] {
    margin: 0 0 1vh 0 !important;
  }
}

.confirmation_modal {
  width: 500px;
  padding: 2.5rem;
}

.confirmation_modal .modal-header .header {
  font-family: "Axiforma";
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-size: 20px;
}

.confirmation_modal .modal-header .email {
  color: #949699;
}

.confirmation_modal .modal-content {
  font-family: "Axiforma Medium";
  color: #949699;
  margin: 0vh 0 1rem 0;
  font-size: 12px;
  font-weight: 400;
}

.confirmation_modal .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.confirmation_modal .modal-controls input[type="button"] {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: 100;
  box-shadow: none;
  height: 40px;
}

.confirmation_modal .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #f0f2f7;
  margin-right: 2vw;
  width: 88px;
  font-size: 12px;
}

.confirmation_modal .modal-controls input[type="button"]:first-of-type:hover {
  background-color: #e6e6e7;
}

.confirmation_modal .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #5100ff;
  width: 101px;
  font-size: 12px;
}

.confirmation_modal .modal-controls input[type="button"]:last-of-type:hover {
  background-color: #5100ff;
}

.form_group {
  display: grid;
  gap: 9px;
  margin: 0vh 0 3rem 0;
}

.form_group > label {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
}

.form_group > textarea {
  height: 60px;
  width: auto;
  border: 1px solid #f0f2f7;
  border-radius: 6px;
  padding: 10px 15px;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  resize: none;
  color: #19191a;
}

.form_group > textarea:focus {
  background-color: #f8f7fa;
  border-color: #5100ff;
}

.pagination {
  position: sticky;
  bottom: 0;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 0 1.2rem;
  background-color: white;
  height: 68px;
  align-items: center;
}

.pagination_btn_left {
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid #c6c8cc;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_left > svg {
  fill: #292d32;
}

.pagination_btn_right {
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  background-color: #88898c;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_right > svg {
  fill: #f3f4f6;
}

.pagination_num {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.pagination_icon {
  width: 5px;
}

.escalate_department_con {
  width: 151px;
  height: 156px;
  border-radius: 8px;
  box-shadow: 0px 20px 42px 0px #00091a1a;
  background-color: white;
  display: grid;
  align-content: start;
  position: absolute;
  overflow: hidden;
}

.search_input {
  padding: 10px;
}

.search {
  height: 26px;
  width: 130px;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 4px;
  border: 1px solid #5100ff;
  border-radius: 4px;
  padding: 4px 10px;
  align-items: center;
}

.search_icon {
  width: 11.31px;
  fill: #88898c;
}

.search > input {
  border: none;
  outline: none;
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

.escalate_department_con > .department:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}

.department_con {
  overflow-y: scroll;
}

.department_con::-webkit-scrollbar {
  display: none;
}

.department_con > .department:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}

.department {
  cursor: pointer;
  padding: 7px 1rem;
}

.department > h4 {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.selected_department {
  width: 124px;
  height: 30px;
  border-radius: 4px;
  background-color: #f0f2f7;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
}

.selected_department > h4 {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.remove_department {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #ffffff;
}

.cancel_icon {
  width: 7px;
  fill: #19191a;
}

.filter_escalate_department_con {
  /* box-shadow: 0px 20px 42px 0px #00091A1A; */
  display: grid;
  justify-items: center;
  position: relative;
}

/* .filter_search_input{
  padding: 10px;
} */

.filter_search2 {
  height: 30px;
  width: 130px;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 4px;
  border: 1px solid #5100ff;
  border-radius: 4px;
  padding: 4px 10px;
  align-items: center;
  background-color: white;
}

.filter_search_icon {
  width: 11.31px;
  fill: #88898c;
}

.filter_search2 > input {
  border: none;
  outline: none;
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

.filterescalate_department_con > .department:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}

.filter_department_con {
  width: 124px;
  height: 98px;
  border-radius: 8px;
  box-shadow: 0px 20px 42px 0px #00091a1a;
  background-color: white;
  display: grid;
  align-content: start;
  overflow-y: scroll;
  position: absolute;
  top: 2.2rem;
}

.filter_department_con::-webkit-scrollbar {
  display: none;
}

.filter_department_con > .department:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}

.filter_department {
  cursor: pointer;
  padding: 7px 1rem;
}

.filter_department > h4 {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.filter_selected_department {
  width: 124px;
  height: 36px;
  border-radius: 4px;
  background-color: #f0f2f7;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  /* margin-bottom: 1rem; */
}

.filter_selected_department > h4 {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.filter_remove_department {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #ffffff;
}

.filter_cancel_icon {
  width: 7px;
  fill: #19191a;
}

.filterDate {
  outline: none;
  height: 30px;
  width: 125px;
  border: 1px solid #5100ff;
  border-radius: 4px;
  padding: 4px 10px;
  background-color: white;
}

.livechat_sidebar {
  /* display: none; */
  position: fixed;
  right: 0;
  top: 0;
  width: 696px;
  height: 100%;
  /* box-shadow: 0px 20px 42px 0px #00091A1A; */
  border-left: 1px solid #ebedf2;
  background-color: white;
  z-index: 8;
  /* transform: translateX(100375px); */
}

.livechat_slideIn {
  display: block;
  animation: slideIn 1 0.2s linear;
}

.livechat_slideOut {
  display: none;
  animation: slideOut 1 0.2s linear;
}

@keyframes slideIn {
  from {
    display: none;
    transform: translateX(375px);
  }
  to {
    display: block;
    transform: translateX(0px);
  }
}

@keyframes slideOut {
  from {
    display: block;
    transform: translateX(0px);
  }
  to {
    display: none;
    transform: translateX(375px);
  }
}

.livechat_sidebar_header {
  height: 85px;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 1rem;
  align-items: center;
  align-content: end;
  border-bottom: 1px solid #f0f2f7;
  padding: 1rem 2rem;
  justify-content: space-between;
}

.livechat_sidebar_header_icon {
  width: 24px;
}

.livechat_sidebar_header > div {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

.livechat_sidebar_header > button {
  cursor: pointer;
  outline: none;
  width: max-content;
  padding: 4px 6px;
  border-radius: 4px;
  border: 0.7px solid #c6c8cc;
  background-color: #ffffff;
  color: #19191a;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
  align-items: center;
}

.conversation-body-column > section .left {
  flex: 3;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(237, 237, 237);
}

.conversation-body-column > section .left .conversation-message {
  height: calc(100vh - 205px);
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  border-bottom: 1px solid #f7f8fc;
  /* padding: 30px; */
  overflow: auto;
}

.conversation-body-column > section .left .conversation-message > ul {
  list-style-type: none;
}

.conversation-body-column > section .left .flex-end {
  align-items: flex-end;
  justify-content: flex-start;
}

.conversation-body-column > section .left .flex-center {
  align-items: center;
  justify-content: center;
}

.conversation-body-column > section .left .text-area {
  /* height: 20%; */
  /* background-color: #24b224; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* padding-top: 10px; */
  width: 90%;
}

.draft_message {
  cursor: pointer;
  position: relative;
  width: 90%;
  box-shadow: 0px 12px 30px 0px #9383b53d;
  border: 1px solid #5100ff;
  background-color: #f6f1ff;
  border-radius: 8px;
  padding: 1rem;
  margin: 0 auto;
}

.draft_message_header {
  width: 91px;
  height: 20px;
  border-radius: 600px;
  background-color: #783bfc;
  display: grid;
  align-items: center;
  justify-items: center;
  position: absolute;
  top: -10px;
  left: 9px;
}

.draft_message_header > h5 {
  margin-top: 1px;
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-align: center;
}

.draft_message_content {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #18004d;
}

.close_draft_message {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 30px;
  background-color: #ffffff;
  display: grid;
  align-items: center;
  justify-items: center;
  position: absolute;
  top: -12px;
  right: -4px;
}

.close_draft_message_icon {
  width: 8px;
  height: 8px;
  fill: #5100ff;
}

.draft_icon {
  width: 15px;
  stroke: #5100ff;
  position: absolute;
  right: 6px;
  bottom: 8px;
}

.text-area-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 2px solid #8d8d8f;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f8f7fa;
  padding: 1vh;
  position: relative;
}

.text-area-wrapper > textarea {
  width: 100%;
  border: none;
  border-radius: 8px;
  /* padding: 1vw; */
  padding: 0 1vw 0 1vw;
  resize: none;
  min-height: 100px;
  background-color: #f8f7fa;
  max-height: 280px;
  outline: none;
}

.send-message {
  display: flex;
  align-items: flex-start;
  border: none;
  background-color: transparent;
  padding: 0.1vh 0 0 0;
}

.icons {
  width: 16px;
}

.restructure {
  position: absolute;
  bottom: 0;
  right: 5px;
}

.refresh_icon {
  stroke: #101828;
  height: 18px;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.restructuring {
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.chat-list {
  height: 100%;
  width: 100%;
  overflow-y: auto !important;
  padding: 30px;
}

.chat-list::-webkit-scrollbar {
  display: none;
}

.livechat_sidebar_bottom {
  display: flex;
  padding: 10px 2rem;
  grid-auto-flow: column;
  grid-template-columns: max-content max-content;
  gap: 1rem;
}

.switch_mode_con {
  width: 156px;
  height: 36px;
  padding: 0 10px;
  border-radius: 20px;
  background-color: #f0f2f7;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.switch_mode_con > h4 {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
}

.switch_mode_con2 {
  width: max-content;
  height: max-content;
  padding: 10px;
  border-radius: 12px;
  background-color: #f0f2f7;
  display: grid;
  grid-template-columns: max-content;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.switch_mode_con2 > h4 {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
  width: min-content;
}
.resolution_time_td {
  display: flex;
  align-items: center;
  gap: 5px;
}

.escalatedFilter {
  width: 5vw;
  height: 36px;
  border: 1px solid #5100ff;
  font-family: "Axiforma";
  font-size: 12px;
  padding: 0.5vh 1vw;
  border-radius: 8px;
  color: #19191a;
  font-weight: 600;
  white-space: nowrap;
}

.escalatedFilter:hover {
  background-color: rgb(231, 231, 231);
}

.escalatedFilter_btn {
  border: none;
  margin-left: 0.5vw;
  color: #19191a;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
}

.escalatedFilter_btn svg {
  color: #5100ff;
}
