.faq {
  font-family: "Axiforma";
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  border-bottom: 1px solid #ebedf2;
  padding: 4.125px 4.125px 24.75px 4.125px;
  margin-bottom: 3vh;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1rem;
  align-items: center;
}

.faq.selected {
  background-color: #e5eeff;
}

.faq:hover {
  background-color: #e5eeff;
}

/* .faq div:first-of-type {
  margin-bottom: 1vh;
} */

.big {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 0.5vw;
  color: black;
}

.responseText2 {
  /* margin-top: 1vh; */
  padding: 10px;
}

.response-div {
  display: flex;
  flex-direction: column;
}
