.wrapper {
  display: flex;
  flex-direction: column;
  padding: 40px 40px;
}

.wrapper > section.header p {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin-bottom: 32px;
}

.main p {
  width: 75%;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #949699;
  margin-bottom: 42px;
}

.upload-icon {
  width: 12.22px;
  stroke: white;
  margin-right: 1vw;
}

.main-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.main-section > .upload-button {
  width: Hug (128.22px);
  height: Hug (37px);
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  gap: 10px;
  background: #5100ff;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 12px;
}

.csv-image {
  width: 100%;
  height: 43px;
  background-size: 100% 100%;
  background-image: url(../../assets/images/inventory_CSV_format.png);
  margin-bottom: 31px;
}

.csv-image_email {
  width: 450px;
  height: 43px;
  background-size: 100% 100%;
  background-image: url(../../assets/images/csv-image_email.png);
  margin: auto;
  margin-bottom: 31px;
}

.csv-image_sms {
  width: 450px;
  height: 43px;
  background-size: 100% 100%;
  background-image: url(../../assets/images/csv-image_sms.png);
  margin: auto;
  margin-bottom: 31px;
}

#hidden-input {
  display: none;
}

.file-name {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 10px;
  margin-bottom: 2vh !important;
  color: #5102ff;
}

.file-name button {
  cursor: pointer;
  background-color: #5102ff;
  border: none;
  color: white;
  padding: 2px 5px;
  margin-left: 0.5vw;
  /* border-radius: 50%; */
  font-size: 10px;
  font-weight: 600;
}

.file-icon {
  width: 24px;
  margin-right: 0.5vw;
}
