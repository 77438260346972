.categorised_faq {
  font-family: "Axiforma";
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  padding: 0;
  /* margin-bottom: 1rem; */
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  align-items: center;
}

.categorised_faq:not(:last-child){
  border-bottom: 2.5px solid rgb(223, 225, 229);
}

.categorised_faq.selected {
  background-color: #e5eeff;
}

/* .categorised_faq:hover {
  background-color: #e5eeff;
} */

/* .categorised_faq div:first-of-type {
  margin-bottom: 1vh;
} */

.big {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 0.5vw;
  color: black;
}

.responseText2 {
  /* margin-top: 1vh; */
  padding: 10px;
}

.response-div {
  display: flex;
  flex-direction: column;
}

.product_category{
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  grid-auto-flow: column;
  align-items: center;
  height: 50px;
  gap: 10px;
  padding: 0 20px;
}

.product_category_icon{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #42414C;
  display: grid;
  align-items: center;
  justify-content: center;
  font-family: "Axiforma";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  text-transform: uppercase;
}

.product_category > h4{
  text-transform: capitalize;
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.product_category > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: right;
  color: #6F7073;
}

.product_category_btn{
  width: 30px;
  height: 26px;
  padding: 4px;
  border-radius: 4px;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #FFFFFF;
  justify-self: end;
}

.product_category_btn_icon{
  width: 11px;
  fill: #6F7073;
}

.product_category_btn_icon_stoke{
  width: 11px;
  stroke: #6F7073;
  justify-self: end;
  transition: all .2s;
}

.product_category_btn_icon_stoke_rotate{
  transform: rotate(-90deg);
}

.ques_res_box{
  gap: 10px;
  animation-duration: .1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.question_slideIn{
  display: grid;
  gap: 10px;
  animation-name: slideIn;
}

.question_slideOut{
  display: none;
  animation-name: slideOut;
}

@keyframes slideIn {
  0% {
    display: none;
    transform: translateY(-100deg);
  }
  100% {
    display: grid;
    transform: translateY(0px);
  }
}

@keyframes slideOut {
  0% {
    display: grid;
    transform: translateY(0px);
  }
  100% {
    display: none;
    transform: translateY(-100deg);
  }
}

.ques_res_con{
  /* width: 100%; */
  height: 36px;
  border-radius: 6px;
  border: 1px solid #DFE1E5;
  display: grid;
  grid-template-columns: max-content 1fr max-content max-content;
  gap: 10px;
  align-items: center;
  padding-right: 10px;
  margin: 0 20px;
}

.ques_res_con:last-child{
  margin-bottom: 1rem;
}

.ques_res_con:hover {
  background-color: #F0F2F7;
}

.ques_res_checkbox_con{
  width: 36px;
  display: grid;
  align-items: center;
  border-right: 1px solid #DFE1E5;
}

.question-div{
 overflow: hidden;
}

.question-div > p{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.question-div_action{
  display: none;
  transition: all 1s;
}

.ques_res_icon{
  width: 15px;
  align-self: center;
}

.ques_res_icon > path{
  stroke: #949699;
}

.ques_res_icon2{
  width: 19px;
  align-self: center;
}

.ques_res_icon2 > path{
  stroke: #949699;
}

.ques_res_con:hover > .question-div_action{
  display: block;
}

.buttons {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-auto-flow: column;
  gap: 1vw;
  justify-content: start;
}

.buttons > button {
  cursor: pointer;
  background-color: #5100FF;
  border: none !important;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  padding: 10px 15px 10px 15px;
  border-radius: 4px;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-icon {
  width: 15px;
  margin-right: 0.5vw;
}

.button-icon > path{
  stroke: #FFFFFF;
}

.delete_button{
  cursor: pointer;
  width: 69px;
  padding: 10px 15px;
  border-radius: 4px;
  gap: 10px;
  opacity: 0.2;
  border: 0.5px solid #313233;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.011em;
  text-align: left;
  color: #313233;
}