.wrapper {
  height: 100vh;
  width: 100%;
  position: static;
  overflow: hidden;
}

.wrapper > header {
  text-transform: capitalize;
  width: 100%;
  height: 6rem;
  position: sticky;
  display: grid;
  grid-auto-flow: column;
  /* justify-content: space-between; */
  gap: 1rem;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding: 1rem 3rem;
  border-bottom: 1px solid rgb(237, 235, 235);
  background-color: white;
  z-index: 5;
}

.wrapper > header > ul{
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
}

.wrapper > header > ul > h1 {
  
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.go_back{
  width: 34px;
  height: 34px;
  border-radius: 7px;
  background-color: #F0F2F7;
  display: grid;
  align-items: center;
  justify-items: center;
}

.icon{
  width: 15px;
  stroke: #292D32;
}

.filter_icon{
  width: 15px;
  stroke: #6F7073;
}

.tabs{
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  justify-content: start;
  position: sticky;
  padding: 1.5rem 3rem;
  top: 185px;
  background-color: white;
}

.tab{
  cursor: pointer;
  padding: 5px;
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 87px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ADAFB2;
  border-bottom: 1px solid transparent;

}

.tab_hover{
  border-bottom: 1px solid #FF00C7;
  color: #19191A;
}

.header_col2{
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  justify-content: end;
}

.filters{
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;
}

.filters > h4{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
  text-transform: none;
}

.header_button{
  cursor: pointer;
  width: 115px;
  height: 36px;
  padding: 10px;
  border-radius: 4px;
  background-color: #F7F8FC;
  border: 1px solid #F0F2F7;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  gap: 4px;
  align-items: center;
}

.header_button > p{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #19191A;
}

.header_btn_icon{
  height: 16px;
  fill: #19191A;
}

.header_btn_icon_stroke{
  height: 16px;
  stroke: #19191A;
}

.resolve_btn{
  width: 114px;
  height: 34px;
  padding: 5px 10px;
  border-radius: 4px;
  background: #5100FF;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
  color: #FFFFFF;
  border: none;
  outline: none;
}

.search2{
  width: 279px;
  height: 36px;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 10px;
  border: 1px solid #F0F2F7;
  border-radius: 8px;
  margin: 20px 0;
  padding: 10px;
  align-items: center;
}

.search_icon{
  width: 15.31px;
}

.search2 > input{
  border: none;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

}

.wrapper > section {
  width: 100%;
}

.wrapper .content {
  width: 100%;
  height: calc(100vh - 79.89px);
  /* margin-top: 73px; */
  /* padding: 4vh 4vw; */
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.wrapper .content .content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4vh 4vw;
  border-bottom: 1px solid rgb(237, 235, 235);
}

.wrapper .content .content-header .left {
  flex: 1;
}

.wrapper .content .content-header .left p:first-of-type {
  font-size: 20px;
  color: #19191a;
  font-weight: 600;
  margin-bottom: 2vh;
}

.wrapper .content .content-header .left p {
  color: #949699;
  font-size: 14px;
}

.wrapper .content .content-header .right {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.wrapper .content .content-header .right input[type="button"]:first-of-type {
  margin-right: 2vh;
  background-color: #efe5ff;
}

.add_product{
  background-color: #5100FF !important;
}

.wrapper .content .content-header .right input[type="button"] {
  width: 142px;
  height: 38px;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  border: 0px 0px 2px 0px;
  opacity: 0.10000000149011612px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: none;
  background-color: #191919;
  color: white;
}

.content-body{
  width: 85%;
  padding: 0 0 3rem;
  margin: auto;
  margin-top: 10vh;
}

.wrapper .content .content-body .grid {
  width: 50%;
  display: grid;
  gap: 50px;
  grid-template-columns: auto auto auto;
}

.order_table_container{
  width: 100%;
  padding: 0 0 3rem;
  margin: auto;
}

.order_table tr{
  width: 100%;
  display: grid;
  grid-template-columns: 10% 15% 10% 15% 17% 10% 82px;
  justify-content: center;
  align-content: center;
}

.order_table{
  width: 100%;
  border-collapse: collapse
}

.order_table > thead{
  background-color: #F0F2F7;
  height: 87px;
  display: grid;
  position: sticky;
  top: 73px;
  /* width: 76%; */
}

.order_table > tbody{
  display: block;
  height: calc(100vh - 227px);
  overflow-y: scroll;
  padding-bottom: 2rem;
}

.order_table > tbody::-webkit-scrollbar {
  display: none;
}

/* tr{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
} */

.order_table > thead > tr > th{
  
  color: #19191A;
  /* padding: 1rem; */
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 3.5px;
}

.order_table > tbody > tr:hover{
  background-color: #F0F2F7;
}

.selected{
  background-color: #E5EEFF;
}

.order_table > tbody > tr > td{
  color: #19191A;
  /* padding: 1rem; */
  border-bottom: 1px solid #E6E8ED;
  height: 63px;
  display: grid;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  align-content: center;
  padding: 0 3.5px;
}

.order_table > tbody > tr > td > input:checked{
  justify-self: center;
  accent-color: #F9F5FF;
  width: max-content;
  border-radius: 4px;
}

.order_table > tbody > tr > td > h5{
  font-family: "Axiforma";
  font-weight: 500;
  height: 100%;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.happy{
  color: #1CD75A !important;
}

.neutral{
  color: #B29A24 !important;
}

.angry{
  color: #fc3301 !important;
}

.product_name{
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 1rem;
}

.product_name > h5{
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;

}

.product_name > img{
  width: 61px;
  height: 61px;
}

.table_icon_fill{
  width: 15px;
  fill: #6F7073;
}

.table_icon_fill2{
  width: 21px;
  fill: #6F7073;
}

.table_icon_stroke{
  width: 21px;
  stroke: #6F7073
}

.order_details_modal{
  display: grid;
  width: 550px;
  height: 450px;
  border-radius: 8px;
  overflow: hidden;
  grid-template-rows: 1fr max-content;
  align-content: start;
}

.order_details_modal_row_1{
  display: grid;
  grid-template-rows: 1fr max-content;
  background-color: white;
  padding: 2.5rem;
  gap: 1rem;
}

.order_details_modal_row_1_row_1{
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 2rem;
}

.order_details_modal_row_1_row_1_col_1{
  display: grid;
  gap: 1rem;
  justify-content: space-between;
  /* align-content: start; */
}

.order_details_modal_row_1_row_1_col_1 > h2{
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #5100FF;
}

.order_details_modal_row_1_row_1_col_2{
  display: grid;
  align-content: start;
  gap: 1rem;
}

.order_product_image_con{
  width: 140px;
  height: 140px;
  padding: 30px;
  border-radius: 8px;
  background-color: #F7F8FC;
  border: 0.87px solid #03CF92;
  position: relative;
}

.order_product_image{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.order_details_modal_row_1_row_2{
  display: grid;
}

.order_action_buttons{
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  justify-content: start;
}

.order_action_button{
  width: max-content;
  height: 30px;
  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid #C6C8CC;
  color: #19191A;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  display: grid;
  align-items: center;
  cursor: pointer;
}

.order_action_button_active{
  border: none;
  background-color: #5100FF;
  color: #FFFFFF;
}

.order_details_modal_info{
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

.order_details_modal_info > h1{
  
  text-transform: capitalize;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.order_details_modal_info > p{
  
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #949699
}

.order_details_modal_buttons{
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 1rem;
  justify-content: start;
}

.order_details_modal_button{
  cursor: pointer;
  width: max-content;
  height: 30px;
  padding: 0 13px;
  border-radius: 4px;
  gap: 6px;
  outline: none;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.view_conversation_button{
  background-color: #F0F2F7;
  border: 1px solid #DFE1E5;
  color:#313233;
}

.mark_resolved_button{
  border: 1px solid #DCCCFF;
  background-color: #EEE6FF;
  color: #19191A;
}

.escalate_button{
  grid-template-columns: max-content;
  border: 1px solid #F8D2ED;
  background-color: #FFDDF5;
  color: #19191A;
}

.view_conversation_button > svg{
  width: 10px;
  stroke: #949699;
}

.order_details_modal_button2{
  width: 69px;
  cursor: pointer;
  height: 20px;
  padding: 0 4px;
  border-radius: 4px;
  outline: none;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: center;
  gap: 2px;
  border: 0.5px solid #EDEBF2;
  background-color: #F0F2F7;
}

.order_details_modal_button2 > h4{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.right_icon{
  width: 5px;
  fill: #949699;
  transform: rotateZ(-180deg);
}

.order_details_modal_button > h4{
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: inherit;
}

.arrow_right_icon{
  width: 7px;
  stroke: #949699;
}

.mark_resolved_icon{
  width: 15px;
  stroke: #19191A;
}

.order_details_modal_row_2{
  padding: 2rem 2.5rem;
  background-color: #F7F8FC;
  height: 118px;
  display: grid;
  gap: 0.5rem;
  grid-template-rows: max-content 1fr;
  align-content: start;
}

.order_details_modal_more_info{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-content: space-between;
}

.order_details_mores{
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 1.5rem;
}

.order_details_more{
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
  align-items: center;
}

.order_details_more_icon{
  width: 9px;
  fill: #6F7073;
}

.order_details_more_icon_stroke{
  width: 12px;
  stroke: #6F7073;
}

.order_details_more > h4{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  margin-top: 2px;
  text-align: left;
  color: #949699;
}

.order_details_modal_category{
  display: grid;
  gap: 8px;
  align-content: start;
}

.order_details_modal_category > div{
  display: grid;
  /* grid-template-columns: 20px 1fr; */
  gap: 3px;
}

.category_icon{
  width: 18px;
  fill: #6F7073;
}

.category_icon_stroke{
  width: 18px;
  stroke: #6F7073;
}

.order_details_modal_category > div > h3{
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.order_details_modal_category > div > p{
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 250px) {
  .wrapper .content .content-body .grid {
    width: 100%;
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 400px) {
  .wrapper .content .content-body .grid {
    width: 100%;
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 600px) {
  .wrapper .content .content-body .grid {
    width: 100%;
  }

  .wrapper .content .content-header .right {
    flex-direction: column;
    justify-content: right;
    align-items: end;
  }

  .wrapper .content .content-header .right input[type="button"] {
    margin: 0 0 1vh 0 !important;
  }
}

.confirmation_modal{
  width: 500px;
  padding: 2.5rem;
}

.confirmation_modal .modal-header .header {
  font-family: "Axiforma";
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-size: 20px;
}

.confirmation_modal .modal-header .email {
  color: #949699;
}

.confirmation_modal .modal-content {
  font-family: "Axiforma Medium";
  color: #949699;
  margin: 0vh 0 1rem 0;
  font-size: 12px;
  font-weight: 400;
}

.confirmation_modal .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.confirmation_modal .modal-controls input[type="button"] {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: 100;
  box-shadow: none;
  height: 40px;
}

.confirmation_modal .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #F0F2F7;
  margin-right: 2vw;
  width: 88px;
  font-size: 12px;
}

.confirmation_modal .modal-controls input[type="button"]:first-of-type:hover{
    background-color: #e6e6e7;
}

.confirmation_modal .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #5100FF;
  width: 101px;
  font-size: 12px;
}

.confirmation_modal .modal-controls input[type="button"]:last-of-type:hover{
    background-color: #5100FF;
}

.form_group{
  display: grid;
  gap: 9px;
  margin: 0vh 0 3rem 0;
}

.form_group > label{
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.form_group > textarea{
  height: 60px;
  width: auto;
  border: 1px solid #f0f2f7;
  border-radius: 6px;
  padding: 10px 15px;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  resize: none;
  color: #19191A;
}

.form_group > textarea:focus{
  background-color: #F8F7FA;
  border-color: #5100FF;
}

.pagination{
  position: sticky;
  bottom: 0;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 0 1.2rem;
  background-color: white;
  height: 68px;
  align-items: center;
}

.pagination_btn_left{
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid #C6C8CC;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_left > svg{
  fill: #292D32;
}

.pagination_btn_right{
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  background-color: #88898C;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_right > svg{
  fill: #F3F4F6;
}

.pagination_num{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.pagination_icon{
  width: 5px;
}

.escalate_department_con{
  width: 151px;
  height: 156px;
  border-radius: 8px;
  box-shadow: 0px 20px 42px 0px #00091A1A;
  background-color: white;
  display: grid;
  align-content: start;
  position: absolute;
  overflow: hidden;
}

.search_input{
  padding: 10px;
}

.search{
  height: 26px;
  width: 130px;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 4px;
  border: 1px solid #5100FF;
  border-radius: 4px;
  padding: 4px 10px;
  align-items: center;
}

.search_icon{
  width: 11.31px;
  fill: #88898C;
}

.search > input{
  border: none;
  outline: none;
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

.escalate_department_con > .department:not(:last-child){
  border-bottom: 1px solid #EBEDF2;
}

.department_con{
  overflow-y: scroll;
}

.department_con::-webkit-scrollbar{
  display: none;
}

.department_con > .department:not(:last-child){
  border-bottom: 1px solid #EBEDF2;
}

.department{
  cursor: pointer;
  padding: 7px 1rem;
}

.department > h4{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.selected_department{
  width: 124px;
  height: 30px;
  border-radius: 4px;
  background-color: #F0F2F7;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
}

.selected_department >h4{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.remove_department{
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #FFFFFF;
}

.cancel_icon{
  width: 7px;
  fill: #19191A;
}

.filter_escalate_department_con{
  /* box-shadow: 0px 20px 42px 0px #00091A1A; */
  display: grid;
  justify-items: center;
  position: relative;
}

/* .filter_search_input{
  padding: 10px;
} */

.filter_search2{
  height: 30px;
  width: 130px;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 4px;
  border: 1px solid #5100FF;
  border-radius: 4px;
  padding: 4px 10px;
  align-items: center;
  background-color: white;
}

.filter_search_icon{
  width: 11.31px;
  fill: #88898C;
}

.filter_search2 > input{
  border: none;
  outline: none;
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

.filterescalate_department_con > .department:not(:last-child){
  border-bottom: 1px solid #EBEDF2;
}

.filter_department_con{
  width: 124px;
  height: 98px;
  border-radius: 8px;
  box-shadow: 0px 20px 42px 0px #00091A1A;
  background-color: white;
  display: grid;
  align-content: start;
  overflow-y: scroll;
  position: absolute;
  top: 2.2rem;
}

.filter_department_con::-webkit-scrollbar{
  display: none;
}

.filter_department_con > .department:not(:last-child){
  border-bottom: 1px solid #EBEDF2;
}

.filter_department{
  cursor: pointer;
  padding: 7px 1rem;
}

.filter_department > h4{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.filter_selected_department{
  width: 124px;
  height: 36px;
  border-radius: 4px;
  background-color: #F0F2F7;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  /* margin-bottom: 1rem; */
}

.filter_selected_department >h4{
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.filter_remove_department{
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #FFFFFF;
}

.filter_cancel_icon{
  width: 7px;
  fill: #19191A;
}

.filterDate{
  outline: none;
  height: 30px;
  width: 125px;
  border: 1px solid #5100FF;
  border-radius: 4px;
  padding: 4px 10px;
  background-color: white;
}