.grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #f0f2f7;
  border-radius: 10px;
  color: #f0f2f7;
  padding: 5vh 2vw 2vh 2vw;
}

.hover:hover {
  border-color: #5100ff;
  stroke: #5100ff;
  fill: #5100ff;
}

.hover:hover > svg > path {
  border-color: #5100ff;
  stroke: #5100ff;
  fill: #5100ff;
}

.grid-item .package-icon {
  width: 50px;
  fill: #f0f2f7;
  margin-bottom: 2vh;
}

.grid-item button {
  
  display: flex;
  justify-content: baseline;
  color: #575759;
  font-size: 8.73px;
  font-weight: 400;
  padding: 1vh 2vw;
  border: none;
  border-radius: 5px;
  background-color: #f2f1f4;
}

.grid-item button p {
  margin-right: 1vh;
}

.grid-item button .upload-icon {
  stroke: #101828;
  width: 8.73px;
}

.added_icon{
  position: absolute;
  right: 6px;
  top: 6px;
  background-color: #2ADE0D;
  width: 24px;
  height: 24px;
  padding: 3px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
}

.green_check{
  stroke: white;
  width: 12px;
}