.wrapper {
  height: 100vh;
}

.wrapper > header {
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-bottom: 1px solid rgb(237, 235, 235);
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
}

.wrapper > header input[type="button"] {
  border-radius: 5px;
  background-color: #5102ff;
  color: white;
  padding: 2vh 3vw;
}

.wrapper > section {
  width: 100%;
}

.wrapper .content {
  width: 100%;
  height: calc(100vh - 95px);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 2rem 3rem;
}

.plans_con{
  width: 100%;
  height: 95%;
  background-color: #F0F2F7;
  border-radius: 12px;
  border: 2px solid #EBEDF2;
  display: grid;
  grid-template-rows: max-content 1fr;
  gap: 1.5rem;
  padding: 2rem;
}

.plans_con > p{
  font-family: "Axiforma";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.plans_col{
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
}

main.plans {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 0px 10px;
  margin-bottom: 25px;
  /* justify-content: space-between; */
  padding-right: 1rem;
  border-right: 1px solid #DADCE0;
  height: 90%;
}

main.plans > section {
  
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  color: #6f7073;
  font-weight: 400;
  font-size: 10px;
  width: 207px;
  height: 450px;
}

.plans > section > main > ul {
  list-style-type: none;
}

.plans > section{
  border: 1px solid transparent;
}

.plans > section > header {
  display: grid;
  justify-content: start;
  grid-template-columns: max-content max-content 1fr;
  gap: 10px;
  align-items: center;
  padding: 10px 10px;
  border-bottom: 1px solid #ebedf2;
}

/* .plans > section > header > svg {
  margin-right: 15px;
} */

.plans > section > header > h2{
  
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7073;
}

.main.plans > section > main > .plan-amount {
  color: red;
}

.background-color-change {
  background-color: #f7f8fc;
  border-radius: 6px;
  padding: 40px 40px;
}

.plans > section > main > .plan-amount {
  padding: 8px 0;
  border-bottom: 1px solid #ebedf2;
}

.plan-button-div {
  align-self: end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.plan-button-div > button {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ebedf2;
  background-color: transparent;
  font-size: 10px 20px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5px;
}

.plans > section > main{
  display: grid;
  grid-template-rows: max-content max-content 1fr;
  height: 89%;
}

.plans > section:first-of-type .plan-button-div > button {
  color: #5100ff;
}

.plans > section:nth-of-type(2) .plan-button-div > button {
  color: #e17613;
}

.plans > section:nth-of-type(3) .plan-button-div > button {
  color: #1cd370;
}

.plans > section > main > .plan-amount {
  color: #5100ff;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.plans > section:nth-of-type(2) > main > .plan-amount {
  color: #e27612;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.plans > section:nth-of-type(2) > main > .plan-amount > p > span {
  color: #949699;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: line-through;
  margin-left: 10px;
}

.plans > section:nth-of-type(3) > main > .plan-amount {
  color: #1cd370;
}

.plans > section > main > ul {
  height: max-content;
  padding: 9px 0;
}

.plans > section > main > ul > li {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.plans > section > main > ul > li:not(:last-of-type) {
  margin-bottom: 5px;
}

.plans > section > main > ul > li > svg {
  margin-right: 10px;
}

.badge {
  text-align: center;
  background: linear-gradient(180deg, #eda96b 0%, #cf823c 100%);
  color: white;
  border-radius: 20px;
  padding: 2px 5px;
  /* margin-left: 5px; */
}

.onboarding_progress{
  display: grid;
  margin-top: 2rem;
  height: 150px;
  width: 251px;
}

.onboarding_progress_background{
  grid-column: 1/-1;
    grid-row: 1/-1;
  background-color: rgb(81 0 255 / 95%);
  /* background: linear-gradient(150.08deg, rgb(157 62 251 / 25%) 0.72%, #5100FF 20.38%); */
  border-radius: 8px;
}

.onboarding_progress_content{
  cursor: pointer;
  position: relative;
  grid-column: 1/-1;
  grid-row: 1/-1;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1rem;
  padding: 0 10px;
  align-items: center;
  padding: 1.5rem;
}

.onboarding_progress_chart{
  position: relative;
}

.onboarding_progress_chart > svg{
  top: 50%;
  left: 0%;
  transform: translate(109%, -50%);
  position: absolute;

}

.time_pause_icon{
  width: 20px;
  stroke: #FFFFFF;
}

.onboarding_progress_text{
  display: grid;
  align-content: center;
  gap: 6px;
}

.onboarding_progress_text > h5{
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}

.onboarding_progress_text > p{
  font-size: 12px;
  font-weight: 700;
  line-height: 14.5px;
  letter-spacing: 0em;
  opacity: .6;
  text-align: left;
  color: #FFFFFF;
}