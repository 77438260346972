.agents_container{
  display: grid;
  height: calc(100vh - 255px);
  overflow-y: scroll;
}

.usersList {
  width: 80%;
  margin-top: 5vh;
}

.no_agents{
  display: grid;
  justify-items: center;
  gap: 1rem;
  align-self: center;
}

.no_agents > p{
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #949699;
}

.create_agent{
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  width: 157px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #C6C8CC;
  box-shadow: 0px 30px 50px 0px #00091A17;
  background-color: #EEE9FF;
}

.create_agent > h4{
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;

}

.plusicon{
  fill: #5100FF;
  width: 15px;
}

.agent_form{
  width: 500px;
  height: 280px;
  align-content: start;
  display: grid;
  gap: 2rem;
  padding: 2rem 3rem;
}

.agent_form > h2{
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.form_group{
  display: grid;
  gap: 9px;
}

.form_group > label{
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.form_group > input{
  height: 50px;
  width: auto;
  border: 1px solid #f0f2f7;
  border-radius: 6px;
  padding: 10px 15px;
  outline: none;
  /* color: #949699; */
}

.send_agent{
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  width: max-content;
  height: 38px;
  border-radius: 4px;
  box-shadow: 0px 30px 50px 0px #00091A17;
  background-color: #5100FF;
  padding: 10px 20px 10px 20px;
  justify-self: end;

}

.send_agent > h4{
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;

}

.inviteicon{
  stroke: #5100FF;
  width: 15px;
}

.sendicon{
  stroke: #FFFFFF;
  width: 15px;
}