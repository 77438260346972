.wrapper {
    height: 100vh;
  }
  
  .wrapper > header {
    height: 95px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vh 4vw;
    border-bottom: 1px solid rgb(237, 235, 235);
  }
  
  .wrapper > header input[type="button"] {
    border-radius: 5px;
    background-color: #5102ff;
    color: white;
    padding: 2vh 3vw;
  }
  
  .wrapper > section {
    width: 100%;
  }
  
  .wrapper .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }
  
  .wrapper .content .usersList {
    width: 80%;
    margin-top: 5vh;
  }
  