.wrapper {
    height: 100vh;
    overflow: hidden;
  }
  
  .wrapper > header {
    height: 95px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vh 4vw;
    border-bottom: 1px solid rgb(237, 235, 235);
    position: sticky;
    top: 0;
    background-color: white;
  }
  
  .wrapper > header > h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #19191A;
  }
  
  .wrapper > header > button {
    cursor: pointer;
    background: linear-gradient(105.08deg, #8000FF 28.72%, #5100FF 85.38%);
    color: white;
    box-shadow: none;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 155px;
    height: 38px;
    padding: o;
    border-radius: 4px;
    border: none;
    outline: none;
    gap: 10px;

  }

  .wrapper > header > button > .send_icon{
    width: 15px;
    fill: #FFFFFF;
  }

  .wrapper > header > button > .send_icon_stroke{
    width: 15px;
    stroke: #FFFFFF;
  }
  
  .wrapper > section {
    width: 100%;
  }
  
  .wrapper .content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: max-content 1fr;
    padding: 0 3rem 3rem;
    align-content: start;
  }
  
  .content_header{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    align-content: start;
    padding-top: 3rem;
    position: sticky;
    top: 95px;
    background-color: white;
  }
  
  .content_header > h2{
    font-size: 34px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color: #19191A;
  }
  
  .content_header > button{
    border: none;
    outline: none;
    width: 142px;
    height: 38px;
    padding: 10px 20px 10px 20px;
    border-radius: 4px;
    border: 0px 0px 2px 0px;
    opacity: 0.10000000149011612px;
    font-size: 12px;
    font-weight: 600;
    box-shadow: none;
    background-color: #191919;
    color: white;
  }
  
  .content_body{
    display: block;
  }
  
  .tabs{
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: start;
    position: sticky;
    padding-top: 2rem;
    top: 185px;
    background-color: white;
  }
  
  .tab{
    padding: 5px;
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    width: 87px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #ADAFB2;
    border-bottom: 1px solid transparent;

  }

  .tab_hover{
    border-bottom: 1px solid #19191A;
    color: #19191A;
  }