.wrapper {
  width: 100vw;
  height: 100vh;
  /* background: rgb(228, 217, 250); */
  /* background: linear-gradient(
    140deg,
    rgba(228, 217, 250, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    #e4d9fa 100%
  ); */

  background: #d7cbfa;
  background: linear-gradient(
    135deg,
    #d7cbfa 0%,
    #d7cbfa 0%,
    white 25%,
    white 75%,
    #d7cbfa 100%,
    #d7cbfa 100%
  );
}

.header {
  padding: 5vh 5vw;
}

.body {
  text-align: center;
}

.body > p:first-of-type {
  font-size: 28px;
  font-weight: 800;
  color: #19191a;
  margin-bottom: 3vh;
}

.body > p {
  color: gray;
  font-size: 14px;
  font-weight: 400;
}

.formWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7vh;
}

.formWrapper > .signup-form {
  width: 30%;
}

.signup-form > div:not(:nth-of-type(5)) {
  width: 100%;
  margin-bottom: 2vh;
}

form > div:first-of-type > input {
  width: 49%;
}

/* form > .twin-input > input {
  width: 49% !important;
} */

form > .twin-input {
  display: flex;
  justify-content: space-between;
}

.signup-form > div > input {
  width: 100%;
  background-color: #f8f7fa;
  border: none;
  padding: 2vh;
  border-radius: 5px;
  outline: none;
}

.signup-form > div > input:focus {
  outline: 1px solid #8652fe;
}

.signup-form > div > input::placeholder {
  color: #bbbbc8;
}

.signup-form > div:last-of-type > input[type="submit"] {
  background-color: #5102ff;
  color: #ffffff;
  font-weight: 600;
  margin-top: 5vh;
}

.signup-form > p {
  margin-top: 4vh;
  font-size: 16px;
  font-weight: 500;
  color: rgb(145, 143, 143);
}

.signup-form > p > a {
  text-decoration: none;
  font-weight: 600;
  color: #5102ff;
}

.password {
  display: flex;
  width: 100%;
  background-color: #f8f7fa;
  border-radius: 5px;
  overflow: hidden;
}

.password:focus-within {
  outline: 1px solid #8652fe;
}

.password > input {
  width: 90%;
  background-color: #f8f7fa;
  padding: 2vh;
  outline: none;
  border: none;
}

.password > input::placeholder {
  color: #bbbbc8;
}

.password > span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #4a4a53;
}

.error {
  outline: 1px solid red !important;
}

.signup-form > div > input[type="submit"]:disabled{
  background-color: #5101ff32;
}

.error-message{
  color: red;
  font-size: 15px;
  font-weight: 600;
  margin-top: 1vh;
}

@media only screen and (max-width: 600px) {
  div.formWrapper {
    /* width: 100%; */
    /* border: 1px solid red; */
  }
  form {
    width: 90% !important;
  }
  form > div:first-of-type > input:first-of-type {
    margin-bottom: 2vh;
  }
  form > div:first-of-type {
    display: block;
  }
  form > div:first-of-type > input {
    display: block;
    width: 100%;
  }
}
