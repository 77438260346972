.wrapper {
  height: 100vh;
  font-size: 12px;
}

.wrapper > header {
  height: 95px;
  display: flex;
  align-items: center;
  padding: 3vh 4vw;
  border-bottom: 1px solid rgb(237, 235, 235);
  font-weight: 600;
  font-size: 20px;
  font-family: "Axiforma SemiBold";
}

.wrapper .trainingIcon {
  width: 24px;
  fill: black;
  stroke: black;
  margin-right: 1vw;
}

.wrapper .content {
  height: calc(100vh - 95px);
  /* height: auto; */
  display: flex;
  flex-direction: row;
}

.wrapper .content > .left {
  flex: 3;
  display: flex;
  padding: 10vh 4vw;
  justify-content: flex-end;
  overflow-y: scroll;
}

.wrapper .content > .left::-webkit-scrollbar {
  width: 7px;
}

.wrapper .content > .left::-webkit-scrollbar-track {
  background-color: #F8F7FA;
}

.wrapper .content > .left::-webkit-scrollbar-thumb {
  background: #5D11FF; 
  border-radius: 20px;
}

.wrapper .content > .left .training-center-form {
  /* height: auto; */
  width: 70%;
  /* border-bottom: 1px solid #f0f2f7; */
}

.wrapper .content > .left .training-center-form > div {
  margin-bottom: 5vh;
}

.wrapper .content > .left .training-center-form textarea:focus {
  outline: 1px solid #5100ff;
}

.wrapper .content > .left .training-center-form input:focus {
  outline: 1px solid #5100ff;
}

.wrapper .content > .left .training-center-form .input-field label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0.5vh;
}

.wrapper .content > .left .training-center-form > .input-field {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
}

.wrapper .content > .left .training-center-form > .input-field input,
textarea,
.twin-input input {
  background-color: #f8f7fa;
  border: none;
  padding: 3vh 2vw;
  border-radius: 6px;
  font-weight: 400;
  color: #19191A;
}

.wrapper .content > .left .training-center-form textarea {
  resize: none;
}

.wrapper .content > .left .training-center-form > hr:first-of-type {
  width: 100%;
  margin: 0 0 6vh 0;
}

/* .wrapper .content .left .training-center-form > .input-field,
.wrapper .content .left .training-center-form > .twin-input {
    margin-bottom: 2vh;
} */

/* .wrapper .content .left .training-center-form > .input-field input,
.wrapper .content .left .training-center-form > .twin-input input {
  width: 100%;
} */

.wrapper .content > .right {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 5vh 2vw;
}

.onboarding_progress{
  display: grid;
  margin-top: 2rem;
  height: 194px;
  width: 287px;
}

.onboarding_progress_background{
  grid-column: 1/-1;
    grid-row: 1/-1;
  background-color: rgb(81 0 255 / 95%);
  /* background: linear-gradient(150.08deg, rgb(157 62 251 / 25%) 0.72%, #5100FF 20.38%); */
  border-radius: 8px;
}

.onboarding_progress_content{
  cursor: pointer;
  position: relative;
  grid-column: 1/-1;
  grid-row: 1/-1;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1.5rem;
  padding: 0 10px;
  align-items: center;
  padding: 1.5rem;
}

.onboarding_progress_chart{
  position: relative;
}

.onboarding_progress_chart > h4{
  line-height: 1.1;
  top: 50%;
  left: 0%;
  transform: translate(65%, -50%);
  position: absolute;
  display: grid;
  justify-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;

}

.onboarding_progress_text{
  display: grid;
  align-content: center;
  gap: 6px;
}

.onboarding_progress_text > h5{
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}

.onboarding_progress_text > p{
  font-size: 12px;
  font-weight: 700;
  line-height: 14.5px;
  letter-spacing: 0em;
  opacity: .6;
  text-align: left;
  color: #FFFFFF;
}

.twin-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1vw;
}

.twin-input > div {
  width: 100%;
}

.twin-input > div > input {
  width: 95%;
  background-color: #f8f7fa;
  border: none;
  padding: 3vh 2vw;
  border-radius: 6px;
  margin-top: 1vh;
  color: #19191A;
}

.wrapper .content > .left .training-center-form .twin-input input[type="text"] {
  font-family: "Axiforma";
  font-weight: 400;
  overflow: hidden;
  font-size: 12px;
}

.wrapper .content > .left .training-center-form .twin-input label {
  font-family: "Axiforma";
  font-weight: 600;
}

.add-product {
  display: grid !important;
  grid-template-columns: auto auto;
  align-items: flex-end;
}

.add-product div {
  display: flex;
  flex-direction: column;
}

.add-product input {
  width: 60%;
}

.add-product button {
  height: 68px;
  display: flex;
  flex-direction: row;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  padding: 0;
  padding: 1vh 1vw;
  background-color: #19191a;
  color: white;
  justify-content: center;
  align-items: center;
}

.addProductIcon {
  width: 20px;
  stroke: white;
  margin-right: 0.5vw;
}

#addProductDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#addProductDiv > input {
}

.twin-input span {
  color: red;
}

#hiddenFileInput {
  display: none;
}

.wrapper .content > .left .training-center-form > hr:last-of-type {
  height: 1px;
  background-color: #f0f2f7;
  border: none;
  margin: 0 0 5vh 0;
}

.save-changes {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  background-color: #5100ff;
  color: white;
  border: none;
  padding: 15px 15px;
  border-radius: 8px;
  margin-bottom: 5vh;
}

.file-icon {
}

.file-button {
  display: block !important;
  margin: 1vh;
  width: 95%;
  padding: 2.3vh 3vw;
  background-color: #f8f7fa;
  border: none;
  border-radius: 6px;
}
