.coming-soon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: rgb(248, 255, 255);
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    position: relative;
}

.content {
    text-align: center;
    max-width: 600px;
    display: grid;
    gap: 2rem;
    align-items: center;
    justify-items: center;
}

.coming-soon-title {
    font-size: 3rem;
    font-weight: bold;
    /* background: linear-gradient(120deg, #3f2c93 0%, #dc45ee 100%); */
    background: -webkit-linear-gradient(10deg, #3f2c93 0%, #dc45ee 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
    opacity: 0.9;
}

.coming-soon-description {
    font-family: "Axiforma";
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #949699;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(1rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.content {
    animation: fadeIn 1.5s ease-in-out;
}


.coming-soon_button {
    outline: none;
    box-shadow: 0px 30px 50px 0px #00091A17;
    border: 1px solid #C6C8CC;
    width: 157px;
    height: 35px;
    border-radius: 4px;
    background-color: #FFFFFF;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.coming-soon_button_icon{
    width: 13px;
    stroke: #EDAAEA;
}

/* .coming-soon_button_empty_icon{

} */

.coming-soon_button > div{
    width: 86px;
    height: 10px;
    border-radius: 12px;
    background-color: #D9D9D9;
}