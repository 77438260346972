.no_padding{
  padding: 0;
}

.gmails_con{
  display: block;
  height: 100%;
}

.gmails_con_header{
  display: grid;
  grid-template-columns: max-content max-content;
  width: 100%;
  align-items: center;
  text-align: center;
  padding: 1rem;
  border-bottom: 1px solid #C6C8CC;
  height: 70px;
  gap: 1rem;
}

.gmails_con_header > header{
  font-family: "Axiforma";
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
}

.gmails_con_header img {
  width: 28px;
}

.gmails_con_body{
  height: 100%;
  /* padding: 1rem; */
}

.gmails{
  display: grid;
  gap: 1rem;
  height: calc(100vh - 56px - 70px - 68px);
  padding: 1rem;
  overflow-y: scroll;
  padding-bottom: 2rem;
  align-content: start;
  /* justify-content: center; */
  width: inherit;
}

/* .gmails::-webkit-scrollbar {
display: none;
} */

.gmail_email{
  background-color: #FFFFFF;
  /* width: 250px; */
  height: 125px;
  border-radius: 8px;
  border: 0px, 0px, 0px, 1.5px;
  box-shadow: 0px 10px 24px 0px #00091A0A;
  padding: 1rem 10px;
  display: grid;
  gap: 10px;
  align-items: start;
  align-content: space-between;
}

.gmail_email > a{
  display: grid;
  gap: 10px;
}

.selected{
  border-left: 1.5px solid #5100FF
}

.gmail_email > a > h2{
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191A;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gmail_email_more{
display: grid;
grid-template-columns: 50% 50%;
justify-content: start;
gap: 10px;
}

.gmail_email_more > h6 {
font-size: 10px;
font-weight: 500;
line-height: 14px;
letter-spacing: 0em;
text-align: left;
color: #6F7073;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}

.email-date {
display: flex;
justify-content: center;
align-items: center;
gap: 4px;
}

.email-date > h6{
font-size: 10px;
color: #6F7073;
font-weight: 500;
}

.gmail_email_bottom{
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  gap: 10px;
}

.toggle_escalated{
/* cursor: pointer;
border-radius: 2px;
border: 0.5px solid #CAEAD1;
display: grid;
grid-template-columns: max-content max-content;
align-items: center;
align-content: center;
justify-content: center;
padding: 2px 5px 2px 10px;
gap: 3px; */
width:56px;
height:19px;
display: grid;
align-items: center;
justify-content: center;
border-radius: 2px;
border: 0.5px;
gap: 4px;

}

.toggle_escalated > p{
font-family: "Axiforma";
font-size: 10px;
font-weight: 500;
line-height: 14px;
letter-spacing: 0em;
text-align: left;
}

.escalated{
color: #313233;
}

.not_escalated{
color: #2AAB46;
}

.escalated + svg{
width: 7px;
transform: rotateZ(-90deg);
stroke: #313233;
}

.not_escalated + svg{
width: 7px;
transform: rotateZ(-90deg);
stroke: #2AAB46;
}

.escalated + svg > path{
stroke: #313233;
}

.not_escalated + svg > path{
stroke: #2AAB46;
}

.gmail_email_icons{
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
  align-items: center;
}

.more_details_con{
display: grid;
gap: 10px;
align-self: end;
border-top: 1px solid #DFE1E5;
margin-top: 10px;
}

.more_details_con:last-child{
border-bottom: 1px solid #DFE1E5;

}

.more_details_header_con{
border-bottom: 1px solid #DFE1E5;
}

.more_details_header_con > .more_details{
padding: 15px 1.5rem !important;
}

.more_details_active > h4{
color: #5100FF !important;
font-weight: 600 !important;
}

.more_details_active > svg{
stroke: #5100FF !important;
}

.more_details{
display: grid;
grid-template-columns: max-content 1fr;
gap: 10px;
padding: 10px 10px;
}

.more_details > h4{
font-family: "Axiforma";
font-size: 12px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
color: #6F7073;
overflow: hidden;
text-overflow: ellipsis;
}

.more_details_icon{
width: 16px;
fill: #000000;
}

.more_details_active > .more_details_icon2{
width: 16px;
fill: #5100FF;
stroke: none !important;
}

.more_details_icon_stroke{
width: 16px;
stroke: #000000;
}

.more_details_content_con{
display: grid;
padding: 5px 0;
}

.badge{
/* margin-left: 4px; */
min-width: 14px;
width: max-content;
height: 14px;
/* border-radius: 30px; */
/* border: 0.5px solid #C6C8CC; */
/* background-color: #651FFE; */
font-family: "Axiforma";
font-size: 12px;
font-weight: 600;
line-height: 10px;
letter-spacing: 0em;
text-align: left;
display: grid;
align-items: center;
justify-items: center;
color: #651FFE;
padding: 0 4px;
padding-top: 2px;

}

.badge2{
min-width: 14px;
width: max-content;
height: 14px;
border-radius: 30px;
/* border: 0.5px solid #C6C8CC; */
/* background-color: #000000; */
font-family: "Axiforma";
font-size: 12px;
font-weight: 600;
line-height: 10px;
letter-spacing: 0em;
text-align: left;
display: grid;
align-items: center;
justify-items: center;
color: #000000;
padding: 0 4px;
padding-top: 2px;

}

.comments_content_con{
width: 100%;
height: 154px;
background-color: #EAECF1;
display: grid;
justify-items: center;
padding: 10px 0;
overflow: hidden;
gap: 1rem;
overflow-y: scroll;
}

.comment{
width: 90%;
height: 60px;
border-radius: 6px;
border: 0.7px solid #C6C8CC;
background-color: #FFFFFF;
padding: 10px;
display: grid;
gap: 10px;
}

.comment_row1{
display: grid;
grid-auto-flow: column;
justify-content: space-between;
}

.comment_departments{
height: 18px;
padding: 0 6px;
border-radius: 20px;
border: 0.5px solid #DFE1E5;
background-color: #F7F8FC;
display: grid;
align-items: center;
justify-items: center;
font-family: "Axiforma";
font-size: 10px;
font-weight: 600;
line-height: 14px;
letter-spacing: 0em;
text-align: left;
}

.comment_date{
font-family: "Axiforma";
font-size: 10px;
font-weight: 400;
line-height: 14px;
letter-spacing: 0em;
text-align: left;
color: #949699;
}

.comment_row2{
display: block;
overflow: hidden;
}

.comment_msg{
font-family: "Axiforma";
font-size: 10px;
font-weight: 400;
line-height: 14px;
letter-spacing: 0em;
text-align: left;
color: #6F7073;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.gmails_con_filter{
display: grid;
grid-auto-flow: column;
grid-template-columns: max-content 1fr;
gap: 6px;
padding: 1rem;
align-items: center;
}

.gmails_con_filter > h2{
font-family: "Axiforma";
font-size: 16px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color: #19191A;

}

.dropdown{
height: 24px;
width: 100%;
border: 1px solid #EBEDF2;
border-radius: 6px;
outline: none;
font-family: "Axiforma";
font-size: 12px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

color: #19191A;
position: relative;
display: grid;
align-items: center;
justify-content: space-between;
grid-auto-flow: column;
padding: 0 5px;
background-color: #F8F7FA;
}

.dropdown > p{
text-transform: capitalize;
width: max-content;
text-overflow: ellipsis;
overflow: hidden;
}

.dropdown > p{
height: 18px;
font-size: 11px;
}

.dropdown > span{
height: 18px;
}

.filter_icon{
width: 10px;
stroke: #88898C;
}

.collapse_icon{
width: 10px;
stroke: #88898C;
transform: rotateZ(-90deg);
}

.more_option_con{
z-index: 2;
position: absolute;
top: 23px;
right: 2px;
border: 1px solid #F0F2F7;
width: max-content;
height: max-content;
border-radius: 8px;
background-color: white;
padding: 10px;
display: grid;
align-items: center;
gap: 5px;
}

.more_option_con > h5{
cursor: pointer;

font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: center;
height: 100%;
display: grid;
align-items: center;
color: #6F7073;
}

.pagination{
position: sticky;
bottom: 0;
display: grid;
grid-template-columns: max-content max-content max-content;
justify-content: center;
gap: 1rem;
width: 100%;
padding: 0 1.2rem;
background-color: white;
height: 68px;
align-items: center;
}

.pagination_btn_left{
cursor: pointer;
width: 18px;
height: 18px;
padding: 4px;
border-radius: 50%;
border: 1px solid #C6C8CC;
display: grid;
align-items: center;
justify-items: center;
}

.pagination_btn_left > svg{
fill: #292D32;
}

.pagination_btn_right{
cursor: pointer;
width: 18px;
height: 18px;
padding: 4px;
border-radius: 50%;
background-color: #88898C;
display: grid;
align-items: center;
justify-items: center;
}

.pagination_btn_right > svg{
fill: #F3F4F6;
}

.pagination_num{
font-family: "Axiforma";
font-size: 12px;
font-weight: 500;
line-height: 14px;
letter-spacing: 0em;
text-align: center;
color: #000000;
}

.pagination_icon{
width: 5px;
}