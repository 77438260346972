.message {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.message:not(:last-of-type) {
  margin-bottom: 20px;
}

.message > .message-image-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.message > .message-image-wrapper > div:first-of-type {
  width: 10%;
}

.message > .message-image-wrapper > div:last-of-type {
  width: 90%;
}

.message > .message-image-wrapper > .message-wrapper > .message-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;
}

.message > .message-image-wrapper > .message-wrapper > .message-top > p.name {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
}

.message > .message-image-wrapper > .message-wrapper > .message-top > p.date {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 500;
  color: #adafb2;
}

.message > .message-image-wrapper > .message-wrapper > .content > p {
  font-family: "Axiforma";
  font-weight: 400;
  font-size: 12px;
  color: #6f7073;
  width: 100%;
}

.message > .message-image-wrapper > .message-wrapper > .content > p > img {
  width: 20vw;
}

.message-wrapper > .content {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.logo-name {
  width: 35px !important;
  height: 35px;
  background-color: #42414c;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 400;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-enif {
  width: 35px !important;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f2f7;
  border-radius: 50%;
}

.message_status{
  align-self: start;
  display: grid;
  justify-items: center;
}

.message_status > p{
  font-family: "Axiforma";
  font-size: 7px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;

}

.sent_icon{
  width: 15px;
  fill: #5100FF;
}