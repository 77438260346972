.wrapper {
  height: 100vh;
  font-size: 12px;
}

.wrapper > header {
  height: 95px;
  display: flex;
  align-items: center;
  padding: 3vh 4vw;
  border-bottom: 1px solid rgb(237, 235, 235);
  font-weight: 600;
  font-size: 20px;
  font-family: "Axiforma SemiBold";
  position: fixed;
  background-color: white;
  width: 100%;
}

.wrapper .trainingIcon {
  width: 24px;
  fill: black;
  stroke: black;
  margin-right: 1vw;
}

.wrapper .content {
  height: 100%;
  /* display: flex;
    flex-direction: row; */
  display: grid;
  grid-template-columns: 3fr 392px;
  margin-top: 95px;
  position: fixed;
}

.wrapper .content .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  padding-top: 125px;
  gap: 2rem;
  overflow-y: scroll;
}

/* .wrapper .content > .left::-webkit-scrollbar {
  width: 5px;
}

.wrapper .content > .left::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}

.wrapper .content > .left::-webkit-scrollbar-thumb {
  background: #5100FF; 
  border-radius: 20px;
} */

.wrapper .content .left > section {
  width: 90%;
  background-color: white;
  /* height: 160px; */
  margin-bottom: 2vh;
}

.sec-head {
  padding: 15px 20px;
  border-bottom: 1px solid #f0f2f7;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.011em;
  text-align: left;
}

.sec-body {
  
  color: #6f7073;
  padding: 20px;
}

.sec-body > p > span{
  
  font-size: 12px;
}

.sec-body span {
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.wrapper .content .left {
  background-color: #f3f4f6;
}

.wrapper .content .right {
  font-family: "Axiforma", sans-serif !important;
  position: sticky;
  /* width: 23%; */
  right: 0;
  overflow-y: scroll;
}
.wrapper .content .right > .right-p-1 {
  
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.011em;
  text-align: left;
}

.wrapper .content .right > p {
  margin-bottom: 15px;
}

.new_detected_con{
  display: grid;
  padding: 3rem 2rem;
}

.new_detected_emptycontent{
  display: grid;
  grid-template-columns: 1fr max-content;
}

.new_detected_content_col h4 {
  margin-bottom: 15px;
  
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.011em;
  text-align: left;
  color: #19191A;
}

.new_detected_content_col p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 15px;
  color: #757575;
}

.new_detected_content_icon{
  width: 77px;
  height: 77px;
  padding: 17px, 15px, 14px, 16px;
  border-radius: 400px;
  box-shadow: 0px 20px 42px 0px #08001A1A;
  background-color: #ffffff;
  display: grid;
  align-content: center;
  justify-items: center;
}

.question_icon{
  width: 38.33px;
  height: 36.67px;
  fill:#5100FF;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.button-wrapper button {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.011em;
  text-align: left;
  border-radius: 4px;
  padding: 10px 15px 10px 15px;
  border: none;
  background-color: #e3e0ed;
  color: white;
}

.paste_textarea{
  width: 90%;
  height: 200px;
}

.sec-2 {
  /* height: 307px !important; */
  height: auto !important;
  background-color: #ecedf0 !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0 !important;
  border: 1px dotted #6f7073 !important;
  border-radius: 2px;
}

.paste_textarea .sec-2-textarea {
  
  width: 100%;
  /* height: 100%; */
  resize: none;
  /* margin-bottom: 3vh; */
  outline: none;
  border: 1px dotted #6f7073 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #19191A;
}

.sec-2 p {
  margin-bottom: 5vh;
}

.file-name {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  /* margin-bottom: 2vh; */
  color: #89928B;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-name button {
  cursor: pointer;
  background-color: #FFFFFF;
  border: none;
  border-radius: 50%;
  color: #03CF92;
  padding: 2px 5px;
  margin-left: 0.5vw;
  /* border-radius: 50%; */
  font-size: 10px;
  font-weight: 600;
  width: 18px;
  height: 18px;
}

.file-icon {
  width: 24px;
  margin-right: 0.5vw;
}

.upload-button {
  cursor: pointer;
  /* width: 187px; */
  border: none;
  background-color: white;
  height: 36px;
  padding: 9px 14px 9px 14px;
  border-radius: 4px;
  color: #6f7073;
}

.upload-button > svg {
  width: 12px;
  margin-left: 1vw;
  stroke: #6f7073;
  margin-right: 0.7vw;
}

.upload-button:disabled {
  color: #e3e4e8;
}

.upload-button:disabled > svg {
  stroke: #e3e4e8 !important;
}

.upload-button-2 {
  cursor: pointer;
  margin-left: 1vw;
  width: 187px;
  border: none;
  /* background-color: #dcd4f1; */
  background-color: #5102ff;
  height: 36px;
  padding: 9px 14px 9px 14px;
  border-radius: 4px;
  color: white;
}

.upload-button-2:disabled {
  cursor: not-allowed;
  background-color: #dcd4f1;
}

.float_right{
  float: right;
}

.sec-3 {
  background-color: #ecedf0 !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1vw;
}

.div-1 {
  border-left: 1px solid #5102ff;
  padding: 20px 10px 20px 10px;
  background-color: white !important;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.div-1 > svg {
  margin-right: 10px;
}

.div-1 > .div-1-wrapper {
  width: 90%;
}

.div-1 > .div-1-wrapper > section {
  display: flex;
  flex-direction: column;
}

.div-1 > .div-1-wrapper > section > p {
  
  margin-top: 1vh !important;
  color: #6f7073;
}

.div-1 > .div-1-wrapper > section > button {
  
  /* width: 280px; */
  width: 95%;
  height: 35px;
  /* padding: 8px 82px 7px 82px; */
  border-radius: 2px;
  border: 0.30000001192092896px;
  background-color: #ebedf2;
  text-align: center;
  font-size: 12px;
  margin-top: 1vh;
}

.div-1-icon {
  width: 36px;
}

.div-2-icon {
  width: 36px;
}

.div-2 {
  border-left: 1px solid #ff4dd4;
  padding: 20px 10px 20px 10px;
  background-color: white;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.div-2 > svg {
  margin-right: 10px;
}

.div-2 > .div-2-wrapper {
  width: 90%;
}

.div-2 > .div-2-wrapper > section {
  display: flex;
  flex-direction: column;
}

.div-2 > .div-2-wrapper > section > p {
  
  margin-top: 1vh !important;
  color: #6f7073;
}

.div-2 > .div-2-wrapper > section > button {
  /* width: 280px; */
  width: 95%;
  height: 35px;
  /* padding: 8px 80px 7px 80px; */
  border-radius: 2px;
  border: 0.30000001192092896px;
  background-color: #ebedf2;
  text-align: center;
  font-size: 12px;
  margin-top: 3vh;
  
}

.div-2 > header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.div-2 > header > svg {
  margin-right: 10px;
}

.div-1 header p {
  
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.div-2 header p {
  
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.file-field {
  display: none;
}

.error {
  position: fixed;
  margin: 1vh;
  color: red;
  font-weight: 700;
  text-align: center;
  left: 50%;
  /* top: 20%; */
  transform: translate(-50%, 0);
}
