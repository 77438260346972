.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 5vh 4vw;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.modal .modal-header .header {
  font-weight: 600;
  margin-bottom: 1vh;
  font-size: 20px;
}

.modal .modal-header .email {
  color: #949699;
}

.modal .modal-content {
  color: #949699;
  margin: 4vh 0 4vh 0;
}

.modal .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.modal .modal-controls input[type="button"] {
  border: none;
  border-radius: 5px;
  padding: 2vh 3vw;
  font-weight: 100;
  box-shadow: none;
}

.modal .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #F0F2F7;
  margin-right: 2vw;
}

.modal .modal-controls input[type="button"]:first-of-type:hover{
    background-color: #e6e6e7;
}

.modal .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #B22424;
}

.modal .modal-controls input[type="button"]:last-of-type:hover{
    background-color: #9f1f1f;
}
