.wrapper {
  /* height: 100vh; */
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.wrapper2 {
  /* height: 100vh; */
  height: 100vh;
  /* display: flex; */
  flex-direction: row;
  overflow: hidden;
}

.wrapper > div {
  display: flex;
  flex-direction: column;
}

.conversation-column {
  /* flex: 0.5; */
  flex: 1;
  border-right: 1px solid rgb(237, 237, 237);
}

.conversation-body-column {
  flex: 3;
  height: calc(100vh - 95px);
}

.conversation-column > header {
  z-index: 3;
  height: 95px;
  font-size: 20px;
  font-weight: 600;
  /* padding: 2vw 0 0 0; */
  padding: 0 2rem;
  border-bottom: 1px solid rgb(237, 237, 237);
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  display: grid;
  align-items: center;
  font-family: "Axiforma";
  grid-auto-flow: column;
  justify-content: space-between;
  grid-template-rows: 95px;
}

.conversation-column > header > ul {
  list-style-type: none;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 1rem;
}

.conversation-column > section > .messages-top-controls {
  padding: 20px 0 20px 21px;
}

.conversation-column > section {
  width: 100%;
  height: 100%;
  display: grid;
  /* align-items: center; */
  /* padding: 1vw; */
}

.conversation-column > section > .messages-top-controls > ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  grid-template-rows: 1fr max-content;
  /* margin-bottom: 2vh; */
}

.conversation-column
  > section
  > .messages-top-controls
  > ul
  > li:not(:last-of-type) {
  margin-right: 1.8vw;
}

.conversation-column > section > .messages > ul {
  list-style-type: none;
}

.conversation-column > section > .messages > ul > li {
  display: grid;
  justify-content: space-around;
  grid-template-columns: 1fr max-content;
  padding: 2vh 3vw;
  border-bottom: 1px solid rgb(237, 237, 237);
  align-items: center;
}

.conversation-column > section > .messages > ul > li > a {
  width: 100%;
  display: grid;
  justify-content: space-around;
  grid-template-columns: 40px 1fr;
  gap: 1.5rem;
}

.conversation-column > section > .messages > ul > li > a > .checkIcons {
  display: flex;
  flex-direction: row;
}

.conversation-column
  > section
  > .messages
  > ul
  > li
  > a
  > .checkIcons
  > img:not(:last-of-type) {
  margin-right: 1vh;
}

.conversation-column > section > .messages > ul > li > a > .checkIcons p {
  font-weight: 600;
  margin-left: 1.5vw;
}

.text-align {
  text-align: center;
}

.chat-list {
  height: 100%;
  width: 100%;
  overflow-y: auto !important;
  padding: 3rem 10rem 3rem 6rem;
  display: grid;
  grid-gap: 3rem;
  align-content: start;
  /* padding-right: 9rem; */
}

.chat-list::-webkit-scrollbar {
  display: none;
}

.high-color {
  color: red;
}

.mid-color {
  color: #5100ff;
}

.low-color {
  color: #b29a24;
}

.chill-color {
  color: #24b224;
}

.icons {
  width: 16px;
}

.no_emails {
  display: grid;
  justify-items: center;
  gap: 1rem;
}

.no_emails > p {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #949699;
}

.create_email {
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  width: 157px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #c6c8cc;
  box-shadow: 0px 30px 50px 0px #00091a17;
  background-color: #eee9ff;
}

.create_email > h4 {
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #313233;
}

.plusicon {
  fill: #5100ff;
  width: 15px;
}

.go_back {
  width: 34px;
  height: 34px;
  border-radius: 7px;
  background-color: #f0f2f7;
  display: grid;
  align-items: center;
  justify-items: center;
}

.icon {
  width: 15px;
  stroke: #292d32;
}

.button {
  width: 87px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #f0f2f7;
  box-shadow: 0px 4px 4px 0px #08001a0f;
  font-family: "Axiforma SemiBold";
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #19191a;
  display: grid;
  align-items: center;
}

.conversation-body-column > section {
  height: calc(100vh - 95px);
  display: grid;
  grid-template-columns: 1fr 250px;
}

.conversation-body-column > section .left {
  flex: 3;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(237, 237, 237);
  width: calc(100vw - 15rem - 250px);
  /* padding: 3rem; */
}

.left_header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  padding: 10px 6rem 0;
  position: sticky;
  background: white;
  top: 0;
  border-bottom: 1px solid #f0f2f7;
  height: 95px;
  align-items: center;
  z-index: 1;
}

.left_header > h3 {
  font-family: "Axiforma";
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
}

.left_header > .left-header_right {
  font-family: "Axiforma";
  line-height: 14px;
  font-weight: 400;
  text-align: right;
  color: #575759;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.live_chat_channel_icon_stroke {
  width: 12px;
  stroke: #575759;
  margin-right: 10px;
}

.live_chat_channel_icon_stroke > path {
  stroke: #575759;
}

.conversation-body-column > section .right {
  flex: 1;
  padding: 2vh 1vw;
  height: calc(100vh - 95px);
  display: grid;
}

.conversation-body-column > section .right > .right_row1 > .calendar {
  padding: 9px 9px 5px;
  border-bottom: 1px solid #f7f8fc;
  font-size: 10px;
  font-weight: 400;
}

.conversation-body-column > section .right > .right_row1 .others {
  padding: 9px 9px 5px;
  border-bottom: 1px solid #f7f8fc;
  font-size: 10px;
  font-weight: 400;
  font-size: 10px;
}

.conversation-body-column > section .right > .right_row1 .others .others-child {
  display: none;
  text-wrap:no-wrap;
  justify-content: space-between;
  gap: 4px;
}
.conversation-body-column
  > section
  .right
  > .right_row1
  .others
  > .others-child {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 8px;
  justify-content: space-between;
}

.conversation-body-column
  > section
  .right
  > .right_row1
  .others
  > .others-child
  > p:last-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conversation-body-column > section .left .conversation-message {
  height: calc(100vh - 245px);
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  border-bottom: 1px solid #f7f8fc;
  /* padding: 30px; */
  overflow: auto;
}

/* .conversation-message > p{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */

.conversation-body-column > section .left .conversation-message > ul {
  list-style-type: none;
}

.conversation-body-column > section .left .flex-end {
  align-items: flex-end;
  justify-content: flex-start;
}

.conversation-body-column > section .left .flex-center {
  align-items: center;
  justify-content: center;
}

.conversation-body-column > section .left .text-area {
  /* height: 20%; */
  /* background-color: #24b224; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.text-area-wrapper {
  width: 90%;
  display: flex;
  flex-direction: row;
  border: 2px solid #8d8d8f;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f8f7fa;
  padding: 1vh;
  position: relative;
  margin: 0 10rem 0 6rem;
}

.restructure {
  position: absolute;
  bottom: 0;
  right: 5px;
}

.refresh_icon {
  stroke: #101828;
  height: 18px;
}

.restructuring {
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.conversation-body-column
  > section
  .left
  .text-area
  > .text-area-wrapper
  > textarea {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
  /* padding: 1vw; */
  padding: 0 1vw 0 1vw;
  resize: none;
  min-height: 100px;
  background-color: #f8f7fa;
  max-height: 280px;
}

.text-area > .text-area-wrapper > textarea:focus {
  border: none !important;
  outline: none;
}

.text-area > .text-area-wrapper > textarea::placeholder {
  color: #c6c8cc;
  font-weight: 400;
  font-family: "Axiforma", sans-serif;
  font-style: italic;
}

.send-message {
  display: flex;
  align-items: flex-start;
  border: none;
  background-color: transparent;
  padding: 0.1vh 0 0 0;
}

.select-message {
  font-weight: 300;
  font-size: 12px;
  color: gray;
  text-align: center;
  height: calc(100vh - 95px - 95px - 50vh);
}

.message {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.message:last-of-type {
  gap: 10px;
}

.message > .message-image-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.message > .message-image-wrapper > div:first-of-type {
  width: 10%;
}

.message > .message-image-wrapper > div:last-of-type {
  width: 90%;
}

.message > .message-image-wrapper > .message-wrapper > .message-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.message > .message-image-wrapper > .message-wrapper > .message-top > p.name {
  font-size: 12px;
  font-weight: 600;
}

.message > .message-image-wrapper > .message-wrapper > .message-top > p.date {
  font-size: 10px;
  font-weight: 500;
  color: #adafb2;
}

.message > .content {
  margin-top: 1rem;
  margin-left: 5px;
  font-family: "Axiforma";
  font-weight: 400;
  font-size: 12px;
  color: #6f7073;
  width: 100%;
}

.message > .content > p {
  overflow: hidden;
}

.message > .content > p ol {
  padding-inline-start: 40px;
}

.message > .content > p ul {
  padding-inline-start: 40px;
}

.message-wrapper > .content {
  display: grid;
  grid-template-columns: 1fr max-content;
}
.attachments_div{
  display: flex;
  padding-top: 12px;
  gap:20px;
  overflow-x:scroll;
}
.attachment{
  background-color: grey;
  color:white;
  width:fit-content;
  padding:5px 10px;
  font-size: 10px;
  border-radius: 5px;
  opacity: 0.6;
  cursor:pointer;
}
.logo-name {
  width: 35px !important;
  height: 35px;
  background-color: #42414c;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 400;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-enif {
  width: 35px !important;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f2f7;
  border-radius: 50%;
}

.sent_icon {
  width: 15px;
  fill: #5100ff;
}

.draft_icon {
  width: 15px;
  fill: #b29a24;
}

.form_group2 {
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
}

.mode_icon {
  width: 17px;
}

.auto_icon {
  fill: #00b2ff;
}

.supervised_icon {
  stroke: #5100ff;
}

.hybrid_icon {
  fill: #ff00c7;
}

.selected_icon {
  width: 10px;
  stroke: #313233;
}

.collapse_icon {
  width: 8px;
  stroke: #88898c;
  transform: rotateZ(-90deg);
}

.form_group2 > div {
  cursor: pointer;
  height: 36px;
  width: 165px;
  border-radius: 6px;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  color: #19191a;
  position: relative;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-auto-flow: column;
  grid-template-columns: max-content 1fr max-content;
  padding: 0 8px;
  gap: 8px;
  background-color: #f8f7fa;
}

.form_group2 > div > p {
  text-transform: capitalize;
  width: max-content;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.011em;
  text-align: left;
  color: #19191a;
}

.form_group2 > div > p {
  height: 18px;
}

.more_option_con {
  position: absolute;
  top: 42px;
  left: 0;
  border: 1px solid #f0f2f7;
  width: 166px;
  height: 115px;
  border-radius: 8px;
  background-color: white;
  display: grid;
  align-items: center;
  box-shadow: 0px 20px 42px 0px #00091a1a;
  grid-template-rows: max-content max-content;
}

.more_option_con > div {
  align-self: start;
}

.more_option_con > div:last-child {
  align-self: end;
}

.selectedMode {
  background-color: #e5eeff;
}

.more_option_con > div {
  padding: 0 15px;
}

.more_option {
  height: 37px;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  gap: 10px;
}

.more_option_con > div > .more_option:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}

.more_option > h5 {
  cursor: pointer;
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  height: 100%;
  display: grid;
  align-items: center;
  color: #6f7073;
}

.learn_more {
  height: 37px;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: space-between;
}

.learn_more > h5 {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.right_row2 {
  display: grid;
  justify-items: center;
  align-content: space-between;
  /* padding: 10px 0; */
  gap: 0.5rem;
  align-content: space-between;
}

.right_row2_col1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.resolved_button {
  cursor: pointer;
  width: 80px;
  height: 30px;
  padding: 0 10px;
  border-radius: 4px;
  gap: 10px;
  outline: none;
  display: grid;
  /* grid-template-columns: max-content max-content; */
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.mark_resolved_button {
  background-color: #eee6ff;
  border: 0.7px solid #dcccff;
  color: #19191a;
}

.mark_escalate_button {
  border: 0.7px solid #f8d2ed;
  background-color: #ffddf5;
  color: #19191a;
}

.mark_resolved_icon {
  width: 15px;
  stroke: #19191a;
}

.mark_escalate_icon {
  width: 15px;
  fill: #19191a;
}

.resolved_button > h4 {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #19191a;
}

.switch_mode_con {
  width: 156px;
  height: 36px;
  padding: 0 10px;
  border-radius: 20px;
  background-color: #f0f2f7;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.switch_mode_con > h4 {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
}

.Modal_modal-overlay__8HdBb {
  grid-template-columns: 18rem 1fr;
}

.confirmation_modal {
  width: 500px;
  padding: 2.5rem;
}

.confirmation_modal .modal-header .header {
  font-family: "Axiforma";
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-size: 20px;
}

.confirmation_modal .modal-header .email {
  color: #949699;
}

.confirmation_modal .modal-content {
  font-family: "Axiforma Medium";
  color: #949699;
  margin: 0vh 0 1rem 0;
  font-size: 12px;
  font-weight: 400;
}

.confirmation_modal .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.confirmation_modal .modal-controls input[type="button"] {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: 100;
  box-shadow: none;
  height: 40px;
}

.confirmation_modal .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #f0f2f7;
  margin-right: 2vw;
  width: 88px;
  font-size: 12px;
}

.confirmation_modal .modal-controls input[type="button"]:first-of-type:hover {
  background-color: #e6e6e7;
}

.confirmation_modal .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #5100ff;
  width: 101px;
  font-size: 12px;
}

.confirmation_modal .modal-controls input[type="button"]:last-of-type:hover {
  background-color: #5100ff;
}

.comment_modal {
  width: 500px;
  padding: 2.5rem;
}

.comment_modal .modal-header {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.comment_modal .modal-header .header {
  font-family: "Axiforma";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
}

.comment_modal .modal-header .header_department {
  width: max-content;
  height: 30px;
  padding: 0 10px;
  border-radius: 4px;
  border: 0.7px solid #dfe1e5;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  display: grid;
  align-items: center;
}

.comment {
  width: 420px;
  height: 120px;
  padding: 10px;
  border-radius: 4px;
  border: 0.7px solid #dfe1e5;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
}

.comment_modal .modal-header .email {
  color: #949699;
}

.comment_modal .modal-content {
  font-family: "Axiforma Medium";
  color: #949699;
  margin: 0vh 0 1rem 0;
  font-size: 12px;
  font-weight: 400;
}

.comment_modal .modal-controls {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.comment_modal .modal-controls input[type="button"] {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: 100;
  box-shadow: none;
  height: 40px;
}

.comment_modal .modal-controls input[type="button"]:first-of-type {
  color: black;
  background-color: inherit;
  border: 1px solid #f0f2f7;
  margin-right: 2vw;
  width: 88px;
  font-size: 12px;
}

.comment_modal .modal-controls input[type="button"]:first-of-type:hover {
  background-color: #e6e6e7;
}

.comment_modal .modal-controls input[type="button"]:last-of-type {
  color: white;
  background-color: #5100ff;
  width: 101px;
  font-size: 12px;
}

.comment_modal .modal-controls input[type="button"]:last-of-type:hover {
  background-color: #5100ff;
}

.escalate_department_con {
  /* box-shadow: 0px 20px 42px 0px #00091A1A; */
  display: grid;
  justify-items: center;
  position: relative;
}

/* .search_input{
  padding: 10px;
} */

.search2 {
  height: 30px;
  width: 144px;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 4px;
  border: 1px solid #5100ff;
  border-radius: 4px;
  padding: 4px 10px;
  align-items: center;
}

.search_icon {
  width: 11.31px;
  fill: #88898c;
}

.search2 > input {
  border: none;
  outline: none;
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

.escalate_department_con > .department:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}

.department_con {
  width: 124px;
  height: 98px;
  border-radius: 8px;
  box-shadow: 0px 20px 42px 0px #00091a1a;
  background-color: white;
  display: grid;
  align-content: start;
  overflow-y: scroll;
  position: absolute;
  top: 2.2rem;
}

.department_con::-webkit-scrollbar {
  display: none;
}

.department_con > .department:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}

.department {
  cursor: pointer;
  padding: 7px 1rem;
}

.department > h4 {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
}

.selected_department {
  width: 124px;
  height: 30px;
  border-radius: 4px;
  background-color: #f0f2f7;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.selected_department > h4 {
  font-family: "Axiforma";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.remove_department {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #ffffff;
}

.cancel_icon {
  width: 7px;
  fill: #19191a;
}

.form_group {
  display: grid;
  gap: 9px;
  margin: 0vh 0 3rem 0;
}

.form_group > label {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
}

.form_group > textarea {
  height: 60px;
  width: auto;
  border: 1px solid #f0f2f7;
  border-radius: 6px;
  padding: 10px 15px;
  outline: none;
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  resize: none;
  color: #19191a;
}

.form_group > textarea:focus {
  background-color: #f8f7fa;
  border-color: #5100ff;
}

.pagination {
  position: sticky;
  bottom: 0;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 0 1.2rem;
  background-color: white;
  height: 68px;
  align-items: center;
}

.pagination_btn_left {
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid #c6c8cc;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_left > svg {
  fill: #292d32;
}

.pagination_btn_right {
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 50%;
  background-color: #88898c;
  display: grid;
  align-items: center;
  justify-items: center;
}

.pagination_btn_right > svg {
  fill: #f3f4f6;
}

.pagination_num {
  font-family: "Axiforma";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.pagination_icon {
  width: 5px;
}

.dropdown {
  align-items: center;
  /* background-color: #f8f7fa; */
  /* border: 1px solid #ebedf2; */
  border-radius: 6px;
  display: grid;
  font-size: 12px;
  font-weight: 400;
  grid-auto-flow: column;
  height: 24px;
  justify-content: space-between;
  line-height: 19px;
  outline: none;
  /* padding: 0 5px; */
  position: relative;
  width: 100%;
}

.dropdown > p {
  text-transform: capitalize;
  width: max-content;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdown > p {
  height: 18px;
  font-size: 11px;
}

.dropdown > span {
  height: 18px;
}

.filter_icon {
  width: 10px;
  stroke: #88898c;
}

.collapse_icon {
  width: 10px;
  stroke: #88898c;
  transform: rotateZ(-90deg);
}

.more_option_con2 {
  z-index: 2;
  position: absolute;
  top: 23px;
  left: -50px;
  border: 1px solid #f0f2f7;
  height: max-content;
  border-radius: 8px;
  background-color: white;
  padding: 10px;
  display: grid;
  align-items: center;
  gap: 5px;
  min-width: 250px;
  max-width: 100%;
  width: max-content;
}

.more_option_con2 > div {
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 5px;
  align-items: start;
}

.more_option_con2 > div > h5 {
  justify-self: end;
  cursor: pointer;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  height: 100%;
  display: grid;
  align-items: center;
  color: #6f7073;
}

.more_option_con2 > div > h6 {
  cursor: pointer;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: start;
  height: 100%;
  display: grid;
  align-items: center;
  color: #19191a;
}

@media only screen and (max-width: 1010px) {
  .left_header {
    grid-template-columns: auto;
    text-overflow: ellipsis;
  }

  .left_header > h3 {
    width: 100%;
  }

  .left_header > .left-header_right {
    display: none;
  }
}
