.side-bar {
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 100;
}

.main {
  width: 55px;
  background-color: #e6e7eb;
  z-index: 2;
  display: grid;
  grid-template-rows: max-content 1fr;
}

.main header {
  width: 100%;
  align-items: center;
  text-align: center;
  padding: 0.8rem 0 0.8rem 0;
  border-bottom: 1px solid #ebedf2;
}

.main nav {
  text-align: center;
  font-size: 1rem;
  display: grid;
  align-content: space-between;
}

.main nav > ul {
  display: grid;
  gap: 5px;
}

/* .main nav:first-of-type {
  margin-bottom: 3rem;
} */

.main nav ul li {
  height: 40px;
  display: grid;
  justify-items: center;
  align-items: center;
}

/* .main nav ul:first-of-type {
  margin-bottom: 3rem;
} */

.main header img {
  width: 28px;
}

.icon-stroke {
  width: 19px;
  /* stroke: #19191A; */
}

.icon2 {
  width: 19px;
  fill: #19191a;
}

.main-ul li.selected {
  background-color: #f3f4f6;
}

.item1 .main-ul li:hover {
  background-color: #f3f4f6;
}

.main-ul li:hover {
  background-color: #f3f4f6;
}

.user_logo {
  margin-top: 1rem;
}

.user_logo > div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #5100ff;
  display: grid;
  align-items: center;
  justify-items: center;
}

.user_logo > div > p {
  text-transform: uppercase;
  color: #ffffff;
}

.child {
  /* display: none; */
  width: 15rem;
  background-color: #f3f4f6;
  transition: all 0.5s ease;
  /* padding: 0 1.5rem; */
  /* box-shadow: 0px 20px 42px 0px #08001A0D; */
  /* box-shadow: 0px 0px 9px 0px #0000000D; */
  border-right: 1px solid #ebedf2;
}

.child.slide-out {
  transform: translateX(-200px);
  width: 0;
}

.child.show {
  display: block;
}

.child .dashboard {
  height: 95px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Axiforma";
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #f0f2f7;
  padding: 0 1.5rem;
}

/* .child > article {
  padding-left: 1.5rem;
} */
.childside-chat-left {
  display: flex;
  /* width: 175px; */
  /* justify-content: space-between; */
  align-items: center;
}
.child > article > .controls {
  font-family: "Axiforma";
  font-size: 16px;
  font-weight: 600;
  margin: 1rem 0 2rem 0;
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
}

.child > article > nav {
  padding: 0 1.5rem;
}

.child > article > .controls > div {
  display: flex;
  flex-direction: row;
}

.child > article > .controls > .icon-wrapper > div .icon {
  width: 16px;
}

.child > article > .controls > .icon-wrapper > div:first-of-type {
  margin-right: 1rem;
}

.child > article > nav > ul {
  list-style-type: none;
  /* display: grid; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.child > article > nav > ul > li {
  font-family: "Axiforma Light";
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  justify-content: space-between;
  display: flex;
  /* margin-top: 2rem; */
  /* display: grid; */
  gap: 10rem;
}

.child > article > nav > ul > li > a {
  /* width: max-content; */
  width: inherit;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  grid-template-columns: max-content 1fr;
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  align-items: center;
  color: #19191a;
  position: relative;
}

.sidebar_child_icon {
  fill: #19191a;
}

.sidebar_child_icon_stroke {
  stroke: #19191a;
}

.child > article > nav > ul > li > a > svg {
  color: #19191a;
}

.child > article > nav > ul > li > a:hover {
  color: #5100ff;
}

.child > article > nav > ul > li > a:hover > .sidebar_child_icon {
  fill: #5100ff;
}

.child > article > nav > ul > li > a:hover > .sidebar_child_icon_stroke {
  stroke: #5100ff;
}

.hover {
  color: #5100ff !important;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.sidebar_child_icon {
  width: 18px;
}

.sidebar_child_icon {
  width: 16px;
  fill: #313233;
}

.sidebar_child_icon_stroke {
  width: 18px;
  stroke: #292d32;
}

.sidebar_child_icon_stroke2 {
  width: 18px;
  stroke: #313233;
}

.hover > .sidebar_child_icon {
  fill: #5100ff !important;
}

.hover > .sidebar_child_icon_stroke {
  stroke: #5100ff !important;
}

.hover > .sidebar_child_icon_stroke2 {
  stroke: #5100ff !important;
}

.chidside_dropdown {
  /* width: 100%; */
  display: grid;
  gap: 10px;
  grid-template-rows: max-content 1fr;
  align-content: start;
}

.chidside_dropdown_toggle {
  width: 200px;
  cursor: pointer;
  display: flex;
  grid-auto-flow: row;
  justify-content: space-between;
}

.chidside_dropdown_toggle > a {
  color: #19191a;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-template-columns: max-content 1fr;
}

.chidside_dropdown_content {
  width: 100%;
  /* gap: 15px; */
  /* display: flex; */
  /* flex-direction: column; */
  gap: 10px;
  /* transform: translateY(-10px); */
  transition: all 0.5s ease;
  overflow-y: auto;
  align-content: start;
  height: 100%;
  max-height: 12rem;
}

.chidside_dropdown_content::-webkit-scrollbar {
  display: none;
}

.chidside_dropdown_content_left {
  display: flex;
  /* width: 100%; */
  gap: 10px;
}

.chidside_dropdown_content::-webkit-scrollbar {
  width: 5px;
}

.chidside_dropdown_content::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.chidside_dropdown_content::-webkit-scrollbar-thumb {
  background: #949699;
  border-radius: 20px;
}

.chidside_dropdown_content > a {
  height: 26px;
  display: flex;
  /* grid-template-columns: max-content 1fr max-content; */
  justify-content: space-between;
  align-items: center;
  color: #19191a;
  border-radius: 4px;
  padding-left: 4px;
  overflow: hidden;
}

.chidside_dropdown_content > a > svg {
  color: #19191a;
}

.chidside_dropdown_content > a > p {
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chidside_dropdown_content > a:hover {
  color: #5100ff;
  background: #ffffff;
}

.chidside_dropdown_content > .hover {
  background: #ffffff;
}

.chidside_dropdown_content > a:hover > ._child_icon {
  fill: #5100ff;
}

.chidside_dropdown_content > a:hover > .sidebar_child_icon_stroke {
  stroke: #5100ff;
}

.chidside_dropdown_toggle > .icon {
  justify-self: end;
  width: 16px;
  transition: transform 0.5s ease;
}

.rotate_icon {
  transform: rotateZ(-90deg);
}

.connection_steps {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1.2rem;
}

.connection_onboading_col1 {
  display: grid;
  gap: 2px;
  align-content: start;
}

.connection_onboading_number {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding-top: 2px;
  border: 1.5px solid transparent;
  border-color: #c6c8cc;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #c6c8cc;
}

.connection_onboading_number_current {
  border-color: #5100ff;
  color: #5100ff;
}

.connection_onboading_number_line {
  justify-self: center;
  width: 1px;
  height: 39px;
  background: #c6c8cc;
}

.connection_onboading_col2 {
  display: grid;
  gap: 2rem;
  height: 14rem;
}

.connection_onboading_step_content {
  display: grid;
  gap: 1px;
  align-content: start;
}

.connection_onboading_step_content > h2 {
  font-size: 10px;
  /* font-weight: 600; */
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
  font-family: "Axiforma";
}

.connection_onboading_step_content > p {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #949699;
  font-family: "Axiforma";
}

.connection_onboading_step_current > h2 {
  color: #c6c8cc;
  font-family: "Axiforma";
}

.connection_onboading_step_current > p {
  color: #c6c8cc;
  font-family: "Axiforma";
}

.bottom_connection {
  display: grid;
  gap: 10px;
  align-self: end;
  padding: 0 1.5rem;
}

.bottom_connection_icon {
  width: 20px;
  fill: #000000;
}

.bottom_connection > h2 {
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #19191a;
  font-family: "Axiforma";
}

.bottom_connection > p {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7073;
  font-family: "Axiforma";
}

.bottom_connection > button {
  width: 68px;
  height: 26px;
  padding: 5px, 15px, 5px, 15px;
  border: 1px solid #6f7073;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  font-family: "Axiforma";
}

.onboarding_progress {
  display: grid;
  margin-top: 2rem;
  height: 63px;
  padding: 0 1.5rem;
}

.onboarding_progress_background {
  grid-column: 1/-1;
  grid-row: 1/-1;
  background-color: rgb(81 0 255 / 95%);
  /* background: linear-gradient(150.08deg, rgb(157 62 251 / 25%) 0.72%, #5100FF 20.38%); */
  border-radius: 4.21px;
  height: inherit;
}

.onboarding_progress_background > img {
  height: inherit;
  width: 100%;
}

.onboarding_progress_content {
  cursor: pointer;
  position: relative;
  grid-column: 1/-1;
  grid-row: 1/-1;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 6px;
  padding: 0 10px;
  align-items: center;
}

/* .onboarding_progress_chart{
  margin-top: 2px;
} */

.onboarding_progress_chart > h4 {
  line-height: 1.1;
  top: 50%;
  left: 0%;
  transform: translate(79%, -50%);
  position: absolute;
  display: grid;
  justify-items: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  font-family: "Axiforma";
}

.onboarding_progress_text {
  display: grid;
  align-content: center;
  gap: 4px;
}

.onboarding_progress_text > h5 {
  font-size: 11px;
  font-weight: 800;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  font-family: "Axiforma";
}

.onboarding_progress_text > p {
  font-size: 7.31px;
  font-weight: 800;
  line-height: 7.64px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  font-family: "Axiforma";
}

.child-article {
  overflow-y: scroll;
}

.child-article::-webkit-scrollbar {
  display: none;
}
